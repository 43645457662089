.prod-list {}

.prod-main {
    @include max(900px) {
        width: 100%;
    }
}

.prod-filter {
    position: sticky;
    top: var(--size-hd);
    height: 100%;

    @include min(901px) {
        .filter-btn {
            display: none;
        }
    }

    @include max(900px) {
        position: fixed;
        z-index: 50;
        right: 0;
        bottom: 0;
        width: 35rem;
        background-color: #fff;
        padding: 0;
        transform: translate(100%, 0);
        transition: all .3s;

        @include max(575px) {
            width: 85%;
        }

        &.open {
            transform: translate(0, 0);
            overflow: hidden scroll;
        }

        .prod-filter-wr {
            @include max(800px) {
                padding: 2rem;
            }
        }

        .filter-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translate(calc(-100% - 2rem), -50%);
            width: 4rem;
            height: 4rem;
            border-radius: 10rem 0 0 10rem;
            background-color: $color-pri;

            i {
                font-size: 1.6rem;
                color: #fff;
            }


        }
    }
}

.prod-card {
    width: 50%;
    padding: 0 var(--it-pd);

    @include max(390px) {
        width: 100%;
    }

    .thumb {
        @include imgPd(288, 384);
        border-radius: 2.4rem;
        margin-bottom: 1.6rem;

        img {
            @include imgCover;
            transition: .3s;
        }

        @include hover {
            img {
                transform: translate(-50%, -50%)scale(1.1);
            }
        }
    }

    .prod-card-name {
        .link {
            color: $color-text;
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1.2rem;
            transition: .3s;
            @include lc(2);

            @include max(1024px) {
                font-size: 1.6rem;
                margin-bottom: 0;
            }

            @include hover {
                color: $color-pri;
            }
        }
    }

    .prod-card-link {
        font-weight: 500;
        padding: 1.6rem 0;
        display: flex;
        align-items: center;
        color: $color-pri;
        transition: .3s;


        @include max(1024px) {
            font-size: 1.4rem;
            padding: 1.2rem 0;
        }

        .ic {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
            flex-shrink: 0;
            margin-left: 1.2rem;
            transition: .3s;

            @include max(1024px) {
                width: 1.2rem;
                height: 1.2rem;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        @include hover {
            .ic {
                transform: translateX(.5rem);
            }
        }
    }

    // @include hover {
    //     .thumb {
    //         img {
    //             transform: translate(-50%, -50%)scale(1.1rem);
    //         }
    //     }
    // }

}

.prod-search-wr {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.8rem;
    background-color: #F5F7F7;
    padding-right: 1.2rem;
    overflow: hidden;
    border-radius: 1.2rem;
    margin-bottom: 2.4rem;

    .input {
        width: 100%;
        height: 100%;
        flex: 1;

        input {
            font-size: 1.6rem;
            width: 100%;
            height: 100%;
            padding: 1.2rem;
            color: #3A4040;

            &::placeholder {
                color: #3A4040;
            }

        }
    }

    .btn-prod-search {
        width: 2.4rem;
        height: 2.4rem;
        @include mid-flex;
        cursor: pointer;

        img {
            width: 1.6rem;
            height: 1.6rem;
            object-fit: contain;
        }
    }
}

.prod-filter-item {
    &:last-child {
        .prod-filter-heading {
            margin-bottom: 0;
            border-bottom: unset;
        }
    }

    &.active {
        .prod-filter-heading {
            .plus {
                .line {
                    background-color: $color-pri;

                    &:last-child {
                        transform: translate(-50%, 0) rotate(90deg);
                    }
                }
            }
        }

        .prod-filter-parent {
            color: $color-pri;
        }
    }

    .prod-filter-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2.4rem;
        border-bottom: 0.1rem solid #E0E0E0;
        margin-bottom: 2.4rem;

        .plus {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            width: 1.4rem;
            height: 1.4rem;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            padding-left: 1rem;
            cursor: pointer;

            .line {
                position: absolute;
                background-color: #101212;
                -webkit-transition: all .4s;
                transition: all .4s;

                &:first-child {
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 100%;
                    height: 0.1rem;
                }

                &:last-child {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 0.1rem;
                    height: 100%;
                }
            }
        }
    }

    .prod-filter-content-list {
        padding-bottom: 2.4rem;
    }

    .prod-filter-content-item {
        cursor: pointer;

        input {
            display: none;

            &:checked {
                ~.txt-prod {
                    color: #101212;
                    background-color: #F5F7F7;
                }
            }
        }

        .txt-prod {
            color: #3A4040;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.2rem;
            transition: all .3s;
            border-radius: 0.8rem;
        }

        .total {
            font-size: 1.2rem;
        }
    }
}

.page-products,
.tax-product {
    .banner .wave-bg {
        display: none;
    }

    .prod-card .prod-card-name .link {
        // @include min(391px) {
        //     height: 6rem;
        // }

        @include lc(2);
    }

    .prod-card .prod-card-link {
        padding: unset;
    }

    // fix gap
    .prod-list {
        @include max(800px) {
            &.row {
                --rg: 1.6rem;
                --cg: 1.6rem;
            }
        }

        row-gap: unset;

        @include max(425px) {
            --rg: 1.6rem;
            --cg: 1.6rem;
        }
    }

    .prod-card {
        width: calc(100% / 3);

        @include max(600px) {
            width: calc(100% / 2);
        }

        @include max(375px) {
            width: 100%;
        }

        padding: 0 calc(var(--cg) / 2);
        margin-bottom: var(--rg);

        img {
            object-fit: contain;
        }

        @include max(425px) {
            .prod-card-inner {
                .thumb {
                    margin-bottom: .8rem;
                }
            }
        }
    }
}

.page_type-search {
    // @include max(1200px) {
    //     padding-bottom: 1rem;
    // }

    .prod-list {}

    .prod-card {
        width: calc(100% / 4);

        @include max(1200px) {
            width: calc(100% / 3);
        }

        @include max(800px) {
            width: calc(100% / 2);
        }

        @include max(375px) {
            width: 100%;
        }
    }
}

// add css extend
.page-products .prod-card .prod-card-name .link,
.tax-product .prod-card .prod-card-name .link {
    text-wrap: pretty;
}

.banner .bg img {
    object-position: right;
}