// .monaMessageFromAdmin {
//     // transform: scale(0);
//     // opacity: 0;
//     // pointer-events: none;
//     // &.done {
//     //     transform: scale(1);
//     //     opacity: 1;
//     //     pointer-events: visible;
//     // }
//     position: relative;
//     display: none;

//     .img-inner {
//         text-align: center;
//         min-height: 20rem;
//         padding: 4rem;
//         @include fz-30;
//         @include font-second;
//     }

//     .bg-pink {
//         display: none;
//     }

//     .course-out {
//         &::after {
//             content: '';
//         }
//     }

//     .course-out-content {
//         display: none;
//     }

//     &::after {
//         display: none;
//     }


// }
// .monaAllButtonRow {
//     align-items: center;
//     margin-bottom: 2rem;
//     gap: 2rem;
//     .lib-self-slider-tt  {
//         width: fit-content
//     }
//     .monaAllButton {
//         width: fit-content;
//         margin-left: auto;
//         .btn {
//             @include max(700px) {
//                 height: 4rem;
//                 .txt {
//                     padding: 1.6rem 2rem;
//                 }
//             }
//         }
//     }
//     @include max(450px) {
//         flex-direction: column;
//         gap: 0;
//         .monaAllButton {
//             margin-right: auto;
//         }
//     }
// }

// .mobile-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current_page_parent > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-parent > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-post-parent > a {
//     padding-left: 1.2rem; 
// }

.abc {
    overflow: hidden;

    @include max(1200px) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }

    @include bg();

    .bg {
        transition: 1.6s linear;
        opacity: 0;
        // clip-path: inset(0 100% 0 0);
        width: calc(1027/ 1728 * 100%);
        // transition: 1s ease;

        img {
            object-fit: contain;
        }
    }

    &-wr {
        padding-top: 10.6rem;
        padding-bottom: 20rem;

        @include max(800px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        .image {
            position: relative;
            width: 100.8rem;
            max-width: 100%;
            margin: auto;
            opacity: 0;

            &-inner {
                @include imgPd(568, 1008);

                img {
                    border-radius: 3.2rem;
                    @include imgCover;
                }
            }

            .abc-card {
                @include min(801px) {
                    @include pseudo;
                    right: 4rem;
                    bottom: 0;
                    transform: translateY(50%);
                    width: calc(522/ 1008 * 100%);
                }

                @include max(800px) {
                    margin-top: 1.6rem;
                    width: 100%;
                }

                &-wr {
                    padding: 4rem;
                    border-radius: 3.2rem;
                    background: #FFF;
                    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);

                    .title {
                        width: 100%;

                        &-second {
                            @include mb(1.6rem);
                            text-align: left;
                            @include fz-42;
                            font-weight: 600;
                        }

                        &-sub {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    &.is-inview {
        .bg {
            // clip-path: inset(0 0 0 0);
            opacity: 1;
            // animation: bounceIn 1.4s forwards;
        }

        .abc-wr .image {
            opacity: 1;
            animation: bounceInRight 1.6s forwards;
        }
    }
}

.bg-f {

    position: relative;

    .bgf {
        padding-top: 15rem;
        @include pseudo;
        @include full-second;
        right: 0;
        bottom: 0;
        z-index: -1;
        // opacity: .2;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
}

.exp {
    position: relative;

    &-wr {
        &.row {
            @include max(800px) {
                flex-direction: column;
                align-items: center;

                .col {
                    width: 70%;

                    @include max(500px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-lf {
        @include mid-flex;

        .wrapper {
            padding: 4rem;

            width: 52.2rem;
            max-width: 100%;

            border-radius: 3.2rem;
            background: #FFF;
            box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);

            .title {
                &-second {
                    @include mb(1.6rem);
                    text-align: left;
                    font-weight: 600;
                    @include fz-36;
                }

                &-sub {
                    text-align: left;

                }
            }
        }
    }

    &-rt {
        position: relative;

        .tl {

            &-decor1,
            &-decor2,
            &-decor3,
            &-decor4 {
                @include pseudo;
                z-index: 2;
            }

            &-decor1 {
                top: 0;
                left: 0;
            }

            &-decor2 {
                top: 0;
                right: 0;
            }

            &-decor3 {
                bottom: 0;
                right: 0;
            }

            &-decor4 {
                bottom: 0;
                left: 0;
            }
        }

        .image {
            width: 44.8rem;

            @include max(425px) {
                overflow: hidden;
            }

            @include max(1200px) {
                width: 100%;
            }

            max-width: 100%;
            position: relative;

            &-inner {
                @include imgPd(1, 1);
                animation: Spinner 18s linear infinite;
                border-radius: 50%;
                overflow: hidden;

                img {
                    @include imgContain;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .tn {
                .the-num {
                    &:nth-child(1) {
                        top: -8%;
                        left: 78%;

                        @include max(425px) {
                            top: 1%;
                        }
                    }

                    &:nth-child(2) {
                        top: 32%;
                        left: 78%;
                    }

                    &:nth-child(3) {
                        top: 72%;
                        left: 78%;

                        @include max(425px) {
                            top: 64%;
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        @include max(1232px) {
                            // left: 67%;
                            left: 55%;

                            @include max(425px) {
                                left: 40%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-about {
    .partner {
        .title {
            width: 50.7rem;

            &-second {
                @include max(450px) {
                    text-wrap: balance;
                }
            }
        }

        &-bg {
            @include max(1200px) {
                padding-top: 6rem;
                padding-bottom: 6rem;
            }
        }
    }

    .blogs {
        padding-top: var(--ss-pd);

        @include max(500px) {
            padding-bottom: var(--ss-pd);
        }
    }
}