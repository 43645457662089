.prod-dt-heading {
    .thumb {
        @include imgPd(728, 1216);
        border-radius: 3.2rem;
        // margin-top: 4.8rem;

        img {
            @include imgContain;
        }
    }
}

.prod-desc-block {
    padding-top: 4rem;

    .tt-main {
        margin-bottom: 2.4rem;
    }

    p {
        iframe {
            aspect-ratio: 16/9;
            width: 100%;
            height: 100%;
        }
    }
}

.prod-desc-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.6rem;
    row-gap: 1.6rem;

    .info-list {}

    .info-item {
        display: flex;
        margin-bottom: 0.8rem;

        @include max(575px) {
            font-size: 1.4rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .label-info {
            width: fit-content;
            color: #606969;
        }

        .info-dt {
            color: #3A4040;
            font-weight: 600;
            margin-left: 0.4rem;
            flex: 1;
        }
    }

    .info-ct {
        display: flex;
        flex-wrap: nowrap;
        row-gap: 1.6rem;
        align-items: center;
        gap: 1.2rem;

        @include max(375px) {
            .btn {
                .txt {
                    padding: 1.6rem 3rem;
                }
            }
        }
    }
}

.feature-prod {
    .feature-item {
        margin-bottom: unset;

        @include max(800px) {
            width: 50%;
        }

        @include max(450px) {
            width: 100%;
        }

        .inner {
            position: relative;
            z-index: 2;
            height: 100%;
        }

        .bg {
            position: absolute;
            z-index: -2;
            inset: 0;
            width: 100%;
            height: 100%;
            border-radius: 1.6rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 35.42%, rgba(0, 0, 0, 0.70) 74.9%);
            }
        }
    }

    .feature-content {
        padding: 36rem 2.4rem 2.4rem 2.4rem;
        color: #F5F7F7;
        font-weight: 500;

        @include max(1200px) {
            padding: 20rem 1.6rem 1.6rem 1.6rem;
        }

        @include max(575px) {
            padding: 15rem 1.2rem 1.2rem 1.2rem;
        }
    }
}

.param-tb {
    border-radius: 1.6rem;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
}

.param-tb-head {
    border-radius: 1.6rem 1.6rem 0px 0px;
    border-bottom: 1px solid #EDF2F2;
    background-color: #156CC9;
}

.param-tb-item {
    width: 50%;
    padding: 1.6rem 0.8rem 1.6rem 1.6rem;
    line-height: 150%;

    @include max(575px) {
        font-size: 1.4rem;
        padding: 1.2rem 0.4rem 1.2rem 1.2rem;
    }
}

.param-tb-row {
    display: flex;
}

.param-tb-head-wr {
    .param-tb-item {
        color: #F5F7F7;
        font-weight: 600;
    }
}

.param-tb-body-wr {
    .param-tb-row {
        &:last-child {
            .param-tb-item {
                border-bottom: none;
            }
        }
    }

    .param-tb-item {
        border-bottom: 1px solid #D1D1D1;

        &:first-child {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 80%;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                background-color: #EDF2F2;
            }
        }
    }
}

.param-note {
    margin-top: 2.4rem;

    .inner {
        border-radius: 1.6rem;
        border-left: 4px solid #156CC9;
        background-color: #FFF;
        box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
        padding: 2.4rem;

        @include max(1024px) {
            padding: 1.6rem;
        }
    }

    .mona-content {
        color: #3A4040;
        font-size: 1.4rem;
        font-weight: 500;
    }
}

.prod-slider-sect {
    .container {
        max-width: 100%;
    }
}

.prod-slider-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1.6rem;
    align-items: center;
    padding-bottom: 4.8rem;

    @include min(1248px) {
        padding-left: calc(var(--half) - 1.6rem);
        padding-right: calc(var(--half) - 1.6rem);
    }

    @include max(1200px) {
        padding-bottom: 2.8rem;
    }
}

.prod-slider-wr {
    width: calc(100vw - (100vw - 120rem)/2);
    margin-left: auto;

    @include max(1260px) {
        width: 100%;
        overflow: hidden;
    }

    .swiper {
        margin: 0 calc(-1 * var(--it-pd));
    }

    .swiper-slide {
        width: calc(3/12*100%);
        padding: 0 var(--it-pd);

        @include max(767px) {
            width: 40%;
        }

        @include max(575px) {
            width: 50%;
        }

        @include max(450px) {
            width: 75%;
        }

        .prod-card {
            width: 100%;
            padding: 0;
        }
    }
}

.prod-dt-sect {
    // css when dont have banner but now not to use
    // &.is_check {
    //     padding-top: calc(var(--size-hd) + 2rem);
    // }
}

.page-blog-dt {
    .prod-slider-sect {
        padding-bottom: unset;
    }


}

.page-product-dt {
    .thumbSwiper {
        width: 40%;

        @include max(800px) {
            width: 40rem;
        }

        @include min(2000px) {
            width: 35%;
        }

        max-width: 100%;
        margin: auto;
        position: relative;

        .thumb-pagi {
            @include pseudo;
            bottom: 0;
        }

        .swiper-pagination-bullet-active {
            background: $color-pri !important;
        }


    }

    // make old product 1/1
    .thumb {
        @include imgPd(1, 1);

        img {
            @include imgCover;
        }

    }

    // 
    .prod-card .thumb {
        @include imgPd(288, 384);
    }

    .featureProdSlider {
        width: 100%;
        --rg: 3.2rem;
        --cg: 3.2rem;

        @include max(800px) {
            --rg: 1.6rem;
            --cg: 1.6rem;
        }

        .swiper {
            margin: 0 calc(var(--cg) / 2 * -1);
        }

        .row {
            margin: unset;
            margin-bottom: var(--rg);

            @include max(800px) {
                --rg: 1.6rem;
                --cg: 1.6rem;
            }
        }

        .feature-item {
            @include max(800px) {
                width: 50%;
            }

            @include max(425px) {
                width: 100%;
            }
        }

        .swiper-pagination {
            margin-top: unset;

            .swiper-pagination-bullet-active {
                background: $color-pri;
            }
        }
    }

    .prod-dt-heading {
        .tt-main {
            @include fz-38;

            @include min(2000px) {
                @include fz-38;
                margin-bottom: 1.8rem;
            }

            width: 45rem;
            max-width: 100%;
            margin: 0 auto;
            @include mb(4.8rem);

            @include max(1280px) {
                margin-bottom: 1.8rem;
            }
        }
    }

    .prod-dt-sect {
        @include min(2000px) {
            --pd-sc: 3rem;
        }

        @include max(1280px) {
            --pd-sc: 3rem;
        }
    }

    .prodSldier {
        .thumb {
            img {
                @include imgContain;
            }

        }
    }
}