.ft {
    width: 100%;
    position: relative;
    // background: transparent;
    overflow: hidden;
    // background: #F6FDF5;

    .tt {
        color: #FFF;
        @include fz-20;
        @include font-second;
        font-weight: 600;
        @include mb(1.6rem);
        text-wrap: nowrap;
    }

    &-wr {
        position: relative;
        padding-top: 17.3rem;
        padding-bottom: 4.8rem;
        // @include pt(27.5rem);
        // @include pb(4.8rem);

        @include bg();

        .bg {
            z-index: -1;

            img {
                object-position: top;
            }
        }

        @include max(500px) {
            padding-top: 4rem;
            padding-bottom: 4rem;

            @include max(425px) {
                padding-top: 2rem;
                padding-bottom: 3rem;
            }

            // background: var(--Gray-900, #101828);
            background: rgb(0, 120, 62);

            // border-bottom: 1px solid rgba($color-white, .2);

            .bg {
                display: none;
            }
        }
    }

    &-logo {
        @include mb(3.2rem);

        .custom-logo-link {
            display: block;
            width: 52.1rem;
            max-width: 100%;
        }
    }


    &-flex {
        &.row {
            @include max(900px) {
                // --cg: 1.6rem;
                // --rg: 1.6rem;

                .col {
                    width: calc(100% / 2);

                    @include max(500px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    // menu
    .menu {
        &-list {}

        &-item {
            @include font-second;

            &:not(:last-child) {
                @include mb(1.2rem);
            }
        }

        &-link {
            color: var(--color-white);
            @include fz-16;
            @include font-second;

            @include max(600px) {
                @include fz-14;

            }

            font-weight: 500;
            transition: .3s;

            @include hover {
                color: rgba(255, 255, 255, .5);
            }
        }
    }

    &-item {
        @include max(900px) {
            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 4;
            }

            &:nth-child(3) {
                order: 2;
            }

            &:nth-child(4) {
                order: 3;
            }
        }

        @include max(500px) {
            row-gap: unset;

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 4;
            }

            &:nth-child(3) {
                order: 2;
            }

            &:nth-child(4) {
                order: 3;
            }
        }

        @include max(500px) {
            margin-bottom: 1.6rem;

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 3;
            }

            &:nth-child(4) {
                order: 4;
            }
        }


        &:nth-child(2),
        &:nth-child(3) {
            .wrapper {
                height: 100%;

                // @include min(901px) {
                @include flex-col;
                justify-content: space-between;
                // }

                @include min(2000px) {
                    .tt {
                        padding-top: 3rem;
                        height: fit-content;
                    }
                }

                @include min(501px) {
                    .tt {
                        padding-top: 3rem;

                        @include max(625px) {
                            padding-top: 1rem;
                        }

                        height: fit-content;
                    }
                }

                @include min(901px) {
                    .tt {
                        padding-top: 5rem;

                        @include max(1071px) {
                            padding-top: 6rem;
                        }

                        height: fit-content;
                        margin-bottom: unset;
                    }

                    .menu-list {
                        height: fit-content;

                        .menu-item {
                            @include fz-14;

                            .menu-link {
                                @include fz-14;
                            }

                            &:not(:last-child) {
                                @include mb(1.2rem);
                            }
                        }
                    }
                }



            }
        }

        // &:nth-child(3) {
        //     @include min(501px) {
        //         .tt {
        //             padding-top: 0;
        //         }
        //     }
        // }

        &:nth-child(4) {
            @include min(501px) {
                .wrapper {
                    height: 100%;
                    @include flex-col;
                    justify-content: center;

                    .maps {
                        @include min(1072px) {
                            margin-top: 3rem;
                        }

                        @include min(901px) {
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }



    &-sign {
        // background: var(--Gray-900, #101828);
        background: $color-white;
        padding: 1.6rem 0;

        &-wr {
            @include flex-wr;
            gap: 1.6rem;
            align-items: center;
            justify-content: space-between;

            @include max(493px) {
                justify-content: center;
            }


            .social-list {
                @include max(493px) {
                    order: 1;
                }

                @include max(373px) {
                    display: none;
                }
            }
        }
    }

    &-copyright {
        @include flex;
        align-items: center;
        gap: 1.1rem;
        width: 32.6rem;

        @include max(493px) {
            order: 2;
        }

        @include max(373px) {
            flex-direction: column;
            justify-content: center;
            width: 60%;
        }

        gap: .8rem;

        .text {
            display: block;
            // font-family: "Mulish";
            @include font-second;

            color: $color-text;
            @include fz-14;
            font-weight: 400;
            text-wrap: nowrap;
            width: fit-content;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .maps {
        // @include min(901px) {
        //     padding-top: 2.1rem;
        // }
        height: fit-content;

        &-wr {
            width: 100%;
            height: 100%;

            iframe {
                width: 100%;
                height: 100%;
                aspect-ratio: 379/191;
            }
        }
    }

    &-content {
        .loca {
            display: block;
            @include flex-wr;
            align-items: center;
            gap: .4rem;
            line-height: normal;

            &:not(:last-child) {
                @include mb(1.6rem);
            }

            color: #F5F7F7;
            @include fz-14;
            @include font-second;
            font-weight: 400;

            .bold {
                display: block;
                font-weight: 700;
                width: fit-content;
            }

            .link {
                flex: 1;
                color: #F5F7F7;
                @include fz-14;
                font-weight: 400;
                transition: .3s;

                @include hover {
                    color: rgba(255, 255, 255, .5);
                }
            }
        }
    }

}