.c-pri {
    color: $color-pri;
}

.c-second {
    color: $color-second;
}

.c-third {
    color: $color-third;
}

.c-four {
    color: $color-four;
}

.c-five {
    color: $color-five;
}

.c-white {
    color: white;
}

.c-black {
    color: $color-black;
}

.c-txt {
    color: $color-text;
}

.c-txt-s {
    color: $color-text-second;
}

.bc-pri {
    background-color: $color-pri;
}

.bc-second {
    background-color: $color-second;
}

.bc-third {
    background-color: $color-third;
}

.bc-four {
    background-color: $color-four;
}

.bc-five {
    background-color: $color-five;
}

.over-hd {
    overflow: hidden;
}

.z-i-1 {
    z-index: 1;
}

.z-i-2 {
    z-index: 2;
}

.z-i-3 {
    z-index: 3;
}

.z-i-4 {
    z-index: 4;
}

.z-i-5 {
    z-index: 5;
}

.m-mid {
    margin: 0 auto;
}

.m-end {
    margin-left: auto;
}

.m-top {
    margin-top: 2.4rem;
}

.m-start {
    margin-right: auto;
}

.f-mid {
    @include mid-flex;
}

.f-end {
    @include flex;
    justify-content: flex-end;
}




// .lg-download,
// #lg-actual-size,
// #lg-share {
//     display: none;
// }

// .lg-backdrop,
// .lg-outer {
//     z-index: 99999;
// }

// .lg-outer .lg-thumb-item {
//     border: 0.2rem solid $color-pri;
//     transition: 0.2s ease;

//     &.active {
//         border-color: $color-third;
//     }

//     @include hover {
//         border-color: $color-third;
//     }
// }

// .lg-prev.lg-icon {
//     &::after {
//         display: none;
//     }
// }

// .lg-next.lg-icon {
//     &::before {
//         display: none;
//     }
// }

// .lg-prev.lg-icon,
// .lg-next.lg-icon {
//     width: 4rem;
//     height: 4rem;

//     i {
//         background: $color-pri-ln;
//         @include txt-ln;
//     }
// }

// .lg-outer .lg-thumb-outer {
//     background-color: #000;
// }

// .lg-close.lg-icon {
//     background: $color-pri-ln;
//     @include txt-ln;
// }

// .lg-outer .lg-toogle-thumb {
//     background: $color-pri-ln;
//     @include txt-ln;
//     width: 4rem;
//     height: 4rem;
// }

// .lg-img-wrap {
//     border-radius: .8rem;
// }

// .lg-thumb-item {
//     border-radius: .8rem;
// }

// .lg-outer .lg-thumb {
//     margin: 0 auto -5px;
// }

// .lg-thumb-outer.lg-grab {
//     display: none;
// }

.lg-download, .lg-autoplay-button, .lg-fullscreen, #lg-zoom-in, #lg-zoom-out
, #lg-actual-size, #lg-share, .lg-flip-ver, .lg-flip-hor, .lg-rotate-left, .lg-rotate-right{
    display: none;
}


.tabPanel {
    display: none;
}

.tabPanel-second {}

.tabBtn {
    cursor: pointer;
}

// .orders-table,
// table {
//     .status {
//         font-weight: 700;

//         &.waiting {
//             color: #F2C524;
//         }

//         &.success {
//             color: $color-third;
//         }

//         &.cancle {
//             color: #D31D1D;
//         }
//     }
// }

.slider {
    position: relative;
}

.relative {
    position: relative;
}

// .head {
//     margin-bottom: 3.2rem;

//     &.space {
//         @include flex-wr;
//         gap: 1rem;
//         justify-content: space-between;
//     }

//     &.center {
//         align-items: center;
//     }

//     &.end {
//         align-items: flex-end;
//     }

//     &.start {
//         align-items: flex-start;
//     }

//     &.mb-center {
//         @include max(700px) {
//             text-align: center;

//             .left {
//                 width: 100%;
//             }

//             .right {
//                 width: 100%;
//             }

//             .cdw {
//                 justify-content: center;
//             }

//             .btn {
//                 margin: 0 auto;
//             }
//         }
//     }
// }

.hv-bd {
    position: relative;

    &::before {
        @include pseudo;
        @include full-size(.8rem);
        border: 0.1rem solid white;
        z-index: 1;
        transition: .3s all ease-in-out;
        transform: scale(1.1);
        opacity: 0;
        border-radius: 1rem;
    }

    img {
        transition: .3s all ease-in-out;
    }

    @include hover {
        &::before {
            opacity: 1;
            transform: scale(1);
        }

        img {
            @include hover-scale;
        }
    }
}

.gItem {
    cursor: pointer;
}

.parallaxJS {
    img {
        position: relative;
    }
}

.hidden-desk {
    @include min($res-mobile-min) {
        display: none !important;
    }
}

.hidden-mobile {
    @include max($res-mobile) {
        display: none !important;
    }
}

.full {
    width: 100%;
}

.wp-block-image {
    // border-radius: 1.2rem;

    img {
        display: block;
        margin: 0 auto;
    }
}

// date
.calendar-table {
    table {}

    td {
        width: 3.2rem !important;
        height: 3.2rem !important;
        border: 0.4rem solid white !important;
        border-radius: 1.2rem !important;
    }

    td.today {
        background: #DFE4EA;
        color: var(--color-pri);

    }

    td.active {
        background: var(--color-pri);
        color: white;

        @include hover {
            background: var(--color-pri);
        }
    }

    // 
    th.next {
        background: #DFE4EA;
        width: 3.2rem !important;
        height: 3.2rem !important;
        @include mid-flex;

        span {
            transform: none !important;
            border: none !important;

            &::after {
                content: "\f054";
                font-family: "Font Awesome 6 Pro";
                color: var(--color-pri);
            }
        }
    }

    th.prev {
        background: #DFE4EA;
        width: 3.2rem !important;
        height: 3.2rem !important;
        @include mid-flex;

        span {
            transform: none !important;
            border: none !important;

            &::after {
                content: "\f053";
                font-family: "Font Awesome 6 Pro";
                color: var(--color-pri);
            }
        }
    }
}

.daterangepicker {

    &::before,
    &::after {
        content: none;
    }

    border: none;
    box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
    border-radius: 1rem;
}

.star {
    @include flex;
    align-items: center;

    &-flex {
        gap: 0.3rem;
    }

    .star-list {
        height: unset;
    }

    .star-empty {
        i {
            color: #dedede;
            font-size: 1.4rem;
        }
    }

    .star-filter {
        i {
            color: #FFC107;
            font-size: 1.4rem;
        }
    }
}

.rating {
    display: flex;
    gap: 0.5rem;

    .stars {
        cursor: pointer;

        i {
            color: #FCC21F;
        }

        &.active {
            i {
                color: #FCC21F;
                font-weight: 900;
            }
        }

    }

    .star {
        width: 2rem;
        height: 2rem;
        background: gray;
        display: block;
    }
}

.form {
    --height:4.4rem;
    --bdr:0.8rem;
    --bdcl:var(--color-pri);
    &-list {
        --cg: 2.4rem;
        --rg: 2.4rem;

        @include max(1200px) {
            --cg: 1.2rem;
            --rg: 1.2rem;
        }
    }

    &-ip {
        .text {
            display: block;
            margin-bottom: 1rem;
            color: var(--color-pri);
            font-weight: 500;

            .red {
                color: red;
                font-weight: 400;
            }
        }

        .input {
            position: relative;

            .seepassJS {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                color: #dedede;
                cursor: pointer;
            }
        }

        input,
        textarea {
            border: 0.1rem solid var(--color-pri);
            border-radius: 0.8rem;
            outline: none;
            padding: 1.3rem 2rem;
            width: 100%;
            background-color: transparent;
            transition: 0.4s;
            font-size: 1.6rem;
            height: 4.4rem;

            @include input-placeholder {
                color: #64748B;
            }

            &:focus {
                border-color: var(--color-pri);
            }

        }

        textarea {
            min-height: 8.4rem;
        }

        // config
        &.per5 {
            width: 50%;

            @include max(500px) {
                width: 100%;
            }
        }

        // select
        .select2-container--default .select2-selection--single {
            border-radius: 0.8rem;
            background-color: transparent;
            border-color: var(--color-pri);
        }

        .select2-container--default .select2-selection--single .select2-selection__placeholder {
            color: #64748B;
            font-size: 1.6rem;
        }

        .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
            border-radius: 0.8rem !important;
        }

        .select2-container .select2-selection--single .select2-selection__rendered {
            font-weight: 400;
            font-size: 1.6rem;
        }
    }
}