.page-numbers {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    // margin-top: 2rem;
    @include mt(2rem);

    >li {
        &:not(:first-child) {
            margin-left: 8px;
        }

        &>.page-numbers {
            // border: 0.1rem solid var(--color-pri);
            width: 4rem;
            height: 4rem;

            @include max(500px) {
                width: 3rem;
                height: 3rem;
            }

            @include max(500px) {
                width: 3rem;
                height: 3rem;
            }

            @include max(350px) {
                width: 2.5rem;
                height: 2.5rem;
            }

            border-radius: 100rem;
            @include mid-flex;
            background-color: transparent;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            color: var(--color-text);
            font-weight: 400;
            font-size: 1.4rem;
            // @include affter(var(--color-pri));
            @include affter(#F5F7F7);

            &:after {
                z-index: -1;
            }

            @include hover {
                &:not(.dot) {
                    color: $color-text;

                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }

            &.current {
                color: $color-text;
                font-weight: 600;

                &::after {
                    left: 0;
                    width: 100%;
                }
            }

            &.dot {
                background-color: transparent;
            }

            i {
                font-weight: 400;
            }
        }
    }
}