[data-aos="fade-up-cus"] {
    transform: translate(0, 4rem);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0, 0);
        opacity: 1;
    }
}

[data-aos="fade-down-cus"] {
    transform: translate(0, -4rem);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0, 0);
        opacity: 1;
    }
}

[data-aos="fade-left-cus"] {
    transform: translate(4rem, 0);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0, 0);
        opacity: 1;
    }
}

[data-aos="fade-right-cus"] {
    transform: translate(-4rem, 0);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: translate(0, 0);
        opacity: 1;
    }
}