.tn {
    .the-num {
        @include pseudo;
        z-index: 2;
        pointer-events: auto;
        animation: k-up-down 3s infinite linear both;
        width: 24rem;

        @include max(1440px) {
            width: 17rem;
        }

        max-width: 100%;
        box-shadow: 0px 4px 32px 0px rgba(12, 16, 27, 0.15);
        border-radius: 3.2rem;

        &-wr {
            width: 100%;
            @include flex;
            justify-content: center;
            gap: 1.5rem;
            padding: 2.2rem 3.7rem;
            border-radius: 3.2rem;
            background: var(--White, #FFF);
            box-shadow: 0.4rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

            @include max(1440px) {
                padding: 1.1rem 1.85rem;
                border-radius: 1.6rem;
            }

            .ic {
                margin-top: .75rem;
                width: 3.6rem;
                height: 3.6rem;

                // @include mid-flex;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .tnum {
                // margin-bottom: .4rem;

                &-wr {
                    @include flex;
                    justify-content: center;
                }

                .num,
                .plus {
                    color: var(--m002, #00783E);
                    text-align: center;
                    @include fz-48;
                    font-weight: 500;
                    line-height: normal;

                    @include max(1440px) {
                        font-size: 3.4rem;
                    }
                }
            }

            .text {
                color: #3A4040;
                text-align: center;
                @include fz-14;
                font-weight: 400;
            }
        }

    }

    .the-num {
        &:nth-child(1) {
            animation-delay: 0;
            top: 25%;
            left: -18%;
        }

        &:nth-child(2) {
            animation-delay: .5s;
            top: 12%;
            right: -8%;
        }

        &:nth-child(3) {
            animation-delay: 1s;
            bottom: 27%;
            right: -15%;
        }

        &:nth-child(4) {
            animation-delay: 1.5s;
            bottom: -13%;
            right: -22%;
        }

    }
}

.ab {

    @include max(800px) {
        padding-top: 2rem;
    }

    &-wr {
        &.row {
            @include max(800px) {
                flex-direction: column-reverse;
                align-items: center;
                padding-bottom: 15rem;

                @include max(600px) {
                    padding-bottom: 13rem;
                }

                @include max(425px) {
                    padding-bottom: 10rem;
                }

                .col {
                    width: 100%;
                }

                .ab-lf {

                    // margin-bottom: 15rem;

                    @include min(501px) {
                        width: 60%;
                    }
                }
            }
        }
    }

    .ab-lf {
        position: relative;
        @include flex;
        align-items: end;

        .tl {

            &-decor1,
            &-decor2,
            &-decor3,
            &-decor4 {
                @include pseudo;
                z-index: 4;
                pointer-events: none;
            }

            &-decor1 {
                top: 0;
                left: 0;

                @include min(901px) {
                    animation: leaf 5s infinite linear;
                }
            }

            &-decor2 {
                top: 0;
                right: 0;

                @include min(901px) {
                    animation: leaf 6s infinite linear;
                }
            }

            &-decor3 {
                bottom: 0;
                right: 0;

                @include min(901px) {
                    animation: leaf 10s infinite linear;
                }
            }

            &-decor4 {
                bottom: 0;
                left: 0;

                @include min(901px) {
                    animation: leaf 12s infinite linear;
                }
            }
        }

        .image {
            position: relative;
            width: 100%;

            .decor,
            .decor1 {
                @include pseudo;
                bottom: -15%;
                left: 0;
                width: 180%;
                transform: translateX(-60%);
                z-index: 1;

                &-inner {
                    @include imgPd(199, 950);

                    img {
                        @include imgContain;
                    }
                }
            }

            .decor1 {
                z-index: 4;
            }

            .decor2 {
                @include pseudo;
                top: 0;
                left: -20%;
                width: 130%;
                height: 130%;
                transform: translateX(-30%);
                z-index: -1;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: top;
                }
            }

            .frame {
                @include pseudo;
                top: 0;
                left: 0;
                width: 101%;
                height: 100%;
                z-index: 3;
                border-radius: 50%;
                overflow: hidden;

                img {
                    animation: Spinner 10s infinite linear;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: bottom;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            &-inner {
                border-radius: 50%;
                border: 2px solid transparent;
                @include imgPd(1, 1);
                z-index: 1;

                img {
                    @include imgCover;
                    // border: 5rem solid transparent;
                    object-position: bottom;
                    width: calc(100% - 4rem);
                    height: calc(100% - 4rem);
                }
            }
        }
    }

    .ab-rt {
        @include flex;
        flex-direction: column;
        justify-content: end;

        .wrapper {

            .title {
                width: 100%;

                .tsub {
                    text-align: left;
                    color: $color-text;
                }

                &-second {
                    @include mb(1.2rem);
                    text-align: left;
                    @include fz-40;
                    font-weight: 600;
                    line-height: normal;
                }

                @include mb(3.2rem);

                &-sub {
                    text-align: left;
                }
            }
        }
    }
}

.abu {
    @include max(900px) {
        // margin-top: -10rem;
        padding-top: 5rem;
    }

    @include max(800px) {
        margin-top: -12rem;
    }

    @include max(500px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @include max(425px) {
        margin-top: -10rem;
    }

    &-wr {
        justify-content: space-between;

        &.row {
            @include max(900px) {
                flex-direction: column;
                align-items: center;

                .col {

                    width: 100%;

                }

                .abu-lf {
                    .title {
                        margin: unset;
                    }
                }

                .abu-rt {
                    // margin-top: -10rem;
                    width: 70%;

                    @include max(900px) {
                        margin-top: -10rem;
                    }

                    @include max(677px) {
                        width: 100%;
                    }

                    @include max(500px) {
                        margin-top: -6rem;
                    }
                }
            }
        }
    }

    &-lf {

        @include mid-flex;

        .wrapper {
            width: 100%;
            height: fit-content;

            .title {
                .tsub {
                    text-align: left;
                    color: $color-text;
                    @include fz-16;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                &-second {
                    @include mb(1.2rem);
                    @include fz-42;
                    text-align: left;
                    font-weight: 600;
                }

                &-sub {
                    width: 100%;
                    @include mb(3.2rem);
                    text-align: left;
                }
            }
        }
    }

    &-rt {
        position: relative;

        .wrapper {
            @include imgPd(523, 592);
            @include bg;
            overflow: unset;

            @include max(500px) {
                padding-top: unset;
            }

            .bg {
                display: none;

                img {
                    object-fit: contain;
                    object-position: bottom;
                }
            }

            .person {
                @include pseudo;
                @include full-second;

                // @include min(1441px) {
                //     left: -20%;
                //     width: 140%;
                //     height: 120%;
                // }

                @include max(500px) {
                    position: relative;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: bottom;
                }
            }

            .tn {
                @include max(500px) {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -.8rem;
                    margin-top: 2rem;
                    row-gap: 1.6rem;
                }

                .the-num {
                    @include min(1441px) {
                        &-wr {}
                    }
                }

                .the-num {
                    @include min(1441px) {}

                    @include max(1440px) {
                        &:nth-child(1) {
                            top: 34%;
                            left: 0%;
                        }

                        &:nth-child(2) {
                            top: 24%;
                            right: 2%;
                        }

                        &:nth-child(3) {
                            bottom: 34%;
                            right: -2%;
                        }

                        &:nth-child(4) {
                            bottom: 5%;
                            right: -6%;
                        }
                    }

                    @include max(1300px) {
                        &:nth-child(1) {}

                        &:nth-child(2) {}

                        &:nth-child(3) {
                            bottom: 28%;
                            right: 1%;
                        }

                        &:nth-child(4) {
                            bottom: -4%;
                            right: 1%;
                        }
                    }

                    @include max(500px) {
                        background: unset;
                        box-shadow: unset;

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4) {
                            width: 50%;

                            @include max(343px) {
                                width: 100%;
                            }

                            position: relative;
                            animation: unset;
                            top: unset;
                            left: unset;
                            right: unset;
                            bottom: unset;
                            padding-left: .8rem;
                            padding-right: .8rem;
                            // padding: 1.1rem 1.85rem;

                            .the-num-wr {
                                // padding: unset;
                                height: 100%;

                                .text {
                                    text-wrap: balance;
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}

.partner {
    .title {
        position: relative;
        z-index: 3;

        .tsub {
            margin-bottom: .4rem;
        }

        &-second {
            @include mb(1.2rem);
        }
    }

    &-bg {
        padding: 20.5rem 0;
        @include bg();

        .bg {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            // width: 100.8rem;
            // max-width: 100%;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
        }
    }

    &-wr {


        &.row {
            margin-bottom: unset;

            .col {
                margin-bottom: unset;
            }
        }
    }

    .partner-item {
        @include max(1200px) {
            width: calc(100% / 5);
        }

        @include max(900px) {
            width: calc(100% / 4);
        }

        @include max(800px) {
            width: calc(100% / 3);
        }

        @include max(425px) {
            width: calc(100% / 2);
        }

        &-wr {
            @include imgPd(158, 280);

            img {
                @include imgContain;
            }
        }
    }
}

.prd {


    overflow: hidden !important;

    .title {
        @include mb(17.8rem);

        @include max(1440px) {
            margin-bottom: 9.8rem;
        }

        @include max(1200px) {
            margin-bottom: 2.4rem;
        }

        @include min(2000px) {
            margin-bottom: 9.8rem;
        }

        .tsub {
            margin-bottom: .4rem;
        }

        &-second {
            @include mb(1.2rem);

            @include max(425px) {
                text-wrap: balance;
            }
        }

        &-sub {}
    }

    &-wr {}
}

.prd-item {
    width: 100%;

    &-wr {
        width: 100%;
        position: relative;
        border-radius: 3.2rem;
        overflow: hidden;

        .image {
            position: relative;

            // border-radius: 3.2rem;
            // @include pseudo;
            // @include full;
            &::before {
                @include pseudo;
                @include full;
                z-index: 1;
                border-radius: 3.2rem;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 15%, rgba(0, 0, 0, 0.70) 75%);
                pointer-events: none;
            }

            &-inner {
                @include imgPd(740, 592);

                img {
                    @include imgCover;
                }
            }
        }

        .content {
            @include pseudo;
            @include full;
            top: unset;
            // padding: 4rem;
            padding: 2rem;
            z-index: 2;

            .tt {
                @include mb(1.2rem);
                color: #F5F7F7;
                // @include fz-32;
                @include fz-22;
                font-weight: 600;
                line-height: normal;
                @include lc(2);
            }

            .des {
                color: #F5F7F7;
                // @include fz-16;
                @include fz-12;
                font-weight: 400;
                @include lc(5);

                @include max(768px) {
                    @include lc(3);
                }
            }
        }
    }
}

.prdSlider {
    // width: 59.2rem;
    width: calc(384/1728 * 100%);

    @include max(1800px) {
        width: calc(300/1728 * 100%);
    }

    @include max(1200px) {
        width: calc(100% / 3.08);
    }

    @include max(1200px) {

        width: 100%;
        overflow: hidden;

        .swiper {
            // width: 100%;
            margin: 0 -.8rem;
        }


        .swiper-slide {
            padding: 0 .8rem;
            width: calc(100% / 3);

            @include max(500px) {
                width: 100%;
            }
        }
    }

    @include max(768px) {

        // width: calc(100% / 1.5);
        width: 100%;
        overflow: hidden;

        .swiper {
            // width: 100%;
            margin: 0 -.8rem;
        }


        .swiper-slide {
            padding: 0 .8rem;
            width: calc(100% / 2);

            @include max(500px) {
                width: 100%;
            }
        }
    }

    // @include max(700px) {
    //     width: 30%;
    // }

    // @include max(500px) {
    //     width: calc(100% / 1.5);
    // }

    @include max(500px) {
        width: 100%;
    }

    // width: calc(592/1728 * 100%);
    // width: calc(362/1728 * 100%);
    // width: 38.4rem;

    // @include min(2000px) {
    //     width: 20.4rem;
    // }

    // @include max(1800px) {
    //     width: 36.4rem;
    // }

    max-width: 100%;
    margin: auto;

    .swiper {
        overflow: unset;
    }

    .swiper-wrapper {}

    .swiper-slide {
        position: relative;
        z-index: 5;

        @include min(1201px) {
            &::before {
                @include pseudo;
                @include full;
                z-index: 5;

                border-radius: 3.2rem;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%);

                opacity: 1;
                pointer-events: none;
                transition: .3s ease;
                // transition: .3s linear;
                transition-delay: .3s;
            }

            // scale: .8;
            transition: .3s linear;
            transition-delay: .3s;

            .content {
                transform: translateY(30%);
                opacity: 0;
                transition: .3s ease;
                transition-delay: .6s;
            }
        }

        // @include max(768px) {
        //     &::before {
        //         @include pseudo;
        //         @include full;
        //         z-index: 5;

        //         border-radius: 3.2rem;
        //         background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%);

        //         opacity: 1;
        //         pointer-events: none;
        //         transition: .3s ease;
        //         // transition: .3s linear;
        //         transition-delay: .3s;
        //     }

        //     // scale: .8;
        //     transition: .3s linear;
        //     transition-delay: .3s;

        //     .content {
        //         transform: translateY(30%);
        //         opacity: 0;
        //         transition: .3s ease;
        //         transition-delay: .6s;
        //     }
        // }

    }

    // .swiper-slide-prev {
    //     // margin-right: -50%;
    //     // padding-left: 15%;
    // }

    // .swiper-slide-next {
    //     // padding-right: 15%;
    //     // margin-left: -50%;
    // }

    .swiper-slide-active {

        // margin-inline: -50%;
        // margin-inline: -30%;
        @include min(1201px) {
            transform: scale(1.5);
        }

        z-index: 6;

        // @include max(800px) {
        //     transform: scale(1.4);
        //     // scale: 1.4;
        // }

        // @include max(500px) {
        //     transform: scale(1);
        //     // scale: 1;
        // }

        &::before {
            opacity: 0;
        }

        .content {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .prd-control {
        @include pt(17.8rem);

        @include max(1400px) {
            padding-top: 9.8rem;
        }

        @include max(1200px) {
            padding-top: 2.4rem;
        }

        // @include max(1000px) {
        //     padding-top: 14.4rem;
        // }

        // @include max(800px) {
        //     padding-top: 6.4rem;
        // }

        // @include max(460px) {
        //     padding-top: 2.4rem;
        // }

        @include min(2000px) {
            padding-top: 9.8rem;
        }

        justify-content: center;
    }
}

.his {

    &-wr {}

    &-lf {
        @include max(930px) {
            display: none;
        }
    }

    &-rt {
        @include max(930px) {
            width: 100%;
        }
    }

    .hisLfSlider {
        height: 66.4rem;

        @include max(1200px) {
            height: 56.4rem;
        }

        @include max(950px) {
            height: 46.4rem;
        }

        overflow: hidden;
        // height: 74rem;
        // rotate: 180deg;

        .swiper {
            overflow: unset;
        }

        .swiper-wrapper {}

        .swiper-slide {
            transform-origin: left center !important;
            transition: opacity 0.8s ease, margin-left 0.8s ease, color .4s ease !important;
            height: fit-content !important;

            .num {
                position: relative;
                width: fit-content;
                transition: .4s;

                &::before {
                    @include pseudo;
                    width: 0;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    background: $color-pri;
                    // border-bottom: 1px solid $color-pri;
                    // transform-origin: left;
                    transition: .4s;
                    transition-delay: .8s;
                }
            }
        }

        // .swiper-slide-next {
        //     .num {
        //         background: $color-white;
        //         color: $color-pri;
        //     }
        // }

        .swiper-slide-active {
            .num {
                position: relative;
                background: $color-white;
                color: $color-pri;
                // border-bottom: 1px solid $color-pri;
                transition: unset;
                transition-delay: unset;

                &::before {
                    @include pseudo;
                    width: 100%;
                    transition: unset;
                    transition-delay: unset;
                }
            }
        }
    }

    .his-lf-item {
        --opa: 0.8;
        height: fit-content;

        .num {
            // rotate: 180deg;
            color: rgba(16, 18, 18, var(--opa));
            @include fz-64;
            font-weight: 600;
            height: fit-content;
        }
    }

    .his-rt-item {
        &-wr {
            position: relative;

            .image {
                position: relative;

                @include max(500px) {
                    border-radius: 3.2rem;
                    overflow: hidden;
                }

                @include max(500) {
                    border-radius: 3.2rem;
                    overflow: hidden;
                }

                &::before {
                    @include pseudo;
                    @include full;
                    // border-radius: 3.2rem;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 55%, rgba(0, 0, 0, 0.70) 95%);
                    z-index: 1;
                    pointer-events: none;
                }

                &-inner {
                    @include imgPd(740, 1008);

                    img {
                        @include imgCover;
                    }
                }
            }

            .content {
                @include pseudo;
                @include full;
                top: unset;
                padding: 4rem;
                z-index: 3;

                @include max(800px) {
                    padding: 2rem;

                }

                @include max(500px) {
                    position: relative;
                    padding-top: 1.6rem;
                    padding-left: unset;
                    padding-right: unset;
                }

                .num {
                    @include min(801px) {
                        display: none;
                    }

                    // @include mb(1.2rem);
                    color: #F5F7F7;
                    @include fz-34;
                    font-weight: 600;
                    line-height: normal;
                    transform: scale(--sc);

                    @include max(500px) {
                        color: $color-pri;
                    }
                }

                .tt {
                    @include mb(1.2rem);
                    color: #F5F7F7;
                    @include fz-32;
                    font-weight: 600;
                    line-height: normal;

                    // @include lc(2);
                    @include max(500px) {
                        color: $color-text;
                    }
                }

                .des {
                    color: #F5F7F7;
                    @include fz-16;
                    font-weight: 400;

                    // @include lc(3);
                    @include max(500px) {
                        color: $color-text-second;
                    }

                    @include max(425px) {
                        height: 8rem;
                        overflow: hidden auto;
                    }
                }

                .mona-content {
                    h3 {
                        color: $color-white;
                        @include fz-32;

                        @include max(500px) {
                            color: $color-text;
                        }
                    }

                    p {
                        color: $color-white;
                        @include fz-16;

                        @include max(500px) {
                            color: $color-text-second;
                        }
                    }
                }
            }
        }
    }

    .hisRtSlider {
        border-radius: 3.2rem;
        overflow: hidden;
    }
}



.rv {
    position: relative;

    @include max(425px) {
        overflow: hidden;
    }

    @include min(2000px) {
        padding-bottom: 4rem;
    }

    .wave-bg {
        position: absolute;
        // height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: -.1rem;
        z-index: 2;
        pointer-events: none;

        @include max(1440px) {
            bottom: -2.1rem;
        }

        &-inner {
            @include imgPd(233, 1728);

            img {
                @include imgCover;
                object-position: bottom;
            }
        }

    }

    .pn {
        @include pseudo;
        top: 0;
        left: 0;

        @include max(500px) {
            display: none;
        }
    }

    .title {
        width: 41.1rem;
        margin: unset;
        @include mb(4.8rem);

        .tsub {
            text-align: left;

            @include max(425px) {
                text-align: center;
            }
        }

        &-second {
            text-align: left;

            @include max(425px) {
                text-align: center;
                text-wrap: balance;
            }
        }
    }

    &-wr {
        &.row {
            margin-bottom: unset;
            // align-items: end;

            @include min(2000px) {
                align-items: end;
            }

            @include max(800px) {
                flex-direction: column;
                align-items: center;

                .col {
                    width: 100%;
                }

                .rv-lf {
                    width: 60%;
                }
            }

            .col {
                margin-bottom: unset;
            }
        }
    }

    .rv-lf {
        @include bg();

        .bg {
            transform: translateY(-10%)translateX(-50%);
            width: 200%;

            img {
                object-fit: contain;
            }
        }

        .pn {
            @include max(800px) {
                display: none;
            }

            width: 32.6rem;
            max-width: 100%;
            height: 100%;
            transform: translateX(-80%);

            @include min(2000px) {
                left: 6rem;
            }

            &-wr {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 4;

                .pn-decor {
                    @include pseudo;
                    border-radius: 50%;
                    animation: k-up-down 3s infinite linear both;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    // space
                    &:nth-child(1) {
                        width: 6.683rem;
                        height: 6.683rem;
                    }

                    &:nth-child(2) {
                        width: 5.595rem;
                        height: 5.595rem;
                    }

                    &:nth-child(3) {
                        width: 4.507rem;
                        height: 4.507rem;
                    }

                    &:nth-child(4) {
                        width: 6.22rem;
                        height: 6.22rem;
                    }

                    &:nth-child(5) {
                        width: 10.34rem;
                        height: 10.34rem;
                    }

                    &:nth-child(6) {
                        width: 5.984rem;
                        height: 5.984rem;
                    }

                    // position
                    &:nth-child(1) {
                        top: 0;
                        right: 2%;
                    }

                    &:nth-child(2) {
                        top: 10%;
                        left: 0%;
                    }

                    &:nth-child(3) {
                        top: 17%;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:nth-child(4) {
                        top: 24%;
                        right: 0;
                    }

                    &:nth-child(5) {
                        top: 33%;
                        left: 50%;
                        transform: translateX(-60%);
                    }

                    &:nth-child(6) {
                        top: 47%;
                        left: 0;
                    }

                    // ani
                    &:nth-child(1) {
                        animation-delay: .4s;
                    }

                    &:nth-child(2) {
                        animation-delay: .8s;
                    }

                    &:nth-child(3) {
                        animation-delay: 1.6s;
                    }

                    &:nth-child(4) {
                        animation-delay: .8s;
                    }

                    &:nth-child(5) {
                        animation-delay: .6s;
                    }

                    &:nth-child(6) {
                        animation-delay: .4s;
                    }
                }
            }

        }
    }

    .rv-lf-item {
        width: 100%;

        height: 100%;
        @include flex-col;
        justify-content: end;

        .image {
            width: 100%;
            height: fit-content;

            @include min-max(501px, 1800px) {
                margin-bottom: 2rem;
            }

            &-inner {
                @include imgPd(710, 488);

                img {
                    @include imgContain;
                }
            }
        }
    }

    .rv-rt {
        @include max(800px) {
            @include flex-col;
            flex-direction: column-reverse;
        }
    }

    .rv-rt-item-wr {
        width: 100%;
        padding: 1rem 2rem;
        margin: 1.6rem 0;

        @include flex;
        align-items: center;
        gap: 1.6rem;
    }

    .rv-rt-item {
        .ava {
            width: 8rem;
            height: 8rem;

            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .name {
            flex: 1;

            .dname {
                margin-bottom: .4rem;
                color: $color-text;
                @include fz-16;
                font-style: normal;
                font-weight: 500;
                text-wrap: nowrap;
            }

            .sname {
                color: #828C8C;
                @include fz-14;
                font-style: normal;
                font-weight: 400;
            }
        }

        &.swiper-slide-thumb {
            .rv-rt-item-wr {
                transition: .3s;
            }
        }

        &.swiper-slide-thumb-active {
            .rv-rt-item-wr {
                background: rgba(255, 255, 255, 0.01);
                box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
            }
        }
    }

    .rvLfSlider {
        @include max(800px) {
            display: none;
        }
    }

    .rvRtSlider {
        @include mb(13.5rem);

        @include min(2000px) {
            margin-bottom: 4rem;
        }

        @include max(1440px) {
            @include mb(6rem);
        }

        .rv-rt-item.swiper-slide {
            @include max(1440px) {
                width: calc(100% / 2.6) !important;
            }

            @include max(1200px) {
                width: calc(100% / 3) !important;
            }

            @include max(950px) {
                width: calc(100% / 2) !important;
            }

            // @include max(1000px) {
            //     width: 100% !important;
            // }

            // @include max(800px) {
            //     width: calc(100% / 2) !important;
            // }

            @include max(580px) {
                width: 100% !important;
            }

            @include min(2000px) {
                width: calc(100% / 2) !important;
            }
        }

        .swiper-slide-active {
            // background: rgba(255, 255, 255, 0.01);
            // box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .rv-rt-content {
        width: 100%;

        &-wr {

            .quote,
            .quote1 {
                width: 4rem;
                height: 4rem;

                @include max(800px) {
                    width: 3rem;
                    height: 3rem;
                }

                @include max(425px) {
                    width: 2rem;
                    height: 2rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .quote {
                @include mb(1.2rem);
            }

            .quote1 {
                margin-left: auto;
            }

            .text {
                @include mb(1.2rem);
                color: #3A4040;
                @include fz-24;
                font-weight: 300;
            }
        }
    }

    .rvRtContentSlider {
        width: 100%;
        padding-right: var(--half);
    }

    .rv-rt-control {
        @include pt(4rem);
        @include pb(17rem);

        @include max(1440px) {
            padding-top: 2rem;
            padding-bottom: 6rem;
        }

        @include max(425px) {
            padding-top: 0;
            padding-bottom: 4rem;
        }

        @include min(2000px) {
            padding-bottom: 4rem;
        }

        @include max(800px) {
            order: -1;
        }
    }
}

.author {
    &-wr {}

    &-name {
        color: $color-text;
        @include fz-14;
        font-weight: 400;
        padding-right: .8rem;
    }

    .dtime {
        padding-left: .8rem;
        position: relative;
        color: $color-text-second;
        @include fz-14;
        font-weight: 400;

        &::before {
            @include pseudo;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            border-left: 1px solid #828C8C;
        }
    }
}

.blogs {
    @include min(1201px) {
        padding-bottom: 2rem;
    }

    @include max(425px) {
        padding-bottom: 2rem;
    }



    // @include bg;

    // .bg {
    //     img {
    //         object-position: top left;
    //     }
    // }

    .head {
        @include flex;
        align-items: center;
        justify-content: space-between;

        @include max(425px) {
            flex-direction: column;
            align-items: start;

            .title {
                margin-bottom: .8rem !important;

                &-second {
                    text-wrap: balance;
                }
            }
        }

        @include mb(4.8rem);

        .title {
            margin: unset;
            width: 41.1rem;

            .tsub {
                text-align: left;
            }

            &-second {
                text-align: left;
            }
        }

        .btn {}
    }

    &-wr {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 3.2rem;

        @include min(426px) {
            margin: 0 -.8rem;
        }


        @include max(1200px) {
            row-gap: 1.6rem;
        }

        @include max(700px) {
            grid-template-columns: repeat(2, 1fr);
            // grid-template-columns: 1fr;
            grid-template-rows: repeat(4, 1fr);
        }

        @include max(425px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(5, 1fr);
        }

        // row-gap: var(--it-pd);

        @include min(1233px) {
            margin: 0 -1.6rem;
        }
    }

    // grid it
    .blog-card {
        margin-bottom: unset !important;
        width: 100%;

        @include min(426px) {
            &:nth-child(1) {
                grid-column: 1/3;
                grid-row: 1/3;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {

                .author,
                .desc {
                    display: none;
                }

            }
        }

        @include max(425px) {
            padding: unset
        }
    }

    .blog-card {
        &-inner {
            .thumb {
                @include mb(1.2rem);
                border-radius: 1.6rem;
                @include imgPd(146, 280);

                .tag {
                    top: 1.2rem;
                    left: 1.2rem;
                }
            }
        }

        &-name {
            .link {
                @include fz-16;
                font-weight: 600;
                @include lc(2);
                margin-bottom: unset;
            }
        }

        &-content {
            .author {
                margin-bottom: .6rems;
            }
        }

        &-name {
            @include mb(1.2rem);
        }

        &-link {
            display: none;
        }

        .desc {
            @include fz-16;
        }

        &:nth-child(1) {
            .blog-card-inner {
                .thumb {
                    @include mb(2.4rem);

                    // @include imgPd(382, 592);
                    @include max(768px) {
                        padding-top: calc((170 / 280) * 100%);
                    }

                    .tag {
                        top: 2.4rem;
                        left: 2.4rem;
                    }
                }



            }

            .blog-card-name {
                .link {
                    @include fz-24;
                }
            }

            @include max(700px) {
                margin-bottom: 2rem;
            }

            // @include max(425px) {
            //     margin-bottom: 4rem;
            // }
        }

        &:not(:nth-child(1)) {
            .blog-card-inner {
                .thumb {
                    padding-top: calc((170 / 280) * 100%);
                }
            }
        }
    }
}

.page-home {

    // padding-top: calc(var(--size-hd)/2);
    padding-top: unset;
    // @include min(1201px) {
    // }

    .banner {
        &-ctn {
            // padding-top: calc(var(--size-hd) + 2.4rem);
            // min-height: calc(100vw*(800/1728));

            .content {
                width: 66.8rem;
                max-width: 100%;

                @include max(600px) {
                    width: 100%;
                }

                .tsub {
                    @include mb(1.2rem);
                    width: fit-content;
                    padding: 1.2rem;
                    color: #F5F7F7;
                    @include fz-48;
                    font-weight: 600;
                    border-radius: 1.6rem;
                    line-height: normal;
                    background: radial-gradient(1526.76% 267.9% at -101.2% 117.07%, #0052AA 5.92%, #156CC9 65.73%);
                }

                .title {
                    width: 100%;

                    &-pri {
                        @include mb(2.4rem);
                        line-height: normal;
                    }

                    &-sub {
                        @include mb(3.2rem);
                        width: 100%;
                        text-align: left;
                    }
                }
            }

            @include max(1200px) {
                width: 100%;
                height: calc(100vh - var(--size-hd));
                min-height: unset;
                justify-content: end;
                padding-bottom: 6rem;
            }
        }

        .bg {
            &::after {
                @include max(425px) {
                    display: none;
                }
            }
        }

        .content {
            position: relative;

            &::before {
                @include min(426px) {
                    display: none;
                }

                @include pseudo;
                top: 0;
                left: -1.6rem;
                right: -1.6rem;
                bottom: 0;
                height: 100%;
                width: calc(100% + 3.2rem);
                background: $color-white;
                z-index: -1;
                transform: translateY(25%);
            }
        }

        .wave-bg1 {
            @include min(426px) {
                display: none;
            }


            position: absolute;
            // height: 100%;
            width: 100%;
            top: 0;
            left: -1.6rem;
            right: -1.6rem;
            width: calc(100% + 3.2rem);
            z-index: -1;
            transform: translateY(-50%);

            &-inner {
                @include imgPd(147, 339);

                img {
                    @include imgCover;
                    object-position: top left;
                }
            }

        }
    }

    .partner,
    .prd,
    .his {
        @include pb(10rem);

        @include max(1200px) {
            @include pb(6rem);
        }
    }

    .his {
        @include min(426px) {
            @include pt(5rem);
        }
    }

    .blogs .blog-card-name {
        &:not(:first-child) {
            margin-bottom: 0;
        }
    }

    // remake in display full
    .the-num {
        &:first-child {

            .the-num-wr .tnum .num,
            .the-num-wr .tnum .plus {
                @include min(501px) {
                    @include fz-64;
                }

                @include max(500px) {
                    @include fz-32;
                }
            }
        }

        &:not(:first-child) {

            .the-num-wr .tnum .num,
            .the-num-wr .tnum .plus {
                @include fz-32;
            }
        }

        @include min(1440px) {
            &:nth-child(1) {
                left: -8%;
            }

            &:nth-child(2) {
                top: 23%;
                right: -8%;
            }

            &:nth-child(3) {
                bottom: 30%;
                right: -15%;
            }

            &:nth-child(4) {
                bottom: -1%;
            }
        }

        @include min(2000px) {

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                right: -2%;
            }
        }
    }

    // .tn .the-num-wr .tnum .num,
    // .tn .the-num-wr .tnum .plus {
    //     @include fz-32;
    // }

    .partner {
        padding-bottom: unset;
    }

    .blogs {
        @include pt(10rem);

        @include max(500px) {
            padding-bottom: 2rem;
        }

        position: relative;

        &::before {
            @include pseudo;
            width: 100%;
            height: 100%;
            background: #F6FDF5;
            z-index: -1;
            clip-path: polygon(0 0, 100% 85%, 100% 100%, 0% 100%);
        }
    }

    .ft {
        position: relative;

        &::before {
            @include pseudo;
            width: 100%;
            height: 100%;
            background: #F6FDF5;
            z-index: -1;
        }

        &-wr {

            .bg {
                z-index: 0;
                pointer-events: none;
            }
        }

        &-flex {
            position: relative;
        }
    }
}

.main {
    overflow: hidden;
}