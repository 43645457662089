.side {
    &-fixed {
        @include max($res-mobile) {
            position: fixed;
            width: 320px;
            max-width: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: #FFF;
            z-index: 51;
            transition: 0.4s ease-in-out;
            opacity: 0;
            transform: translateX(110%);
            visibility: hidden;

            &.open {
                opacity: 1;
                transform: translate(0, 0);
                visibility: visible;
            }
        }

        &-wrap {
            @include max($res-mobile) {
                padding: 5.5rem 1.5rem 1.5rem 1.5rem;
                width: 100%;
                height: 100%;
                overflow-y: auto;
            }
        }
    }

    &-overlay {
        position: fixed;
        @include full;
        background-color: rgba($color: $color-black, $alpha: 0.6);
        z-index: 50;
        transition: 0.5s ease-in-out;
        opacity: 0;
        transform: translateX(110%);
        visibility: hidden;

        &.open {
            opacity: 1;
            transform: translate(0, 0);
            visibility: visible;
        }

        @include min($res-mobile-min) {
            display: none;
        }
    }

    &-open {
        &:not(.no-style) {
            width: 4rem;
            height: 4rem;
            position: fixed;
            right: 0;
            // @include posi-vertical;
            z-index: 40;
            background-color: $color-pri;
            @include mid-flex;
            border-radius: 100rem 0 0 100rem;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &.close {
                opacity: 0;
                transform: translate(100%, -50%);
                visibility: visible;
            }

            @include min($res-mobile-min) {
                display: none;
            }

            &-wrap {
                width: 3rem;
                height: 3rem;
                border-radius: 100rem;
                background-color: white;
                @include mid-flex;

                .icon {
                    color: $color-white;
                    font-weight: 600;
                    font-size: 1.6rem;
                }
            }
        }
        &-wrap {
            .icon {
                color: $color-white;
            }
        }
    }

    &-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4.5rem;
        padding: 0 0 0 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        background-color: $color-second;

        @include min($res-mobile-min) {
            display: none;
        }

        .text {
            @include lc(1);
        }
    }

    &-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 3rem;
        background-color: $color-pri;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 0 0.4rem;

        .icon {
            font-size: 1.8rem;
            font-weight: 400;
            transition: 0.6s ease-in-out;
            color: $color-white;
        }

        @include min($res-mobile-min) {
            display: none;
        }
    }
}