.row {
    --cg: 3.2rem;
    --rg: 3.2rem;
    @include flex-wr;
    margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1);
    // row-gap: var(--it-pd);
}

.rows {
    --cg: 3.2rem;
    --rg: 3.2rem;
    margin: 0 calc(var(--cg) / 2 * -1);
}

.col {
    width: 100%;
    padding: 0 calc(var(--cg) / 2);
    margin-bottom: var(--rg);

    &-11 {
        width: calc(11/12 * 100%);
    }

    &-10 {
        width: calc(10/12 * 100%);
    }

    &-9 {
        width: calc(9/12 * 100%);
    }

    &-8 {
        width: calc(8/12 * 100%);
    }

    &-7 {
        width: calc(7/12 * 100%);
    }

    &-6 {
        width: 50%;
    }

    &-5 {
        width: calc(5/12 * 100%);
    }

    &-4 {
        width: calc(4/12 * 100%);
    }

    &-3 {
        width: calc(3/12 * 100%);
    }

    &-2 {
        width: calc(2/12 * 100%);
    }

    &-1 {
        width: calc(1/12 * 100%);
    }

    &-5i {
        width: calc(100% / 5);
    }

    &-4i {
        width: calc(100% / 4);
    }
}


.dnor {
    @include flex-st;
    margin: 0 -1.5rem;
    flex-wrap: wrap;
    margin-bottom: -3rem;

    &-item {
        padding: 0 1.5rem;
        margin-bottom: 3rem
    }
}

.dsmall {
    @include flex-st;
    flex-wrap: wrap;
    margin: 0 -1.6rem;
    margin-bottom: -3.2rem;

    &-item {
        padding: 0 1.6rem;
        margin-bottom: 3.2rem
    }
}

.dmini {
    @include flex-st;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    margin-bottom: -1rem;

    &-item {
        padding: 0 0.5rem;
        margin-bottom: 1rem
    }
}

.dside {
    @include flex-st;
    flex-wrap: wrap;
}