.mb-0 {
    margin-bottom: 0 !important;
}

.mb-2 {
    margin-bottom: 0.2rem;
}

.mb-4 {
    margin-bottom: 0.4rem;
}

.mb-6 {
    margin-bottom: 0.6rem;
}

.mb-8 {
    margin-bottom: 0.8rem;
}

.mb-8s {
    &:not(:last-child) {
        margin-bottom: 0.8rem;
    }
}

.mb-10 {
    margin-bottom: 1rem;
}

.mb-10s {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.mb-12 {
    margin-bottom: 1.2rem;
}

.mb-12s {
    &:not(:last-child) {
        margin-bottom: 1.2rem;
    }
}

.mb-14 {
    margin-bottom: 1.4rem;
}

.mb-14s {
    &:not(:last-child) {
        margin-bottom: 1.4rem;
    }
}

.mb-15 {
    margin-bottom: 1.5rem;
}

.mb-15s {
    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.mb-16 {
    margin-bottom: 1.6rem;
}

.mb-16s {
    &:not(:last-child) {
        margin-bottom: 1.6rem;
    }
}

.mb-18 {
    margin-bottom: 1.8rem;
}

.mb-18s {
    &:not(:last-child) {
        margin-bottom: 1.8rem;
    }
}

.mb-20 {
    margin-bottom: 2rem;
}

.mb-20s {
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.mb-24 {
    margin-bottom: 2.4rem;
}

.mb-24s {
    &:not(:last-child) {
        margin-bottom: 2.4rem;
    }
}

.mb-25 {
    margin-bottom: 2.5rem;
}

.mb-25s {
    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }
}

.mb-30 {
    margin-bottom: 3rem;
}

.mb-30s {
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}

.mb-32 {
    margin-bottom: 3.2rem;
}

.mb-32s {
    &:not(:last-child) {
        margin-bottom: 3.2rem;
    }
}

.mb-40 {
    margin-bottom: 4rem;
}

.mb-40s {
    &:not(:last-child) {
        margin-bottom: 4rem;
    }
}

.mb-48 {
    margin-bottom: 4.8rem;
}

.mb-48s {
    &:not(:last-child) {
        margin-bottom: 4.8rem;
    }
}

.mb-56 {
    margin-bottom: 5.6rem;
}

.mb-56s {
    &:not(:last-child) {
        margin-bottom: 5.6rem;
    }
}

.mb-64 {
    margin-bottom: 6.4rem;
}

.mb-64s {
    &:not(:last-child) {
        margin-bottom: 6.4rem;
    }
}

.mb-72 {
    margin-bottom: 7.2rem;
}

.mb-72s {
    &:not(:last-child) {
        margin-bottom: 7.2rem;
    }
}

.mb-80 {
    margin-bottom: 8rem;
}

.mb-80s {
    &:not(:last-child) {
        margin-bottom: 8rem;
    }
}

.ss-mg {
    margin: var(--pd-sc) 0;
}

.ss-mg-t {
    margin-top: var(--pd-sc);
}

.ss-mg-b {
    margin-bottom: var(--pd-sc);
}

.ss-pd {
    padding: var(--pd-sc) 0;
}

.ss-pd-t {
    padding-top: var(--pd-sc);
}

.ss-pd-b {
    padding-bottom: var(--pd-sc);
}