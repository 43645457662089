.blog-sect {
    .prod-search {
        width: 43rem;

        @include max(575px) {
            width: 100%;
        }
    }
}

.blog-aside-wr {
    display: flex;
    flex-direction: column;

    @include min(801px) {
        row-gap: 3.2rem;
    }
}

.blog-list-wr {
    @include max(800px) {
        width: 100%;

        .blog-list.row {
            --cg: 1.6rem;
        }
    }

}

.blog-aside {
    position: sticky;
    top: var(--size-hd);
    height: 100%;

    @include max(800px) {
        position: unset;
        width: 100%;
    }

    &-wr {
        .benefit-item {
            margin-bottom: 3.2rem;

            @include max(1200px) {
                margin-bottom: 1.6rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &-wr {
                align-items: center;
            }
        }

        .benefit {
            margin-top: 0;
        }

        .benefit-wr {
            @include max(800px) {
                display: flex;
                flex-wrap: wrap;
                margin: 0 calc(-1 * var(--it-pd));
                row-gap: var(--it-pd);
            }

            .benefit-item {
                @include max(800px) {
                    // width: calc(4/12*100%);
                    width: 100%;
                    padding: 0 var(--it-pd);
                }

                // @include max(700px) {
                //     width: 50%;
                // }

                // @include max(450px) {
                //     width: 100%;
                // }
            }
        }
    }

    // .sticky {
    //     position: sticky;
    //     top: calc(var(--size-hd) + 1rem);
    // }
}

.blog-card {
    width: 50%;
    padding: 0 var(--it-pd);

    @include max(390px) {
        width: 100%;
    }

    .thumb {
        @include imgPd(288, 384);
        border-radius: 2.4rem;
        margin-bottom: 1.6rem;

        img {
            @include imgCover;
            transition: .3s;
        }

        @include hover {
            img {
                transform: translate(-50%, -50%)scale(1.1);
            }
        }

        .tag {
            min-width: 4.7rem;
            position: absolute;
            top: 2.4rem;
            left: 2.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.5rem 1rem;
            border-radius: 0.8rem;
            color: #F5F7F7;
            pointer-events: none;
            background-color: $color-pri;

            .day {
                font-size: 2.4rem;
                font-weight: 600;
            }

            .month {
                font-size: 1.2rem;
            }
        }
    }

    .blog-card-name {
        .link {
            color: $color-text;
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1.2rem;
            @include lc(3);

            @include max(1024px) {
                font-size: 1.6rem;
                margin-bottom: 0;
            }

            @include lc(2);

            @include hover {
                color: $color-pri;
            }
        }
    }

    .desc {
        @include lc(2);
        font-size: 1.4rem;
    }

    .blog-card-link {
        font-weight: 500;
        padding: 1.6rem 0;
        display: flex;
        align-items: center;
        color: $color-pri;

        @include max(1024px) {
            font-size: 1.4rem;
            padding: 1.2rem 0;
        }

        .ic {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
            flex-shrink: 0;
            margin-left: 1.2rem;

            @include max(1024px) {
                width: 1.2rem;
                height: 1.2rem;
            }

            img {
                width: 100%;
                height: 100%;
            }

            transition: .3s;
        }

        @include hover {
            .ic {
                transform: translateX(.2rem);
            }
        }
    }
}

.topic-block {
    @include max(800px) {
        margin-bottom: 2.4rem;
    }

    .topic-block-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #282B27;
        font-size: 2.4rem;
        font-weight: 600;
        border-bottom: 0.1rem solid #E0E0E0;
        padding-bottom: 2.4rem;
        margin-bottom: 2.4rem;

        @include max(800px) {
            padding-bottom: 1.2rem;
            margin-bottom: 1.2rem;
        }

        .ic {
            display: flex;
            width: 3.2rem;
            height: 3.2rem;
            display: none;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    #ez-toc-container {
        background: transparent;
        border: unset;
        border-radius: unset;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: unset;
        width: 100%;
        display: table;
        margin-bottom: unset;
        padding: 0 2rem;

        @include max(800px) {
            padding: 0;
        }

        .ez-toc-btn {
            display: none;
        }

        .ez-toc-title {
            color: var(--TextColor-NeutralPrimary, #282B27);
            @include font-pri;
            @include fz-24;
            font-weight: 600;

            width: 100%;
            padding: 2.5rem 0;

            @include max(800px) {
                padding: 1.25rem 0;
            }
        }

        a {
            display: block;
            color: var(--TextColor-NeutralPrimary, #282B27);
            @include font-pri;
            @include fz-20;

            @media only screen and (max-width: 800px) {
                font-size: 1.6rem;
            }

            @media only screen and (max-width: 425px) {
                font-size: 1.4rem;
            }

            font-style: normal;
            font-weight: 500;
            padding: 1.4rem 0;
            width: 100%;
            border-bottom: 1px solid var(--BoderColor-BorderNeutralSecondary, #E0E2DE);

            @include max(800px) {
                padding: 1.2rem 0;
            }

            @include max(425px) {
                padding: .6rem 0;
            }
        }
    }
}

.blog-topic {
    position: sticky;
    top: var(--size-hd);
    height: 100%;

    @include max(800px) {
        position: fixed;
        z-index: 50;
        right: 0;
        bottom: 0;
        width: 35rem;
        background-color: #fff;
        padding: 0;
        transform: translate(100%, 0);
        transition: all .3s;

        @include max(575px) {
            width: 85%;
        }

        &.open {
            transform: translate(0, 0);
        }

        .blog-topic-wr {
            @include max(800px) {
                padding: 2rem;
            }
        }

        .filter-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translate(calc(-100% - 2rem), -50%);
            width: 4rem;
            height: 4rem;
            border-radius: 10rem 0 0 10rem;
            background-color: $color-pri;

            i {
                font-size: 1.6rem;
                color: #fff;
            }
        }
    }
}


.blog-topic-item {
    &:last-child {
        .blog-topic-heading {
            margin-bottom: 0;
        }
    }

    &.active {
        .blog-topic-heading {
            .ic {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .blog-topic-heading {
        padding-bottom: 2.4rem;
        margin-bottom: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.1rem solid #E0E0E0;
        font-weight: 500;
        font-size: 2rem;

        @include max(768px) {
            font-size: 1.8rem;
            padding-bottom: 1.2rem;
            margin-bottom: 1.2rem;
        }

        .ic {
            i {
                font-size: 1.6rem;
                transition: all .3s;
            }
        }
    }

    .blog-topic-content-list {
        padding-bottom: 2.4rem;
    }

    .blog-topic-content-item {
        cursor: pointer;

        &:last-child {
            .blog-card-link {
                border-bottom: none;
            }
        }

        .blog-topic-link {
            display: flex;
            padding: 2rem 0;
            border-bottom: 0.1rem solid #E0E0E0;
        }
    }
}


.most-blog {
    .most-blog-list {
        @include max(800px) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 calc(-1 * var(--it-pd));
            row-gap: 3.2rem;

            @include max(425px) {
                row-gap: 1.6rem;
            }
        }
    }

    .tt-main {
        margin-bottom: 2.8rem;

        @include max(800px) {
            margin-bottom: 1.4rem;
        }
    }

    .blog-card {
        width: 100%;
        background-color: #F5F7F7;
        margin-bottom: 1.6rem;
        padding: 2rem;
        padding-top: 1.6rem !important;

        border-radius: 1.6rem;

        @include max(800px) {
            padding-top: unset !important;
            width: 50%;
            padding: 0 var(--it-pd);
            background-color: transparent;
            margin-bottom: unset !important;
        }

        @include max(390px) {
            width: 100%;
        }

        .blog-card-inner {
            @include max(800px) {
                background-color: #F5F7F7;
                padding: 1.2rem;
                padding-bottom: unset;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .thumb {
            display: none;
        }
    }
}

.page-blog {
    overflow: unset !important;

    .blog-card {
        @include max(425px) {
            --rg: 1.6rem;
            --cg: 1.6rem;
        }

        padding: 0 calc(var(--rg) / 2);
        margin-bottom: var(--cg);

        .author {
            display: none;
        }
    }

    .benefit-item-wr {
        @include max(575px) {
            justify-content: start !important;
        }
    }

    @include max(800px) {
        .benefit {
            margin-bottom: 3.2rem;
        }
    }
}

// .sticky {
//     position: sticky;
//     top: calc(var(--size-hd) + 1rem);
// }