// Library
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/themify-icons/themify-icons.css");
@import url("../assets/library/select2/select2.min.css");
// @import url("../assets/library/themifi/themify-icons.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/splitting/splitting.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");
// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/variable";
@import "./core/font";
@import "./core/mixin";
@import "./core/base";
@import "./core/flex";
@import "./core/animation";
// Component
@import "./components/backtotop";
@import "./components/aos";
@import "./components/scroll";
@import "./components/header";
@import "./components/smenu";
@import "./components/footer";
@import "./components/burger";
@import "./components/mobile";
@import "./components/select2";
@import "./components/mirror";
@import "./components/sl";
@import "./components/popup";
@import "./components/loading";
@import "./components/datetime";
@import "./components/animation-scr";
@import "./components/custom-animation";
@import "./components/range";
// Layout
@import "./layouts/swiper";
@import "./layouts/ani";
@import "./layouts/breadcrumb";
@import "./layouts/btn";
@import "./layouts/input";
@import "./layouts/pagi";
@import "./layouts/pro";
@import "./layouts/search";
@import "./layouts/sidebar";
@import "./layouts/text";
@import "./layouts/component";
@import "./layouts/distance";
@import "./layouts/recheck";
@import "./layouts/star";
@import "./layouts/side";
@import "./layouts/user";
@import "./layouts/social";
@import "./layouts/cart";
@import "./layouts/prl";
// Pages
@import "./pages/common";
@import "./layouts/banner";
@import "./pages/blog";
@import "./pages/blog-dt";
@import "./pages/products";
@import "./pages/product-dt";
@import "./pages/contact";
@import "./pages/home";
@import "./pages/about";
// @import "./pages/childpage";
