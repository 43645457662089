:root {
    --size-hd: 12rem;

    // distance section
    --pd-sc: 8rem;
    --it-pd: 1.6rem;
    --ss-pd: 10rem;

    @media (max-width: 1200px) {
        --pd-sc: 4rem;
        --radius: .8rem;
        --size-hd: 6rem;
        --ss-pd: 5rem;
        --it-pd: 0.8rem;
    }

    // translate
    --transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
    // padding
    --pBot: calc((298/1728*100%));
    // Resistance
    --recontainer: calc(100vw - (100vw - 124.8rem)/2);
    --half: calc((100vw - 124.8rem)/2);

    // color
    --color-white:#FFF;
    --color-text:#101212;
    --color-text-second:#3A4040;
    --color-pri:#00783E;
    --color-second:#156CC9;
    --color-red:#C91515;
    --color-hover:#D0D9D9;

    // shadow
    --sdow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    // linear
    // --linear-pastel: linear-gradient(30deg, #FFE7E7, #FFC6C6);
    // --linear-blue:linear-gradient(135deg, #F5F8FF 0%, #E8F0FF 100%);
    // 
    // --filter:brightness(0) saturate(100%) invert(70%) sepia(9%) saturate(938%) hue-rotate(178deg) brightness(95%) contrast(90%);
    --filter: brightness(0) saturate(100%) invert(15%) sepia(99%) saturate(3367%) hue-rotate(154deg) brightness(98%) contrast(102%);
}

$color-pri-ln: rgba(0, 120, 62, 0.10);
$color-pri: var(--color-pri);
$color-pri2: var(--color-pri);
$color-second: var(--color-second);
$color-third: #408630;
$color-four: #FCB445;
$color-five: #f05123;
$color-black: #000000;
$color-black-four: #0A0A0A;
$color-black-five : #0A0A0A;
$color-white: #ffffff;
$color-text: var(--color-text);
$color-text-second: var(--color-text-second);
$color-text-five: #4D4D4D;
$color-grey-second: #EDEDED;

$color-border: rgba(0, 0, 0, 0.1);
$color-border-01: rgba(0, 0, 0, 0.1);
$color-border-02: rgba(0, 0, 0, 0.2);
$color-border-03: rgba(0, 0, 0, 0.3);
$color-border-04: rgba(0, 0, 0, 0.4);
$color-border-05: rgba(0, 0, 0, 0.5);
$color-border-06: rgba(0, 0, 0, 0.6);
$color-border-07: rgba(0, 0, 0, 0.7);
$color-border-08: rgba(0, 0, 0, 0.8);
$color-border-09: rgba(0, 0, 0, 0.9);
$color-border-10: #E0E0E0;
$color-opa-white-01: rgba(255, 255, 255, 0.1);
$color-opa-white-02: rgba(255, 255, 255, 0.2);
$color-opa-white-03: rgba(255, 255, 255, 0.3);
$color-opa-white-04: rgba(255, 255, 255, 0.4);
$color-opa-white-05: rgba(255, 255, 255, 0.5);
$color-opa-white-06: rgba(255, 255, 255, 0.6);
$color-opa-white-07: rgba(255, 255, 255, 0.7);
$color-opa-white-08: rgba(255, 255, 255, 0.8);
$color-opa-white-09: rgba(255, 255, 255, 0.9);
$color-red-opa-3: #FCEAE9;
//Responsive
$res-mobile: 1200px;
$res-mobile-min: 1201px;
$mobile: 500px;
//Distance
$radius: 0.6rem;
// cubic
$cubic: cubic-bezier(0.72, 0.04, 0.49, 0.82);


$h-top: 6rem;
$h-bot: 2.5rem;