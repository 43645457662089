@charset "UTF-8";
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/themify-icons/themify-icons.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/splitting/splitting.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");
@import url("../assets/font/Mulish/stylesheet.css");
@import url("../assets/font/Roboto/stylesheet.css");
@import url("../assets/font/SVN-Poppins/stylesheet.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.mona-content strong,
.mona-content b {
  font-weight: bold; }

.mona-content em,
.mona-content i {
  font-style: italic; }

.mona-content h1,
.mona-content h2,
.mona-content h3,
.mona-content h4,
.mona-content h5,
.mona-content h6 {
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0.6em; }

.mona-content h1 {
  font-size: 1.7em; }

.mona-content h2 {
  font-size: 1.6em; }

.mona-content h3 {
  font-size: 1.25em; }

.mona-content h4 {
  font-size: 1.125em; }

.mona-content h5 {
  font-size: 1em; }

.mona-content h6 {
  font-size: 0.85em; }

.mona-content p {
  margin: 1em 0; }

.mona-content ul,
.mona-content ol {
  margin: 1em 0;
  list-style-position: inside; }

.mona-content ul ul,
.mona-content ul ol,
.mona-content ol ul,
.mona-content ol ol {
  margin-left: 1em; }

.mona-content ul {
  list-style-type: disc; }

.mona-content ol {
  list-style-type: decimal; }

.mona-content ul ul,
.mona-content ol ul {
  list-style-type: circle; }

.mona-content ol ol,
.mona-content ul ol {
  list-style-type: lower-latin; }

.mona-content img,
.mona-content iframe {
  max-width: 100% !important;
  height: auto; }

.mona-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1em 0;
  padding: 1em;
  quotes: "\201C" "\201D" "\2018" "\2019"; }
  .mona-content blockquote::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  .mona-content blockquote p {
    display: inline; }

.mona-content table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 1em 0;
  border: 1px solid #e1e1e1; }
  .mona-content table th,
  .mona-content table td {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 10px;
    vertical-align: middle; }

.mona-content .mona-youtube-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%; }
  .mona-content .mona-youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.mona-content .mona-table-wrap {
  overflow: auto; }

.mona-content iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%; }

:root {
  --size-hd: 12rem;
  --pd-sc: 8rem;
  --it-pd: 1.6rem;
  --ss-pd: 10rem;
  --transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  --pBot: calc((298/1728*100%));
  --recontainer: calc(100vw - (100vw - 124.8rem)/2);
  --half: calc((100vw - 124.8rem)/2);
  --color-white:#FFF;
  --color-text:#101212;
  --color-text-second:#3A4040;
  --color-pri:#00783E;
  --color-second:#156CC9;
  --color-red:#C91515;
  --color-hover:#D0D9D9;
  --sdow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
  --filter: brightness(0) saturate(100%) invert(15%) sepia(99%) saturate(3367%) hue-rotate(154deg) brightness(98%) contrast(102%); }
  @media (max-width: 1200px) {
    :root {
      --pd-sc: 4rem;
      --radius: .8rem;
      --size-hd: 6rem;
      --ss-pd: 5rem;
      --it-pd: 0.8rem; } }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media screen and (min-width: 2000px) {
    html {
      font-size: 100%; } }

body {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--color-text);
  overflow: hidden auto; }
  @media screen and (min-width: 2000px) {
    body {
      font-size: 20px; } }

a {
  text-decoration: none;
  color: inherit; }

button,
select {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif; }

button {
  padding: 0;
  background-color: transparent; }

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Roboto", sans-serif; }

.main {
  min-height: 68vh; }
  @media screen and (max-width: 1200px) {
    .main {
      padding-top: var(--size-hd); } }

.container {
  width: 100%;
  max-width: 124.8rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.6rem; }

.ctn-f {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0; }
  @media screen and (max-width: 1248px) {
    .ctn-f {
      padding: 0 1.6rem; } }

.ctn {
  height: 100%;
  margin: 0;
  margin-left: auto;
  padding: 0 1.6rem; }
  @media screen and (min-width: 1249px) {
    .ctn {
      width: var(--recontainer);
      max-width: unset; } }
  @media screen and (max-width: 1248px) {
    .ctn {
      width: 100%;
      max-width: 124.8rem; } }

.over {
  overflow-x: auto;
  scroll-snap-type: x mandatory; }
  .over::-webkit-scrollbar {
    display: none; }

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; }

iframe {
  vertical-align: middle; }

.load-item {
  display: none; }

.show-hide-toggle {
  cursor: pointer; }

img {
  max-width: 100%;
  border-radius: inherit;
  height: auto; }

.global-title {
  max-width: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden; }

input,
textarea {
  border: none;
  background-color: transparent;
  outline: none; }

.collapse-head {
  cursor: pointer; }

.collapse-body {
  display: none; }

.ss-pd-t {
  padding-top: var(--ss-pd); }

.ss-pd-b {
  padding-bottom: var(--ss-pd); }

.ss-pd {
  padding: var(--ss-pd) 0; }

.row {
  --cg: 3.2rem;
  --rg: 3.2rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1); }

.rows {
  --cg: 3.2rem;
  --rg: 3.2rem;
  margin: 0 calc(var(--cg) / 2 * -1); }

.col {
  width: 100%;
  padding: 0 calc(var(--cg) / 2);
  margin-bottom: var(--rg); }
  .col-11 {
    width: calc(11/12 * 100%); }
  .col-10 {
    width: calc(10/12 * 100%); }
  .col-9 {
    width: calc(9/12 * 100%); }
  .col-8 {
    width: calc(8/12 * 100%); }
  .col-7 {
    width: calc(7/12 * 100%); }
  .col-6 {
    width: 50%; }
  .col-5 {
    width: calc(5/12 * 100%); }
  .col-4 {
    width: calc(4/12 * 100%); }
  .col-3 {
    width: calc(3/12 * 100%); }
  .col-2 {
    width: calc(2/12 * 100%); }
  .col-1 {
    width: calc(1/12 * 100%); }
  .col-5i {
    width: calc(100% / 5); }
  .col-4i {
    width: calc(100% / 4); }

.dnor {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  flex-wrap: wrap;
  margin-bottom: -3rem; }
  .dnor-item {
    padding: 0 1.5rem;
    margin-bottom: 3rem; }

.dsmall {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1.6rem;
  margin-bottom: -3.2rem; }
  .dsmall-item {
    padding: 0 1.6rem;
    margin-bottom: 3.2rem; }

.dmini {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  margin-bottom: -1rem; }
  .dmini-item {
    padding: 0 0.5rem;
    margin-bottom: 1rem; }

.dside {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  flex-wrap: wrap; }

@-webkit-keyframes leaf {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    transform: translateX(70vw) rotateZ(-360deg) translateZ(150rem);
    opacity: 0;
    top: 100%; } }

@-moz-keyframes leaf {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    transform: translateX(70vw) rotateZ(-360deg) translateZ(150rem);
    opacity: 0;
    top: 100%; } }

@-ms-keyframes leaf {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    transform: translateX(70vw) rotateZ(-360deg) translateZ(150rem);
    opacity: 0;
    top: 100%; } }

@-o-keyframes leaf {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    transform: translateX(70vw) rotateZ(-360deg) translateZ(150rem);
    opacity: 0;
    top: 100%; } }

@keyframes leaf {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    transform: translateX(70vw) rotateZ(-360deg) translateZ(150rem);
    opacity: 0;
    top: 100%; } }

@-webkit-keyframes leafAnim {
  0% {
    right: 10rem;
    transform: rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    right: 200rem;
    transform: rotateZ(360deg) translateZ(150rem);
    opacity: 0; } }

@-moz-keyframes leafAnim {
  0% {
    right: 10rem;
    transform: rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    right: 200rem;
    transform: rotateZ(360deg) translateZ(150rem);
    opacity: 0; } }

@-ms-keyframes leafAnim {
  0% {
    right: 10rem;
    transform: rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    right: 200rem;
    transform: rotateZ(360deg) translateZ(150rem);
    opacity: 0; } }

@-o-keyframes leafAnim {
  0% {
    right: 10rem;
    transform: rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    right: 200rem;
    transform: rotateZ(360deg) translateZ(150rem);
    opacity: 0; } }

@keyframes leafAnim {
  0% {
    right: 10rem;
    transform: rotateZ(0) translateZ(0);
    opacity: 1; }
  100% {
    right: 200rem;
    transform: rotateZ(360deg) translateZ(150rem);
    opacity: 0; } }

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-ms-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@-moz-keyframes shine {
  100% {
    left: 125%; } }

@-ms-keyframes shine {
  100% {
    left: 125%; } }

@-o-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-moz-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-ms-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-o-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-moz-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-ms-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-o-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-o-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-moz-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-ms-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-o-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-webkit-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-moz-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-ms-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-o-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-webkit-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-moz-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-ms-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-o-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-webkit-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-moz-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-ms-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-o-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-webkit-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-moz-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-ms-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-o-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-webkit-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-moz-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-ms-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-o-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-webkit-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-moz-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-ms-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-o-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-webkit-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-moz-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-ms-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-o-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-moz-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-ms-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-o-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-webkit-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-moz-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-ms-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-o-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-webkit-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-moz-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-ms-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-o-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-webkit-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-moz-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-ms-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-o-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-webkit-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-moz-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-ms-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-o-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-webkit-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-moz-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-ms-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-o-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-webkit-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@-moz-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@-ms-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@-o-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@keyframes animateSvg {
  0% {
    stroke-dashoffset: 2689.942138671875px;
    stroke-dasharray: 2689.942138671875px; }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2689.942138671875px; } }

@keyframes note {
  0% {
    transform-origin: top center;
    transform: rotate(0deg); }
  20% {
    transform: rotate(-5deg); }
  40% {
    transform: rotate(3deg); }
  60% {
    transform: rotate(-2deg); }
  80% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes star {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes rotate45 {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(180deg); } }

@keyframes rotate360 {
  0% {
    transform: translate(-50%, -50%) rotate(0); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

.back-to-top {
  width: 5rem;
  height: 5rem;
  background: white;
  border-radius: 0.6rem;
  position: fixed;
  bottom: 21.5rem;
  right: 3rem;
  z-index: 7;
  transform: translateX(3rem);
  opacity: 0;
  transition: 0.5s linear;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 100rem;
  padding: 1rem 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
  @media screen and (max-width: 1440px) {
    .back-to-top {
      bottom: 18.5rem; } }
  .back-to-top img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  @media screen and (min-width: 1200px) {
    .back-to-top img {
      -webkit-animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
      animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both; }
    @-webkit-keyframes shake-vertical {
      0%,
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      10%,
      30%,
      50%,
      70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px); }
      20%,
      40%,
      60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px); }
      80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px); }
      90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px); } }
    @keyframes shake-vertical {
      0%,
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      10%,
      30%,
      50%,
      70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px); }
      20%,
      40%,
      60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px); }
      80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px); }
      90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px); } } }
  @media screen and (max-width: 1440px) {
    .back-to-top {
      width: 4rem;
      height: 4rem; } }
  @media screen and (max-width: 768px) {
    .back-to-top {
      right: 1.5rem; } }
  .back-to-top.active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1; }
  .back-to-top .triangle {
    display: block;
    width: 100%;
    height: 0.5rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center; }
    .back-to-top .triangle i {
      color: #408630; }
    .back-to-top .triangle:nth-child(1) {
      animation: BTT 1.5s linear 0.6s infinite; }
    .back-to-top .triangle:nth-child(2) {
      animation: BTT 1.5s linear 0.3s infinite; }
    .back-to-top .triangle:nth-child(3) {
      animation: BTT 1.5s linear 0s infinite; }
  @media (hover: hover) {
    .back-to-top:hover .icon {
      -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
      animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both; } }
  .back-to-top .icon {
    display: flex; }
  .back-to-top .btn-rotate {
    width: 100%;
    height: 100%; }
    .back-to-top .btn-rotate .rounded-text {
      fill: white; }

@-webkit-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

[data-aos="fade-up-cus"] {
  transform: translate(0, 4rem);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-up-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-down-cus"] {
  transform: translate(0, -4rem);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-down-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-left-cus"] {
  transform: translate(4rem, 0);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-left-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-right-cus"] {
  transform: translate(-4rem, 0);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-right-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

::-webkit-scrollbar {
  width: 0.5rem; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-pri);
  border-radius: 100rem; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-pri); }

.header {
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50 !important;
  padding-top: 2.4rem;
  transition: 0.3s; }
  @media screen and (max-width: 1200px) {
    .header {
      height: auto;
      padding: 0; } }
  .header.sticky {
    padding-top: 0; }
    .header.sticky .header-wrapper {
      max-width: 100%;
      border-radius: 0; }
    @media screen and (min-width: 1201px) {
      .header.sticky .header-wr {
        height: calc(var(--size-hd) / 1.5); } }
    .header.sticky .header-logo .custom-logo-link {
      height: 4.2rem; }
      @media screen and (max-width: 320px) {
        .header.sticky .header-logo .custom-logo-link {
          height: 3.2rem; } }
    .header.sticky .header-nav .menu-nav > .menu-list > .menu-item::before {
      bottom: 1.6rem; }
    .header.sticky .mega {
      top: 1.8rem; }
    .header.sticky .header-srch-ip {
      top: 5.4rem; }
      @media screen and (max-width: 1200px) {
        .header.sticky .header-srch-ip {
          top: 4.4rem; } }
  .header.customize {
    position: fixed; }
  .header-wrapper {
    max-width: calc(124.8rem + 31.2rem);
    box-shadow: var(--sdow);
    transition: 0.3s;
    border-radius: 7.3rem;
    margin: auto;
    background-color: #ffffff; }
    @media screen and (max-width: 1200px) {
      .header-wrapper {
        width: 100%;
        border-radius: 0; } }
  .header-wr {
    height: calc(var(--size-hd) - 2.4rem);
    max-width: 124.8rem;
    padding: 0 1.6rem;
    margin: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 1200px) {
      .header-wr {
        width: 100%;
        border-radius: 0;
        padding: 1.5rem;
        height: 6rem; } }
  .header-right {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
  .header-logo .custom-logo-link {
    display: block;
    transition: 0.4s ease-in-out;
    height: 6.4rem;
    width: auto; }
    @media screen and (max-width: 1200px) {
      .header-logo .custom-logo-link {
        height: 4.2rem; } }
  @media screen and (max-width: 1200px) and (max-width: 320px) {
    .header-logo .custom-logo-link {
      height: 3.2rem; } }
    .header-logo .custom-logo-link img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block; }
  .header-nav {
    position: relative;
    height: 100%; }
    @media screen and (max-width: 1200px) {
      .header-nav {
        display: none; } }
  .header-action {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .header-action {
        gap: .8rem; } }
  .header-lg {
    display: flex; }
    @media screen and (max-width: 1200px) {
      .header-lg {
        gap: 0.8rem;
        margin-left: auto;
        padding: 0.6rem;
        padding-right: unset;
        border-radius: 0.4rem; } }
    .header-lg-it {
      color: #D0D9D9;
      transition: 0.4s;
      position: relative;
      font-weight: 700;
      padding: .2rem 0;
      gap: .8rem; }
      @media screen and (min-width: 1201px) {
        .header-lg-it:first-child {
          padding-right: 2.35rem;
          border-right: 1px solid #E1E8E8; }
        .header-lg-it:last-child {
          padding-left: 2.35rem; } }
      @media screen and (max-width: 1200px) {
        .header-lg-it {
          color: #D0D9D9; } }
      .header-lg-it.active {
        color: var(--color-pri); }
        @media screen and (max-width: 1200px) {
          .header-lg-it.active {
            color: var(--color-pri); } }
      @media (hover: hover) {
        .header-lg-it:hover {
          color: var(--color-pri); } }
  .header-search-button {
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    width: 3.2rem;
    height: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .header-search-button {
        width: 2.2rem;
        height: 2.2rem; } }
    .header-search-button img {
      flex-shrink: 0;
      width: 3.2rem;
      height: 3.2rem; }
      @media screen and (max-width: 1200px) {
        .header-search-button img {
          width: 2.2rem;
          height: 2.2rem; } }
  .header-srch {
    display: block;
    position: relative;
    height: 100%; }
    @media screen and (max-width: 1200px) {
      .header-srch {
        margin-left: auto; } }
    .header-srch-it {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 0.8rem;
      cursor: pointer; }
      .header-srch-it img {
        width: 3.2rem;
        height: 2.8rem; }
        @media screen and (max-width: 800px) {
          .header-srch-it img {
            width: 2.2rem;
            height: 2.2rem; } }
    .header-srch-ip {
      position: absolute;
      top: 0;
      right: 0;
      transition: 0.4s;
      top: 6.2rem;
      clip-path: inset(0 0 0 100%); }
      @media screen and (max-width: 1200px) {
        .header-srch-ip {
          top: 4.4rem; } }
      .header-srch-ip.open {
        clip-path: inset(0 0 0 0); }
    .header-srch-flex {
      display: flex;
      align-items: center;
      height: 4rem;
      min-width: 20rem;
      border: 0.1rem solid rgba(0, 0, 0, 0.1);
      padding: 0.5rem;
      padding-left: 1rem;
      border-radius: 3.2rem;
      background: white; }
      .header-srch-flex input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.4rem;
        font-family: "Roboto", sans-serif; }
        @media only screen and (max-width: 600px) {
          .header-srch-flex input {
            font-size: 1.2rem; } }
      .header-srch-flex button {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-pri);
        border-radius: inherit; }
        .header-srch-flex button img {
          width: 1.5rem;
          height: 1.6rem;
          filter: brightness(0) invert(1);
          flex-shrink: 0;
          object-fit: contain; }
  .header-searchbar {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(0.7rem);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: 0.5s; }
    .header-searchbar.open {
      transform: translateX(0); }
      .header-searchbar.open .header-searchbar-wr::after {
        width: 100%;
        transition-delay: 0.5s; }
      .header-searchbar.open .header-searchbar-wr input.placeholder {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 1.25s; }
      .header-searchbar.open .header-searchbar-wr input:-moz-placeholder {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 1.25s; }
      .header-searchbar.open .header-searchbar-wr input::-moz-placeholder {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 1.25s; }
      .header-searchbar.open .header-searchbar-wr input:-ms-input-placeholder {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 1.25s; }
      .header-searchbar.open .header-searchbar-wr input::-webkit-input-placeholder {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 1.25s; }
      .header-searchbar.open button {
        transform: translateX(0);
        transition-delay: 1.5s;
        opacity: 1; }
    .header-searchbar-wrapper {
      position: relative;
      height: 100%; }
    .header-searchbar-close {
      position: absolute;
      top: 2.4rem;
      left: 0;
      width: 35px;
      height: 35px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      cursor: pointer;
      z-index: 110;
      display: none;
      display: flex; }
      @media screen and (max-width: 900px) {
        .header-searchbar-close {
          top: 1rem;
          left: 0; } }
      .header-searchbar-close:hover .icon {
        transform: rotate(360deg); }
      .header-searchbar-close .icon {
        transition: 0.6s ease-in-out;
        font-weight: 500;
        font-size: 2.4rem;
        color: #ffffff;
        width: 2.4rem;
        height: 2.4rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center; }
    .header-searchbar-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 50px;
      width: 100%; }
    .header-searchbar-wr {
      position: relative; }
      .header-searchbar-wr::after {
        content: '';
        width: 0%;
        height: 0.1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        transition: 1s; }
    .header-searchbar input {
      width: 100%;
      font-size: 3rem;
      color: #ffffff;
      background-color: transparent;
      border: none;
      padding: 1rem 0;
      padding-left: 1rem;
      padding-right: 7rem; }
      @media only screen and (max-width: 1200px) {
        .header-searchbar input {
          font-size: 2rem; } }
      .header-searchbar input.placeholder {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.4s;
        transform: translateY(100%);
        opacity: 0; }
      .header-searchbar input:-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.4s;
        transform: translateY(100%);
        opacity: 0; }
      .header-searchbar input::-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.4s;
        transform: translateY(100%);
        opacity: 0; }
      .header-searchbar input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.4s;
        transform: translateY(100%);
        opacity: 0; }
      .header-searchbar input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.4s;
        transform: translateY(100%);
        opacity: 0; }
    .header-searchbar button {
      right: 0;
      bottom: 0;
      height: 100%;
      position: absolute;
      transition: 0.4s;
      transform: translateX(50%);
      opacity: 0;
      background-color: transparent;
      padding-right: 1rem;
      cursor: pointer; }
      .header-searchbar button img {
        filter: brightness(0) saturate(100%) invert(95%) sepia(1%) saturate(72%) hue-rotate(232deg) brightness(121%) contrast(100%);
        transition: 0.4s;
        width: 2rem;
        height: 2rem; }
      .header-searchbar button::after {
        background-color: transparent; }
      @media (hover: hover) {
        .header-searchbar button:hover > img {
          transform: scale(1.2); } }
  @media screen and (max-width: 1199px) {
    .header-phone {
      display: none; } }
  .header-phone-gr .tlink .txt {
    display: block;
    color: #3A4040;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal; }
    @media only screen and (max-width: 600px) {
      .header-phone-gr .tlink .txt {
        font-size: 1.2rem; } }
  .header-phone-gr .tlink .tphone {
    display: block;
    color: var(--color-pri);
    font-weight: 700;
    line-height: normal;
    transition: .3s; }
    @media (hover: hover) {
      .header-phone-gr .tlink .tphone:hover {
        color: var(--color-second); } }
  .header .banner-img-menu {
    position: relative;
    display: block;
    padding-top: calc((1 / 4) * 100%);
    position: relative;
    overflow: hidden;
    overflow: hidden;
    margin-bottom: 2rem; }
    .header .banner-img-menu .banner-content-menu-mega {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 0.4s; }
      .header .banner-img-menu .banner-content-menu-mega:nth-child(1) {
        opacity: 1; }
      .header .banner-img-menu .banner-content-menu-mega.open {
        opacity: 1; }
      .header .banner-img-menu .banner-content-menu-mega img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

@media screen and (min-width: 1201px) {
  .header-wrapper {
    background-color: transparent;
    box-shadow: unset; }
  .header-nav,
  .header-right {
    opacity: 0; }
  .sticky .header-wrapper {
    background-color: #ffffff;
    box-shadow: initial; }
  .sticky .header-nav,
  .sticky .header-right {
    opacity: 1; } }

.header-nav .menu-nav {
  height: 100%; }
  .header-nav .menu-nav > .menu-list {
    height: 100%;
    justify-content: center; }
    .header-nav .menu-nav > .menu-list > .menu-item {
      height: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center; }
      .header-nav .menu-nav > .menu-list > .menu-item:not(:first-child) {
        margin-left: 3.2rem; }
      .header-nav .menu-nav > .menu-list > .menu-item::before {
        content: "";
        position: absolute;
        bottom: 3.2rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        height: 0.2rem;
        background: linear-gradient(90deg, white 0%, #00783e 50%, white 100%);
        width: 0;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out; }
      @media screen and (min-width: 1201px) {
        .header-nav .menu-nav > .menu-list > .menu-item:hover::before {
          width: 100%; }
        .header-nav .menu-nav > .menu-list > .menu-item:hover i {
          rotate: 180deg; }
        .header-nav .menu-nav > .menu-list > .menu-item:hover .mega-sidebar-item i,
        .header-nav .menu-nav > .menu-list > .menu-item:hover .menu-list i {
          rotate: 0deg; } }
      .header-nav .menu-nav > .menu-list > .menu-item:hover > .menu-link {
        color: var(--color-pri); }
      .header-nav .menu-nav > .menu-list > .menu-item:hover .mega {
        transform: translate(-50%, 0);
        visibility: visible;
        opacity: 1; }
      .header-nav .menu-nav > .menu-list > .menu-item:hover .mega-overlay {
        opacity: 1;
        visibility: visible;
        pointer-events: visible;
        transform: translate(0, 0); }
      .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
        height: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        color: var(--color-text);
        padding: 2rem 0; }
        @media only screen and (max-width: 425px) {
          .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
            font-size: 1.4rem; } }
      .header-nav .menu-nav > .menu-list > .menu-item.current_page_item > .menu-link {
        color: var(--color-pri); }
      .header-nav .menu-nav > .menu-list > .menu-item.current_page_item::before {
        width: 100%; }
      .header-nav .menu-nav > .menu-list > .menu-item > .menu-list {
        top: calc(100% + 0rem); }

.header-nav .menu-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }

.header-nav .menu-item {
  position: relative;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  @media screen and (min-width: 1201px) {
    .header-nav .menu-item:hover > .menu-list {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      transform: translate(0, 0); } }
  .header-nav .menu-item:hover > .menu-list > .menu-item.current_page_item > a {
    color: var(--color-pri); }
    .header-nav .menu-item:hover > .menu-list > .menu-item.current_page_item > a > i {
      color: white; }
  .header-nav .menu-item .menu-link {
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }
    .header-nav .menu-item .menu-link i {
      transition: 0.2s linear;
      -webkit-transition: 0.2s linear;
      -moz-transition: 0.2s linear;
      -ms-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
  .header-nav .menu-item > .menu-link {
    color: var(--color-text);
    font-weight: 400;
    display: block;
    font-size: 1.6rem; }
    @media only screen and (max-width: 425px) {
      .header-nav .menu-item > .menu-link {
        font-size: 1.4rem; } }
    .header-nav .menu-item > .menu-link > i {
      display: none;
      font-weight: 600;
      margin-left: 0.4rem;
      font-size: 1rem;
      margin-top: 0rem; }
  .header-nav .menu-item.dropdown > a > i {
    display: inline-block; }
  .header-nav .menu-item > .menu-list {
    padding: 1.4rem;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 20rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    border-radius: 1.6rem;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    @media screen and (min-width: 1201px) {
      .header-nav .menu-item > .menu-list {
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 5px);
        pointer-events: none; } }
    .header-nav .menu-item > .menu-list > .menu-item {
      margin-left: 0;
      width: 100%; }
      @media screen and (min-width: 1201px) {
        .header-nav .menu-item > .menu-list > .menu-item:hover > .menu-link {
          color: var(--color-pri); } }
      .header-nav .menu-item > .menu-list > .menu-item > .menu-link {
        padding: 1rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .header-nav .menu-item > .menu-list > .menu-item > .menu-link:not(:last-child) {
          margin-bottom: 1.6rem; }
          @media screen and (max-width: 800px) {
            .header-nav .menu-item > .menu-list > .menu-item > .menu-link:not(:last-child) {
              margin-bottom: calc(1.6rem / 2); } }
        .header-nav .menu-item > .menu-list > .menu-item > .menu-link > i {
          transform: rotate(-90deg) translate(5px, 0);
          margin-top: 0.8rem; }
      .header-nav .menu-item > .menu-list > .menu-item > .menu-list {
        left: 100%;
        top: -2.4rem; }

.mobile-nav .menu-nav > .menu-list > .menu-item.current_page_item > a {
  color: var(--color-pri); }

.mobile-nav .menu-nav > .menu-list > .menu-item > a {
  padding: 1.2rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #303030;
  font-size: 1.4rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .mobile-nav .menu-nav > .menu-list > .menu-item > a::before {
    display: none; }

.mobile-nav .menu-item.dropdown > a > i {
  display: flex; }

.mobile-nav .menu-item.active > a {
  color: var(--color-pri); }
  .mobile-nav .menu-item.active > a > i {
    transform: rotate(180deg); }

.mobile-nav .menu-item a {
  position: relative; }
  .mobile-nav .menu-item a i {
    width: 2rem;
    height: 2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: none;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }

.mobile-nav .menu-item > a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  font-size: 1.4rem;
  color: #303030;
  font-weight: 500; }

.mobile-nav .menu-item > .menu-list {
  display: none;
  margin-left: 1.6rem;
  position: relative; }
  .mobile-nav .menu-item > .menu-list::before {
    content: '';
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1); }
  .mobile-nav .menu-item > .menu-list > .menu-item.current_page_item > a {
    color: var(--color-pri); }

.mega {
  position: fixed;
  left: 50%;
  right: 0;
  width: 100%;
  min-height: 70vh;
  height: 70rem;
  max-width: 124.8rem;
  padding-top: 4.5rem;
  z-index: 0;
  top: 7.5rem;
  transform-origin: top;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -100%);
  pointer-events: none;
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -ms-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  .mega.close {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -100%);
    pointer-events: none; }
  .mega-overlay {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    top: calc($h-top + $h-bot);
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -100%);
    pointer-events: none;
    transition: 0.6s ease;
    -webkit-transition: 0.6s ease;
    -moz-transition: 0.6s ease;
    -ms-transition: 0.6s ease;
    -o-transition: 0.6s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    z-index: -2;
    display: none; }
  .mega-side {
    min-height: inherit;
    background-color: #ffffff;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    overflow: hidden;
    border-radius: 3.2rem; }
    .mega-side-left {
      width: calc(100%*(2.5/12));
      min-height: inherit;
      background-color: #ffffff;
      padding: 0; }
    .mega-side-right {
      width: calc(100%*(9.5/12));
      max-height: 70vh;
      padding-left: 4.8rem;
      padding-right: 2.4rem;
      overflow: auto;
      overflow-x: hidden; }
      @media screen and (max-width: 1440px) {
        .mega-side-right {
          padding-left: 2.4rem;
          padding-right: 1.2rem; } }
  .mega-sidebar {
    height: 100%;
    padding: 4rem 2.4rem 2.4rem 4.8rem;
    min-height: inherit; }
    @media screen and (max-width: 1440px) {
      .mega-sidebar {
        padding: 2rem 1.2rem 1.2rem 2.4rem; } }
    .mega-sidebar-item {
      border-bottom: 1px solid #ffffff;
      border-radius: 4.6rem;
      padding: 1rem;
      position: relative;
      z-index: 2;
      background-color: #ffffff;
      transition: 0.4s; }
      .mega-sidebar-item:not(:last-child) {
        margin-bottom: 1.6rem; }
        @media screen and (max-width: 800px) {
          .mega-sidebar-item:not(:last-child) {
            margin-bottom: calc(1.6rem / 2); } }
      .mega-sidebar-item.active {
        border-bottom: 1px solid var(--m002, #00783E);
        background: rgba(0, 120, 62, 0.1); }
        .mega-sidebar-item.active a,
        .mega-sidebar-item.active i {
          color: var(--color-pri);
          font-weight: 400;
          transition: .1s; }
      .mega-sidebar-item.current_page_item::after {
        transform: translateX(0);
        opacity: 1; }
      .mega-sidebar-item.current_page_item a {
        color: var(--color-pri);
        font-weight: 500; }
      .mega-sidebar-item:hover a,
      .mega-sidebar-item:hover i {
        color: var(--color-pri);
        font-weight: 400; }
      .mega-sidebar-item a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .8rem;
        color: var(--color-text);
        transition: 0.4s; }
        .mega-sidebar-item a i {
          font-weight: 400; }
  .mega-menu {
    padding: 4rem 0;
    display: none;
    animation: megaAni 0.7s ease-in-out; }
    @media screen and (max-width: 1440px) {
      .mega-menu {
        padding: 2rem 0; } }
    .mega-menu.open {
      display: block; }
    .mega-menu-row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex; }
    .mega-menu-col {
      width: calc(100% / 4); }
      .mega-menu-col.current_page_parent .mega-menu-title {
        color: var(--color-pri); }
    .mega-menu-title {
      margin-bottom: 1.6rem;
      width: calc(100% + 2.4rem);
      color: var(--color-text);
      font-size: 1.6rem;
      font-weight: 600;
      position: relative; }
      @media only screen and (max-width: 425px) {
        .mega-menu-title {
          font-size: 1.4rem; } }
    .mega-menu-item {
      font-size: 1.6rem; }
      .mega-menu-item:not(:last-child) {
        margin-bottom: 1rem; }
      @media only screen and (max-width: 425px) {
        .mega-menu-item {
          font-size: 1.4rem; } }
      .mega-menu-item:hover a {
        color: var(--color-pri); }
      .mega-menu-item.current_page_item {
        pointer-events: none; }
        .mega-menu-item.current_page_item a {
          color: var(--color-pri); }
      .mega-menu-item a {
        color: var(--color-text);
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        text-wrap: pretty; }

@keyframes megaAni {
  0% {
    transform: translateX(20rem);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

.mega {
  /* pointer-events: none; */
  transform: translateX(-50%) translateY(0);
  opacity: 0; }

.header-nav .menu-nav > .menu-list > .menu-item:hover .mega {
  pointer-events: visible;
  transform: translate(-50%, 0);
  transition: 0.4s; }

.ft {
  width: 100%;
  position: relative;
  overflow: hidden; }
  .ft .tt {
    color: #FFF;
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-bottom: 1.6rem;
    text-wrap: nowrap; }
    @media only screen and (max-width: 1200px) {
      .ft .tt {
        font-size: 1.8rem; } }
    @media screen and (max-width: 800px) {
      .ft .tt {
        margin-bottom: calc(1.6rem / 2); } }
  .ft-wr {
    position: relative;
    padding-top: 17.3rem;
    padding-bottom: 4.8rem;
    position: relative; }
    .ft-wr .bg {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      z-index: 0;
      pointer-events: none; }
      .ft-wr .bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .ft-wr .bg {
      z-index: -1; }
      .ft-wr .bg img {
        object-position: top; }
    @media screen and (max-width: 500px) {
      .ft-wr {
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: #00783e; } }
  @media screen and (max-width: 500px) and (max-width: 425px) {
    .ft-wr {
      padding-top: 2rem;
      padding-bottom: 3rem; } }
    @media screen and (max-width: 500px) {
        .ft-wr .bg {
          display: none; } }
  .ft-logo {
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 800px) {
      .ft-logo {
        margin-bottom: calc(3.2rem / 2); } }
    .ft-logo .custom-logo-link {
      display: block;
      width: 52.1rem;
      max-width: 100%; }
  @media screen and (max-width: 900px) {
    .ft-flex.row .col {
      width: calc(100% / 2); } }
  @media screen and (max-width: 900px) and (max-width: 500px) {
    .ft-flex.row .col {
      width: 100%; } }
  .ft .menu-item {
    font-family: "Roboto", sans-serif; }
    .ft .menu-item:not(:last-child) {
      margin-bottom: 1.2rem; }
      @media screen and (max-width: 800px) {
        .ft .menu-item:not(:last-child) {
          margin-bottom: calc(1.2rem / 2); } }
  .ft .menu-link {
    color: var(--color-white);
    font-size: 1.6rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: .3s; }
    @media only screen and (max-width: 425px) {
      .ft .menu-link {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .ft .menu-link {
        font-size: 1.4rem; } }
  @media only screen and (max-width: 600px) and (max-width: 600px) {
    .ft .menu-link {
      font-size: 1.2rem; } }
    @media (hover: hover) {
      .ft .menu-link:hover {
        color: rgba(255, 255, 255, 0.5); } }
  @media screen and (max-width: 900px) {
    .ft-item:nth-child(1) {
      order: 1; }
    .ft-item:nth-child(2) {
      order: 4; }
    .ft-item:nth-child(3) {
      order: 2; }
    .ft-item:nth-child(4) {
      order: 3; } }
  @media screen and (max-width: 500px) {
    .ft-item {
      row-gap: unset; }
      .ft-item:nth-child(1) {
        order: 1; }
      .ft-item:nth-child(2) {
        order: 4; }
      .ft-item:nth-child(3) {
        order: 2; }
      .ft-item:nth-child(4) {
        order: 3; } }
  @media screen and (max-width: 500px) {
    .ft-item {
      margin-bottom: 1.6rem; }
      .ft-item:nth-child(1) {
        order: 1; }
      .ft-item:nth-child(2) {
        order: 2; }
      .ft-item:nth-child(3) {
        order: 3; }
      .ft-item:nth-child(4) {
        order: 4; } }
  .ft-item:nth-child(2) .wrapper, .ft-item:nth-child(3) .wrapper {
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media screen and (min-width: 2000px) {
      .ft-item:nth-child(2) .wrapper .tt, .ft-item:nth-child(3) .wrapper .tt {
        padding-top: 3rem;
        height: fit-content; } }
    @media screen and (min-width: 501px) {
      .ft-item:nth-child(2) .wrapper .tt, .ft-item:nth-child(3) .wrapper .tt {
        padding-top: 3rem;
        height: fit-content; } }
  @media screen and (min-width: 501px) and (max-width: 625px) {
    .ft-item:nth-child(2) .wrapper .tt, .ft-item:nth-child(3) .wrapper .tt {
      padding-top: 1rem; } }
    @media screen and (min-width: 901px) {
      .ft-item:nth-child(2) .wrapper .tt, .ft-item:nth-child(3) .wrapper .tt {
        padding-top: 5rem;
        height: fit-content;
        margin-bottom: unset; } }
  @media screen and (min-width: 901px) and (max-width: 1071px) {
    .ft-item:nth-child(2) .wrapper .tt, .ft-item:nth-child(3) .wrapper .tt {
      padding-top: 6rem; } }
    @media screen and (min-width: 901px) {
      .ft-item:nth-child(2) .wrapper .menu-list, .ft-item:nth-child(3) .wrapper .menu-list {
        height: fit-content; }
        .ft-item:nth-child(2) .wrapper .menu-list .menu-item, .ft-item:nth-child(3) .wrapper .menu-list .menu-item {
          font-size: 1.4rem; } }
    @media only screen and (min-width: 901px) and (max-width: 600px) {
      .ft-item:nth-child(2) .wrapper .menu-list .menu-item, .ft-item:nth-child(3) .wrapper .menu-list .menu-item {
        font-size: 1.2rem; } }
    @media screen and (min-width: 901px) {
          .ft-item:nth-child(2) .wrapper .menu-list .menu-item .menu-link, .ft-item:nth-child(3) .wrapper .menu-list .menu-item .menu-link {
            font-size: 1.4rem; } }
      @media only screen and (min-width: 901px) and (max-width: 600px) {
        .ft-item:nth-child(2) .wrapper .menu-list .menu-item .menu-link, .ft-item:nth-child(3) .wrapper .menu-list .menu-item .menu-link {
          font-size: 1.2rem; } }
    @media screen and (min-width: 901px) {
          .ft-item:nth-child(2) .wrapper .menu-list .menu-item:not(:last-child), .ft-item:nth-child(3) .wrapper .menu-list .menu-item:not(:last-child) {
            margin-bottom: 1.2rem; } }
      @media screen and (min-width: 901px) and (max-width: 800px) {
        .ft-item:nth-child(2) .wrapper .menu-list .menu-item:not(:last-child), .ft-item:nth-child(3) .wrapper .menu-list .menu-item:not(:last-child) {
          margin-bottom: calc(1.2rem / 2); } }
  @media screen and (min-width: 501px) {
    .ft-item:nth-child(4) .wrapper {
      height: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  @media screen and (min-width: 501px) and (min-width: 1072px) {
    .ft-item:nth-child(4) .wrapper .maps {
      margin-top: 3rem; } }
  @media screen and (min-width: 501px) and (min-width: 901px) {
    .ft-item:nth-child(4) .wrapper .maps {
      margin-top: 2rem; } }
  .ft-sign {
    background: #ffffff;
    padding: 1.6rem 0; }
    .ft-sign-wr {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      align-items: center;
      justify-content: space-between; }
      @media screen and (max-width: 493px) {
        .ft-sign-wr {
          justify-content: center; } }
      @media screen and (max-width: 493px) {
        .ft-sign-wr .social-list {
          order: 1; } }
      @media screen and (max-width: 373px) {
        .ft-sign-wr .social-list {
          display: none; } }
  .ft-copyright {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: 1.1rem;
    width: 32.6rem;
    gap: .8rem; }
    @media screen and (max-width: 493px) {
      .ft-copyright {
        order: 2; } }
    @media screen and (max-width: 373px) {
      .ft-copyright {
        flex-direction: column;
        justify-content: center;
        width: 60%; } }
    .ft-copyright .text {
      display: block;
      font-family: "Roboto", sans-serif;
      color: var(--color-text);
      font-size: 1.4rem;
      font-weight: 400;
      text-wrap: nowrap;
      width: fit-content; }
      @media only screen and (max-width: 600px) {
        .ft-copyright .text {
          font-size: 1.2rem; } }
    .ft-copyright img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .ft .maps {
    height: fit-content; }
    .ft .maps-wr {
      width: 100%;
      height: 100%; }
      .ft .maps-wr iframe {
        width: 100%;
        height: 100%;
        aspect-ratio: 379/191; }
  .ft-content .loca {
    display: block;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .4rem;
    line-height: normal;
    color: #F5F7F7;
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
    .ft-content .loca:not(:last-child) {
      margin-bottom: 1.6rem; }
      @media screen and (max-width: 800px) {
        .ft-content .loca:not(:last-child) {
          margin-bottom: calc(1.6rem / 2); } }
    @media only screen and (max-width: 600px) {
      .ft-content .loca {
        font-size: 1.2rem; } }
    .ft-content .loca .bold {
      display: block;
      font-weight: 700;
      width: fit-content; }
    .ft-content .loca .link {
      flex: 1;
      color: #F5F7F7;
      font-size: 1.4rem;
      font-weight: 400;
      transition: .3s; }
      @media only screen and (max-width: 600px) {
        .ft-content .loca .link {
          font-size: 1.2rem; } }
      @media (hover: hover) {
        .ft-content .loca .link:hover {
          color: rgba(255, 255, 255, 0.5); } }

.burger {
  cursor: pointer;
  position: relative; }
  @media screen and (min-width: 1201px) {
    .burger {
      display: none; } }

.hamburger .line {
  width: 22px;
  height: 2px;
  border-radius: 4px;
  display: block;
  margin: 5px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-left: auto; }
  .hamburger .line:nth-child(1) {
    background-color: var(--color-pri); }
  .hamburger .line:nth-child(2) {
    background-color: var(--color-pri); }
  .hamburger .line:nth-child(3) {
    background-color: var(--color-pri); }

#hamburger.active .line:nth-child(1) {
  width: 18px;
  margin-left: auto; }

#hamburger.active .line:nth-child(2) {
  margin-left: auto; }

#hamburger.active .line:nth-child(3) {
  width: 14px;
  margin-left: auto; }

.menu-button {
  padding: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 1.2rem;
  padding-right: 1.2rem; }
  @media screen and (max-width: 1200px) {
    .menu-button {
      display: none; } }
  .menu-button::after {
    content: '';
    width: 100%;
    height: 100%;
    background: var(--color-second);
    border-radius: 4px 0px 2px 4px;
    position: absolute;
    z-index: -1; }
  .menu-button .head {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 40px;
    overflow: hidden; }
    .menu-button .head::before {
      content: '';
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      width: 200%;
      background: var(--color-second);
      z-index: 10;
      border-radius: 0 5px 2px 0;
      transform: skewX(-40deg); }
  .menu-button .tail {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 40px;
    overflow: hidden; }
    .menu-button .tail::before {
      content: '';
      position: absolute;
      top: 0;
      left: 18px;
      bottom: 0;
      width: 200%;
      background: blue;
      z-index: 10;
      border-radius: 2px 0px 0px 5px;
      transform: skewX(-22deg); }
  .menu-button a {
    color: white;
    text-decoration: none; }
  .menu-button p {
    color: #ffffff;
    margin-left: 1.2rem; }

.menu-type {
  padding: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 5rem;
  cursor: pointer; }
  .menu-type::after {
    content: '';
    width: 100%;
    height: 100%;
    background: var(--color-pri);
    position: absolute;
    z-index: -1;
    border-radius: 1px 0 1px 0; }
    @media screen and (max-width: 1200px) {
      .menu-type::after {
        display: none; } }
  .menu-type .head {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 40px;
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .menu-type .head {
        display: none; } }
    .menu-type .head::before {
      content: '';
      position: absolute;
      top: 0;
      right: 19px;
      bottom: 0;
      width: 200%;
      background: var(--color-pri);
      z-index: 10;
      border-radius: 0 5px 2px 0;
      transform: skewX(-40deg); }
  .menu-type .tail {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 40px;
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .menu-type .tail {
        display: none; } }
    .menu-type .tail::before {
      content: '';
      position: absolute;
      top: 0;
      left: 19px;
      bottom: 0;
      width: 200%;
      background: var(--color-pri);
      z-index: 10;
      border-radius: 2px 0px 0px 5px;
      transform: skewX(-40deg); }
  .menu-type a {
    color: white;
    text-decoration: none; }
  .menu-type img {
    width: 2.4rem;
    height: auto;
    max-width: none !important; }
  .menu-type p {
    color: #ffffff;
    margin-left: 1.2rem;
    white-space: nowrap; }
  @media screen and (max-width: 1200px) {
    .menu-type {
      padding: 1.1rem 1.6rem;
      border-radius: 0.4rem;
      background-color: var(--color-pri);
      margin-left: 0;
      height: 4rem; } }
  @media screen and (max-width: 500px) {
    .menu-type {
      width: 100%; }
      .menu-type img {
        display: none; }
      .menu-type p {
        margin-left: 0; } }

.mobile {
  position: fixed;
  z-index: -1 !important;
  top: 6rem;
  left: 0;
  bottom: 0;
  min-width: 50rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transform: translateX(-110%);
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 500px) {
    .mobile {
      width: 100%;
      min-width: initial; } }
  .mobile.sticky {
    top: 6rem !important; }
  .mobile.open {
    transform: translateX(0); }
  .mobile .search-mona {
    padding: 0 1.5rem;
    margin: 1.6rem 0; }
  @media screen and (min-width: 1201px) {
    .mobile {
      display: none; } }
  .mobile-con {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
  .mobile-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: var(--color-pri);
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase; }
  .mobile-overlay {
    position: fixed;
    z-index: -1 !important;
    top: 6rem;
    left: 0;
    bottom: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.6s ease;
    -webkit-transition: 0.6s ease;
    -moz-transition: 0.6s ease;
    -ms-transition: 0.6s ease;
    -o-transition: 0.6s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-110%);
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .mobile-overlay.sticky {
      top: 6rem !important; }
    .mobile-overlay.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0); }
    @media screen and (min-width: 1201px) {
      .mobile-overlay {
        display: none; } }
  .mobile-wr {
    padding-bottom: 6rem;
    position: relative; }
  .mobile-list {
    margin-bottom: 2rem; }
  .mobile-content {
    padding: 2.4rem 1.6rem; }
    .mobile-content .social-list {
      margin-bottom: 2.4rem;
      gap: 1.6rem; }
    .mobile-content .social-link img {
      filter: var(--filter); }
  .mobile-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem; }
  .mobile-mxh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .mobile-mxh-link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex: 1;
      height: 100%;
      background-color: white;
      cursor: pointer; }
      .mobile-mxh-link:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.1); }
      .mobile-mxh-link .icon {
        color: #ffffff; }
  .mobile-text {
    font-size: 1.4rem;
    display: block;
    color: var(--color-pri);
    word-break: break-word;
    font-weight: 500; }
    @media only screen and (max-width: 600px) {
      .mobile-text {
        font-size: 1.2rem; } }
    .mobile-text:not(:last-child) {
      margin-bottom: 0.8rem; }
  .mobile-account {
    position: absolute;
    top: 1rem;
    left: 1rem; }
  .mobile-re {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .mobile-re-link {
      font-size: 1.6rem;
      color: var(--color-pri);
      text-decoration: underline; }
      @media only screen and (max-width: 425px) {
        .mobile-re-link {
          font-size: 1.4rem; } }
      .mobile-re-link:not(:first-child) {
        margin-left: 16px; }
  .mobile-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid var(--color-pri);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    background-color: white; }
    .mobile-close:hover .icon {
      transform: rotate(360deg); }
    .mobile-close .icon {
      transition: 0.6s ease-in-out;
      font-weight: 500;
      font-size: 1.8rem;
      color: var(--color-pri); }
  .mobile-fl .fw-6 {
    margin-bottom: 0.8rem;
    margin-bottom: 0.8rem;
    display: inline;
    width: fit-content;
    font-size: 1.4rem; }
  .mobile-fl .mobile-text {
    display: inline;
    font-size: 1.4rem; }
  .mobile-fl .dot {
    display: none; }

.select2-container {
  height: 4.4rem;
  background-color: transparent;
  border-radius: 6px; }

.select2.select2-container {
  width: 100% !important; }

.selection {
  width: 100%; }

.select2-container--default .select2-selection--single {
  border: none;
  border-radius: 0.8rem;
  color: #ffffff;
  height: 4.4rem;
  background-color: transparent;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }

.select2.select2-container .select2-selection--multiple {
  height: auto;
  min-height: 34px; }

.select2-container .select2-selection--single .select2-selection__rendered {
  background-color: transparent;
  border: 1px solid transparent;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400;
  display: inline !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%; }

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding: 0 4px;
  padding-left: 1.4rem; }

.select2-container .select2-dropdown {
  background: transparent;
  border: none; }

.select2-container .select2-dropdown .select2-search {
  padding: 0; }

.select2-container .select2-dropdown .select2-search input {
  outline: none !important;
  border: none;
  border-bottom: none !important;
  padding: 4px 6px !important;
  background-color: #000000;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul {
  background: #e6eeed;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  background-color: var(--color-pri);
  font-size: 1.4rem; }

.select2-container--default .select2-results__option--selected {
  background-color: #e6eeed;
  color: #000000;
  font-size: 1.4rem; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  border-color: #000 transparent transparent transparent; }

.select2-search.select2-search--dropdown {
  display: none !important; }

.select2-results__option {
  font-size: 1.4rem; }

.select2-results {
  display: block;
  border-radius: 6px !important;
  overflow: hidden;
  border: 0.1rem solid white; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-radius: 0.8rem !important;
  border-color: var(--color-pri); }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 1.4rem;
  font-weight: 700;
  color: var(--color-text);
  padding-right: 3rem; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #C9C9C9; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-left: -1.2rem; }

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  font-size: 1.4rem; }

.effectShine {
  position: relative;
  overflow: hidden; }

.effectShine::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg); }

.effectShine:hover::before {
  -webkit-animation: shine 1s;
  animation: shine 1s; }

.sl-cus {
  position: relative; }
  .sl-cus.min {
    min-width: 12rem; }
    .sl-cus.min:not(:first-child) {
      margin-left: 0.5rem; }

.sl-head {
  background-color: #f8f8f8;
  height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 1.5rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  cursor: pointer;
  border-radius: 0.6rem; }
  .sl-head::before {
    content: "";
    position: absolute;
    right: 10px;
    top: 13px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid var(--color-second); }
  .sl-head::after {
    content: "";
    position: absolute;
    right: 1rem;
    bottom: 1.3rem;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid var(--color-second); }

.sl-main {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.sl-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  background-color: white;
  z-index: 10;
  display: none;
  overflow: hidden;
  border-radius: 0 0 6px 6px;
  max-height: 20rem;
  overflow-y: auto; }

.sl-item {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #000000;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out; }
  .sl-item:hover {
    background-color: var(--color-second);
    color: white; }

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  visibility: hidden;
  pointer-events: none; }
  .popup.open {
    visibility: visible;
    pointer-events: visible; }
    .popup.open .popup-overlay {
      opacity: 1;
      visibility: visible;
      height: 100%; }
    .popup.open .popup-main {
      opacity: 1;
      visibility: visible;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition-delay: 0.1s;
      -webkit-transition-delay: 0.1s;
      -moz-transition-delay: 0.1s;
      -ms-transition-delay: 0.1s;
      -o-transition-delay: 0.1s;
      clip-path: polygon();
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -ms-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -o-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  .popup-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 70rem;
    max-width: 95%;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    clip-path: polygon();
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -moz-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -ms-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -o-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
    .popup-main-wrapper {
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      padding: 2rem 0;
      background: white;
      border-radius: 1rem; }
  .popup-over {
    overflow-y: auto;
    min-height: 40vh;
    max-height: 80vh;
    max-width: calc(100% - 3rem);
    background-color: #ffffff;
    border-radius: 0.8rem;
    margin: auto;
    position: relative;
    z-index: 2; }
  .popup-wrapper {
    width: 100%;
    min-height: 40vh;
    position: relative;
    padding: 7rem 2rem 2rem 2rem; }
  .popup-title {
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .popup-title {
        font-size: 2.2rem; } }
    @media only screen and (max-width: 800px) {
      .popup-title {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 425px) {
      .popup-title {
        font-size: 1.6rem; } }
  .popup-close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    cursor: pointer;
    transition: 0.6s ease-in-out;
    width: 3rem;
    height: 3rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    border-radius: 100rem;
    background-color: var(--color-pri);
    z-index: 3; }
    .popup-close:hover .icon {
      transform: rotate(360deg); }
    .popup-close .icon {
      color: #ffffff;
      font-weight: 300;
      font-size: 2.4rem;
      transition: 0.6s ease-in-out;
      -webkit-transition: 0.6s ease-in-out;
      -moz-transition: 0.6s ease-in-out;
      -ms-transition: 0.6s ease-in-out;
      -o-transition: 0.6s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
  .popup .popSearch {
    width: 50rem; }
    .popup .popSearch .popup-over {
      min-height: unset; }
    .popup .popSearch .popup-wrapper {
      min-height: unset;
      padding: 0rem;
      padding-top: 3rem; }
    .popup .popSearch .content-form {
      display: flex;
      border: 0.1rem solid var(--color-pri);
      padding: 0.5rem;
      border-radius: 1rem;
      margin: auto;
      max-width: 50rem; }
      .popup .popSearch .content-form input {
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        padding: 0 2rem;
        font-size: 1.6rem; }
      .popup .popSearch .content-form button {
        flex-shrink: 0;
        height: 4rem; }
  .popup .popWish .popup-over {
    min-height: unset; }
  .popup .popWish .popup-wrapper {
    min-height: unset;
    padding: 2rem;
    padding-top: 3rem; }
    @media screen and (max-width: 370px) {
      .popup .popWish .popup-wrapper {
        padding: 3rem 0rem 0rem 0rem; } }
  .popup .popWish .wish-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; }
    .popup .popWish .wish-form input {
      width: 100%;
      padding: 1rem;
      border: 0.1rem solid var(--color-pri);
      border-radius: 1rem;
      font-size: 1.6rem;
      color: var(--color-pri); }
  .popup .popWish .wish-bot {
    display: flex;
    gap: 1rem; }
  .popup .popWish .wish-tt {
    font-size: 2.4rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif; }
  .popup .popWish .wish-note {
    font-size: 1rem;
    font-weight: 300; }

.loading {
  position: fixed;
  z-index: 6000;
  inset: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  pointer-events: none; }
  .loading.final {
    animation: anLoading 1s linear forwards 1s; }
    .loading.final .loading_img img {
      animation: phongToLogo 1s linear forwards; }
  .loading.loaded .loading_wrap {
    animation: loadingLogo1 1s linear forwards; }
  .loading.loaded .loading_img {
    animation: loadingLogo2 6s linear forwards infinite alternate 1.2s; }

@keyframes loadingLogo1 {
  0% {
    transform: translate(-50%, 300%);
    opacity: 0; }
  50% {
    transform: translate(-50%, 150%);
    opacity: 0.5; }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1; } }

@keyframes loadingLogo2 {
  0% {
    transform: translateY(0%); }
  10% {
    transform: translateY(10%); }
  20% {
    transform: translateY(0%); }
  30% {
    transform: translateY(-10%); }
  40% {
    transform: translateY(0%); }
  50% {
    transform: translateY(10%); }
  60% {
    transform: translateY(0%); }
  70% {
    transform: translateY(-10%); }
  80% {
    transform: translateY(0%); }
  90% {
    transform: translateY(10%); }
  100% {
    transform: translateY(0%); } }

@keyframes phongToLogo {
  0% {
    transform: scale(1);
    opacity: 1; }
  50% {
    transform: scale(10);
    opacity: 0.5; }
  100% {
    transform: scale(15);
    opacity: 0; } }

@keyframes anLoading {
  0% {
    opacity: 1;
    visibility: visible; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0;
    visibility: hidden; } }
  .loading_wrap {
    max-width: 26.5rem;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -300%);
    opacity: 0; }
  .loading_img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.dateTime {
  position: relative;
  height: 4.4rem;
  z-index: 2;
  border-radius: 100rem; }
  .dateTime img {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    border-radius: unset; }

.dateTimeItem {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; }

.dateTimeText {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  background-color: transparent;
  z-index: 2;
  font-size: 1.6rem;
  color: #64748B; }
  .dateTimeText.disable {
    opacity: 0;
    visibility: hidden; }

.dateTimeInput {
  padding: 0.8rem 1.4rem;
  background-color: transparent;
  width: 100%;
  font-family: "Roboto", sans-serif;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  font-size: 1.3rem;
  font-family: "Roboto", sans-serif;
  border-radius: inherit;
  background-color: #ffffff; }

@keyframes k-up-down {
  0% {
    transform: translateY(0); }
  32% {
    transform: translateY(-1rem); }
  66% {
    transform: translateY(1rem); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg); }
  100% {
    -webkit-transform: translate3d(-350px, 700px, 0) rotate(90deg);
    opacity: 0; } }

@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg); }
  100% {
    -webkit-transform: translate3d(-230px, 640px, 0) rotate(-70deg);
    opacity: 0; } }

@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg); }
  100% {
    -webkit-transform: translate3d(-400px, 680px, 0) rotate(0deg);
    opacity: 0; } }

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05); }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02); } }

@keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05); }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02); } }

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.animate__shakeX {
  -webkit-animation-name: shakeX;
  animation-name: shakeX; }

@-webkit-keyframes shakeY {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); } }

@keyframes shakeY {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); } }

.animate__shakeY {
  -webkit-animation-name: shakeY;
  animation-name: shakeY; }

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.animate__headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.animate__swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.animate__jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.animate__heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: calc(1s * 1.3);
  animation-duration: calc(1s * 1.3);
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

/* Back entrances */
@-webkit-keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInDown {
  -webkit-animation-name: backInDown;
  animation-name: backInDown; }

@-webkit-keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft; }

@-webkit-keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInRight {
  -webkit-animation-name: backInRight;
  animation-name: backInRight; }

@-webkit-keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInUp {
  -webkit-animation-name: backInUp;
  animation-name: backInUp; }

/* Back exits */
@-webkit-keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutDown {
  -webkit-animation-name: backOutDown;
  animation-name: backOutDown; }

@-webkit-keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutLeft {
  -webkit-animation-name: backOutLeft;
  animation-name: backOutLeft; }

@-webkit-keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutRight {
  -webkit-animation-name: backOutRight;
  animation-name: backOutRight; }

@-webkit-keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(-700px) scale(0.7);
    transform: translateY(-700px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(-700px) scale(0.7);
    transform: translateY(-700px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutUp {
  -webkit-animation-name: backOutUp;
  animation-name: backOutUp; }

/* Bouncing entrances  */
@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__bounceIn {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

/* Bouncing exits  */
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.animate__bounceOut {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3); } }

.animate__bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
    transform: translate3d(20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
    transform: translate3d(20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2); } }

.animate__bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
    transform: translate3d(-20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
    transform: translate3d(-20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2); } }

.animate__bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
    transform: translate3d(0, -10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
    transform: translate3d(0, 20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
    transform: translate3d(0, -10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
    transform: translate3d(0, 20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3); } }

.animate__bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

/* Fading entrances  */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeInTopLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInTopLeft {
  -webkit-animation-name: fadeInTopLeft;
  animation-name: fadeInTopLeft; }

@-webkit-keyframes fadeInTopRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInTopRight {
  -webkit-animation-name: fadeInTopRight;
  animation-name: fadeInTopRight; }

@-webkit-keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInBottomLeft {
  -webkit-animation-name: fadeInBottomLeft;
  animation-name: fadeInBottomLeft; }

@-webkit-keyframes fadeInBottomRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInBottomRight {
  -webkit-animation-name: fadeInBottomRight;
  animation-name: fadeInBottomRight; }

/* Fading exits */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.animate__fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.animate__fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.animate__fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.animate__fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.animate__fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); } }

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); } }

.animate__fadeOutTopLeft {
  -webkit-animation-name: fadeOutTopLeft;
  animation-name: fadeOutTopLeft; }

@-webkit-keyframes fadeOutTopRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); } }

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); } }

.animate__fadeOutTopRight {
  -webkit-animation-name: fadeOutTopRight;
  animation-name: fadeOutTopRight; }

@-webkit-keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); } }

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); } }

.animate__fadeOutBottomRight {
  -webkit-animation-name: fadeOutBottomRight;
  animation-name: fadeOutBottomRight; }

@-webkit-keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); } }

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); } }

.animate__fadeOutBottomLeft {
  -webkit-animation-name: fadeOutBottomLeft;
  animation-name: fadeOutBottomLeft; }

/* Flippers */
@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.animate__flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.animate__flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.animate__flipOutX {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.animate__flipOutY {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

/* Lightspeed */
@-webkit-keyframes lightSpeedInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes lightSpeedInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__lightSpeedInRight {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes lightSpeedInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__lightSpeedInLeft {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOutRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOutRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.animate__lightSpeedOutRight {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes lightSpeedOutLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0; } }

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0; } }

.animate__lightSpeedOutLeft {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/* Rotating entrances */
@-webkit-keyframes rotateIn {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

/* Rotating exits */
@-webkit-keyframes rotateOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.animate__rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes rotateOutDownLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.animate__rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateOutDownRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.animate__rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

@-webkit-keyframes rotateOutUpLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.animate__rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateOutUpRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.animate__rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

/* Specials */
@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.animate__hinge {
  -webkit-animation-duration: calc(1s * 2);
  animation-duration: calc(1s * 2);
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  -webkit-animation-name: hinge;
  animation-name: hinge;
  -webkit-transform-origin: top left;
  transform-origin: top left; }

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.animate__rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

/* Zooming entrances */
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

/* Zooming exits */
@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0); } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0); } }

.animate__zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
  -webkit-transform-origin: left center;
  transform-origin: left center; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0); } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0); } }

.animate__zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
  -webkit-transform-origin: right center;
  transform-origin: right center; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

/* Sliding entrances */
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

/* Sliding exits */
@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.animate__slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

.custom-bounce {
  animation-delay: var(--delay) !important; }
  .custom-bounce.active {
    animation: bounce var(--duration) forwards;
    -webkit-animation: bounce var(--duration) forwards;
    -moz-animation: bounce var(--duration) forwards;
    -ms-animation: bounce var(--duration) forwards;
    -o-animation: bounce var(--duration) forwards; }

.custom-bounceIn {
  opacity: 0;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  animation-delay: var(--delay) !important; }
  .custom-bounceIn.active {
    animation: bounceIn var(--duration) forwards;
    -webkit-animation: bounceIn var(--duration) forwards;
    -moz-animation: bounceIn var(--duration) forwards;
    -ms-animation: bounceIn var(--duration) forwards;
    -o-animation: bounceIn var(--duration) forwards; }

.custom-bounceInRight {
  -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
  transform: translate3d(3000px, 0, 0) scaleX(3);
  animation-delay: var(--delay) !important; }
  .custom-bounceInRight.active {
    animation: bounceInRight var(--duration) forwards;
    -webkit-animation: bounceInRight var(--duration) forwards;
    -moz-animation: bounceInRight var(--duration) forwards;
    -ms-animation: bounceInRight var(--duration) forwards;
    -o-animation: bounceInRight var(--duration) forwards; }

.custom-bouncenInLeft {
  -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
  transform: translate3d(-3000px, 0, 0) scaleX(3);
  animation-delay: var(--delay) !important; }
  .custom-bouncenInLeft.active {
    animation: bounceInLeft var(--duration) forwards;
    -webkit-animation: bounceInLeft var(--duration) forwards;
    -moz-animation: bounceInLeft var(--duration) forwards;
    -ms-animation: bounceInLeft var(--duration) forwards;
    -o-animation: bounceInLeft var(--duration) forwards; }

.custom-bounceInUp {
  -webkit-transform: translateY(2000px);
  transform: translateY(2000px);
  animation-delay: var(--delay) !important; }
  .custom-bounceInUp.active {
    animation: bounceInUp var(--duration) forwards;
    -webkit-animation: bounceInUp var(--duration) forwards;
    -moz-animation: bounceInUp var(--duration) forwards;
    -ms-animation: bounceInUp var(--duration) forwards;
    -o-animation: bounceInUp var(--duration) forwards; }

.custom-bounceInDown {
  -webkit-transform: translateY(-2000px);
  transform: translateY(-2000px);
  animation-delay: var(--delay) !important; }
  .custom-bounceInDown.active {
    animation: bounceInDown var(--duration) forwards;
    -webkit-animation: bounceInDown var(--duration) forwards;
    -moz-animation: bounceInDown var(--duration) forwards;
    -ms-animation: bounceInDown var(--duration) forwards;
    -o-animation: bounceInDown var(--duration) forwards; }

.custom-bounceOut {
  animation-delay: var(--delay) !important; }
  .custom-bounceOut.active {
    animation: bounceOut var(--duration) forwards;
    -webkit-animation: bounceOut var(--duration) forwards;
    -moz-animation: bounceOut var(--duration) forwards;
    -ms-animation: bounceOut var(--duration) forwards;
    -o-animation: bounceOut var(--duration) forwards; }

.custom-bounceOutDown {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutDown.active {
    animation: bounceOutDown var(--duration) forwards;
    -webkit-animation: bounceOutDown var(--duration) forwards;
    -moz-animation: bounceOutDown var(--duration) forwards;
    -ms-animation: bounceOutDown var(--duration) forwards;
    -o-animation: bounceOutDown var(--duration) forwards; }

.custom-bounceOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutLeft.active {
    animation: bounceOutLeft var(--duration) forwards;
    -webkit-animation: bounceOutLeft var(--duration) forwards;
    -moz-animation: bounceOutLeft var(--duration) forwards;
    -ms-animation: bounceOutLeft var(--duration) forwards;
    -o-animation: bounceOutLeft var(--duration) forwards; }

.custom-bounceOutRight {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutRight.active {
    animation: bounceOutRight var(--duration) forwards;
    -webkit-animation: bounceOutRight var(--duration) forwards;
    -moz-animation: bounceOutRight var(--duration) forwards;
    -ms-animation: bounceOutRight var(--duration) forwards;
    -o-animation: bounceOutRight var(--duration) forwards; }

.custom-bounceOutUp {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutUp.active {
    animation: bounceOutUp var(--duration) forwards;
    -webkit-animation: bounceOutUp var(--duration) forwards;
    -moz-animation: bounceOutUp var(--duration) forwards;
    -ms-animation: bounceOutUp var(--duration) forwards;
    -o-animation: bounceOutUp var(--duration) forwards; }

.custom-fadeIn {
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-fadeIn.active {
    animation: fadeIn var(--duration) forwards;
    -webkit-animation: fadeIn var(--duration) forwards;
    -moz-animation: fadeIn var(--duration) forwards;
    -ms-animation: fadeIn var(--duration) forwards;
    -o-animation: fadeIn var(--duration) forwards; }

.custom-fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInDown.active {
    animation: fadeInDown var(--duration) forwards;
    -webkit-animation: fadeInDown var(--duration) forwards;
    -moz-animation: fadeInDown var(--duration) forwards;
    -ms-animation: fadeInDown var(--duration) forwards;
    -o-animation: fadeInDown var(--duration) forwards; }

.custom-fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInLeft.active {
    animation: fadeInLeft var(--duration) forwards;
    -webkit-animation: fadeInLeft var(--duration) forwards;
    -moz-animation: fadeInLeft var(--duration) forwards;
    -ms-animation: fadeInLeft var(--duration) forwards;
    -o-animation: fadeInLeft var(--duration) forwards; }

.custom-fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInRight.active {
    animation: fadeInRight var(--duration) forwards;
    -webkit-animation: fadeInRight var(--duration) forwards;
    -moz-animation: fadeInRight var(--duration) forwards;
    -ms-animation: fadeInRight var(--duration) forwards;
    -o-animation: fadeInRight var(--duration) forwards; }

.custom-fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInUp.active {
    animation: fadeInUp var(--duration) forwards;
    -webkit-animation: fadeInUp var(--duration) forwards;
    -moz-animation: fadeInUp var(--duration) forwards;
    -ms-animation: fadeInUp var(--duration) forwards;
    -o-animation: fadeInUp var(--duration) forwards; }

.custom-fadeInLeftBig {
  -webkit-transform: translate3d(-2000px, 0, 0);
  transform: translate3d(-2000px, 0, 0);
  animation-delay: var(--delay) !important;
  opacity: 0; }
  .custom-fadeInLeftBig.active {
    animation: fadeInLeftBig var(--duration) forwards;
    -webkit-animation: fadeInLeftBig var(--duration) forwards;
    -moz-animation: fadeInLeftBig var(--duration) forwards;
    -ms-animation: fadeInLeftBig var(--duration) forwards;
    -o-animation: fadeInLeftBig var(--duration) forwards; }

.custom-fadeInRightBig {
  -webkit-transform: translate3d(2000px, 0, 0);
  transform: translate3d(2000px, 0, 0);
  animation-delay: var(--delay) !important;
  opacity: 0; }
  .custom-fadeInRightBig.active {
    animation: fadeInRightBig var(--duration) forwards; }

.custom-fadeInUpBig {
  -webkit-transform: translateY(2000px);
  -ms-transform: translateY(2000px);
  transform: translateY(2000px);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-fadeInUpBig.active {
    animation: fadeInUpBig var(--duration) forwards;
    -webkit-animation: fadeInUpBig var(--duration) forwards;
    -moz-animation: fadeInUpBig var(--duration) forwards;
    -ms-animation: fadeInUpBig var(--duration) forwards;
    -o-animation: fadeInUpBig var(--duration) forwards; }

.custom-fadeInDownBig {
  -webkit-transform: translateY(-2000px);
  -ms-transform: translateY(-2000px);
  transform: translateY(-2000px);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-fadeInDownBig.active {
    animation: fadeInDownBig var(--duration) forwards;
    -webkit-animation: fadeInDownBig var(--duration) forwards;
    -moz-animation: fadeInDownBig var(--duration) forwards;
    -ms-animation: fadeInDownBig var(--duration) forwards;
    -o-animation: fadeInDownBig var(--duration) forwards; }

.custom-fadeInTopLeft {
  opacity: 0;
  -webkit-transform: translate3d(-100%, -100%, 0);
  transform: translate3d(-100%, -100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInTopLeft.active {
    animation: fadeInTopLeft var(--duration) forwards;
    -webkit-animation: fadeInTopLeft var(--duration) forwards;
    -moz-animation: fadeInTopLeft var(--duration) forwards;
    -ms-animation: fadeInTopLeft var(--duration) forwards;
    -o-animation: fadeInTopLeft var(--duration) forwards; }

.custom-fadeInTopRight {
  opacity: 0;
  -webkit-transform: translate3d(100%, -100%, 0);
  transform: translate3d(100%, -100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInTopRight.active {
    animation: fadeInTopRight var(--duration) forwards;
    -webkit-animation: fadeInTopRight var(--duration) forwards;
    -moz-animation: fadeInTopRight var(--duration) forwards;
    -ms-animation: fadeInTopRight var(--duration) forwards;
    -o-animation: fadeInTopRight var(--duration) forwards; }

.custom-fadeInBottomLeft {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 100%, 0);
  transform: translate3d(-100%, 100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInBottomLeft.active {
    animation: fadeInBottomLeft var(--duration) forwards;
    -webkit-animation: fadeInBottomLeft var(--duration) forwards;
    -moz-animation: fadeInBottomLeft var(--duration) forwards;
    -ms-animation: fadeInBottomLeft var(--duration) forwards;
    -o-animation: fadeInBottomLeft var(--duration) forwards; }

.custom-fadeInBottomRight {
  opacity: 0;
  -webkit-transform: translate3d(100%, 100%, 0);
  transform: translate3d(100%, 100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInBottomRight.active {
    animation: fadeInBottomRight var(--duration) forwards;
    -webkit-animation: fadeInBottomRight var(--duration) forwards;
    -moz-animation: fadeInBottomRight var(--duration) forwards;
    -ms-animation: fadeInBottomRight var(--duration) forwards;
    -o-animation: fadeInBottomRight var(--duration) forwards; }

.custom-fadeOut {
  animation-delay: var(--delay) !important; }
  .custom-fadeOut.active {
    animation: fadeOut var(--duration) forwards;
    -webkit-animation: fadeOut var(--duration) forwards;
    -moz-animation: fadeOut var(--duration) forwards;
    -ms-animation: fadeOut var(--duration) forwards;
    -o-animation: fadeOut var(--duration) forwards; }

.custom-fadeOutDown {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutDown.active {
    animation: fadeOutDown var(--duration) forwards;
    -webkit-animation: fadeOutDown var(--duration) forwards;
    -moz-animation: fadeOutDown var(--duration) forwards;
    -ms-animation: fadeOutDown var(--duration) forwards;
    -o-animation: fadeOutDown var(--duration) forwards; }

.custom-fadeOutDownBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutDownBig.active {
    animation: fadeOutDownBig var(--duration) forwards;
    -webkit-animation: fadeOutDownBig var(--duration) forwards;
    -moz-animation: fadeOutDownBig var(--duration) forwards;
    -ms-animation: fadeOutDownBig var(--duration) forwards;
    -o-animation: fadeOutDownBig var(--duration) forwards; }

.custom-fadeOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutLeft.active {
    animation: fadeOutLeft var(--duration) forwards;
    -webkit-animation: fadeOutLeft var(--duration) forwards;
    -moz-animation: fadeOutLeft var(--duration) forwards;
    -ms-animation: fadeOutLeft var(--duration) forwards;
    -o-animation: fadeOutLeft var(--duration) forwards; }

.custom-fadeOutLeftBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutLeftBig.active {
    animation: fadeOutLeftBig var(--duration) forwards;
    -webkit-animation: fadeOutLeftBig var(--duration) forwards;
    -moz-animation: fadeOutLeftBig var(--duration) forwards;
    -ms-animation: fadeOutLeftBig var(--duration) forwards;
    -o-animation: fadeOutLeftBig var(--duration) forwards; }

.custom-fadeOutRight {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutRight.active {
    animation: fadeOutRight var(--duration) forwards;
    -webkit-animation: fadeOutRight var(--duration) forwards;
    -moz-animation: fadeOutRight var(--duration) forwards;
    -ms-animation: fadeOutRight var(--duration) forwards;
    -o-animation: fadeOutRight var(--duration) forwards; }

.custom-fadeOutRightBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutRightBig.active {
    animation: fadeOutRightBig var(--duration) forwards;
    -webkit-animation: fadeOutRightBig var(--duration) forwards;
    -moz-animation: fadeOutRightBig var(--duration) forwards;
    -ms-animation: fadeOutRightBig var(--duration) forwards;
    -o-animation: fadeOutRightBig var(--duration) forwards; }

.custom-fadeOutUp {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutUp.active {
    animation: fadeOutUp var(--duration) forwards;
    -webkit-animation: fadeOutUp var(--duration) forwards;
    -moz-animation: fadeOutUp var(--duration) forwards;
    -ms-animation: fadeOutUp var(--duration) forwards;
    -o-animation: fadeOutUp var(--duration) forwards; }

.custom-fadeOutUpBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutUpBig.active {
    animation: fadeOutUpBig var(--duration) forwards;
    -webkit-animation: fadeOutUpBig var(--duration) forwards;
    -moz-animation: fadeOutUpBig var(--duration) forwards;
    -ms-animation: fadeOutUpBig var(--duration) forwards;
    -o-animation: fadeOutUpBig var(--duration) forwards; }

.custom-fadeOutTopLeft {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutTopLeft.active {
    animation: fadeOutTopLeft var(--duration) forwards;
    -webkit-animation: fadeOutTopLeft var(--duration) forwards;
    -moz-animation: fadeOutTopLeft var(--duration) forwards;
    -ms-animation: fadeOutTopLeft var(--duration) forwards;
    -o-animation: fadeOutTopLeft var(--duration) forwards; }

.custom-fadeOutTopRight {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutTopRight.active {
    animation: fadeOutTopRight var(--duration) forwards;
    -webkit-animation: fadeOutTopRight var(--duration) forwards;
    -moz-animation: fadeOutTopRight var(--duration) forwards;
    -ms-animation: fadeOutTopRight var(--duration) forwards;
    -o-animation: fadeOutTopRight var(--duration) forwards; }

.custom-fadeOutBottomRight {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutBottomRight.active {
    animation: fadeOutBottomRight var(--duration) forwards;
    -webkit-animation: fadeOutBottomRight var(--duration) forwards;
    -moz-animation: fadeOutBottomRight var(--duration) forwards;
    -ms-animation: fadeOutBottomRight var(--duration) forwards;
    -o-animation: fadeOutBottomRight var(--duration) forwards; }

.custom-fadeOutBottomLeft {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutBottomLeft.active {
    animation: fadeOutBottomLeft var(--duration) forwards;
    -webkit-animation: fadeOutBottomLeft var(--duration) forwards;
    -moz-animation: fadeOutBottomLeft var(--duration) forwards;
    -ms-animation: fadeOutBottomLeft var(--duration) forwards;
    -o-animation: fadeOutBottomLeft var(--duration) forwards; }

.custom-zoomIn {
  opacity: 0;
  -webkit-transform: scale3d(0.3, 0.3, 0.3);
  transform: scale3d(0.3, 0.3, 0.3);
  animation-delay: var(--delay) !important; }
  .custom-zoomIn.active {
    animation: zoomIn var(--duration) forwards;
    -webkit-animation: zoomIn var(--duration) forwards;
    -moz-animation: zoomIn var(--duration) forwards;
    -ms-animation: zoomIn var(--duration) forwards;
    -o-animation: zoomIn var(--duration) forwards; }

.custom-zoomInDown {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInDown.active {
    animation: zoomInDown var(--duration) forwards;
    -webkit-animation: zoomInDown var(--duration) forwards;
    -moz-animation: zoomInDown var(--duration) forwards;
    -ms-animation: zoomInDown var(--duration) forwards;
    -o-animation: zoomInDown var(--duration) forwards; }

.custom-zoomInLeft {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInLeft.active {
    animation: zoomInLeft var(--duration) forwards;
    -webkit-animation: zoomInLeft var(--duration) forwards;
    -moz-animation: zoomInLeft var(--duration) forwards;
    -ms-animation: zoomInLeft var(--duration) forwards;
    -o-animation: zoomInLeft var(--duration) forwards; }

.custom-zoomInRight {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInRight.active {
    animation: zoomInRight var(--duration) forwards;
    -webkit-animation: zoomInRight var(--duration) forwards;
    -moz-animation: zoomInRight var(--duration) forwards;
    -ms-animation: zoomInRight var(--duration) forwards;
    -o-animation: zoomInRight var(--duration) forwards; }

.custom-zoomInUp {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInUp.active {
    animation: zoomInUp var(--duration) forwards;
    -webkit-animation: zoomInUp var(--duration) forwards;
    -moz-animation: zoomInUp var(--duration) forwards;
    -ms-animation: zoomInUp var(--duration) forwards;
    -o-animation: zoomInUp var(--duration) forwards; }

.custom-zoomOut {
  animation-delay: var(--delay) !important; }
  .custom-zoomOut.active {
    animation: zoomOut var(--duration) forwards;
    -webkit-animation: zoomOut var(--duration) forwards;
    -moz-animation: zoomOut var(--duration) forwards;
    -ms-animation: zoomOut var(--duration) forwards;
    -o-animation: zoomOut var(--duration) forwards; }

.custom-zoomOutDown {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutDown.active {
    animation: zoomOutDown var(--duration) forwards;
    -webkit-animation: zoomOutDown var(--duration) forwards;
    -moz-animation: zoomOutDown var(--duration) forwards;
    -ms-animation: zoomOutDown var(--duration) forwards;
    -o-animation: zoomOutDown var(--duration) forwards; }

.custom-zoomOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutLeft.active {
    animation: zoomOutLeft var(--duration) forwards;
    -webkit-animation: zoomOutLeft var(--duration) forwards;
    -moz-animation: zoomOutLeft var(--duration) forwards;
    -ms-animation: zoomOutLeft var(--duration) forwards;
    -o-animation: zoomOutLeft var(--duration) forwards; }

.custom-zoomOutRight {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutRight.active {
    animation: zoomOutRight var(--duration) forwards;
    -webkit-animation: zoomOutRight var(--duration) forwards;
    -moz-animation: zoomOutRight var(--duration) forwards;
    -ms-animation: zoomOutRight var(--duration) forwards;
    -o-animation: zoomOutRight var(--duration) forwards; }

.custom-zoomOutUp {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutUp.active {
    animation: zoomOutUp var(--duration) forwards;
    -webkit-animation: zoomOutUp var(--duration) forwards;
    -moz-animation: zoomOutUp var(--duration) forwards;
    -ms-animation: zoomOutUp var(--duration) forwards;
    -o-animation: zoomOutUp var(--duration) forwards; }

.custom-slideInDown {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInDown.active {
    animation: slideInDown var(--duration) forwards;
    -webkit-animation: slideInDown var(--duration) forwards;
    -moz-animation: slideInDown var(--duration) forwards;
    -ms-animation: slideInDown var(--duration) forwards;
    -o-animation: slideInDown var(--duration) forwards; }

.custom-slideInLeft {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInLeft.active {
    animation: slideInLeft var(--duration) forwards;
    -webkit-animation: slideInLeft var(--duration) forwards;
    -moz-animation: slideInLeft var(--duration) forwards;
    -ms-animation: slideInLeft var(--duration) forwards;
    -o-animation: slideInLeft var(--duration) forwards; }

.custom-slideInRight {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInRight.active {
    animation: slideInRight var(--duration) forwards;
    -webkit-animation: slideInRight var(--duration) forwards;
    -moz-animation: slideInRight var(--duration) forwards;
    -ms-animation: slideInRight var(--duration) forwards;
    -o-animation: slideInRight var(--duration) forwards; }

.custom-slideInUp {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInUp.active {
    animation: slideInUp var(--duration) forwards;
    -webkit-animation: slideInUp var(--duration) forwards;
    -moz-animation: slideInUp var(--duration) forwards;
    -ms-animation: slideInUp var(--duration) forwards;
    -o-animation: slideInUp var(--duration) forwards; }

.custom-slideOutDown {
  animation-delay: var(--delay) !important; }
  .custom-slideOutDown.active {
    animation: slideOutDown var(--duration) forwards;
    -webkit-animation: slideOutDown var(--duration) forwards;
    -moz-animation: slideOutDown var(--duration) forwards;
    -ms-animation: slideOutDown var(--duration) forwards;
    -o-animation: slideOutDown var(--duration) forwards; }

.custom-slideOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-slideOutLeft.active {
    animation: slideOutLeft var(--duration) forwards;
    -webkit-animation: slideOutLeft var(--duration) forwards;
    -moz-animation: slideOutLeft var(--duration) forwards;
    -ms-animation: slideOutLeft var(--duration) forwards;
    -o-animation: slideOutLeft var(--duration) forwards; }

.custom-slideOutRight {
  animation-delay: var(--delay) !important; }
  .custom-slideOutRight.active {
    animation: slideOutRight var(--duration) forwards;
    -webkit-animation: slideOutRight var(--duration) forwards;
    -moz-animation: slideOutRight var(--duration) forwards;
    -ms-animation: slideOutRight var(--duration) forwards;
    -o-animation: slideOutRight var(--duration) forwards; }

.custom-slideOutUp {
  animation-delay: var(--delay) !important; }
  .custom-slideOutUp.active {
    animation: slideOutUp var(--duration) forwards;
    -webkit-animation: slideOutUp var(--duration) forwards;
    -moz-animation: slideOutUp var(--duration) forwards;
    -ms-animation: slideOutUp var(--duration) forwards;
    -o-animation: slideOutUp var(--duration) forwards; }

.custom-hinge {
  animation-delay: var(--delay) !important; }
  .custom-hinge.active {
    animation: hinge var(--duration) forwards;
    -webkit-animation: hinge var(--duration) forwards;
    -moz-animation: hinge var(--duration) forwards;
    -ms-animation: hinge var(--duration) forwards;
    -o-animation: hinge var(--duration) forwards; }

.custom-jackInTheBox {
  opacity: 0;
  -webkit-transform: scale(0.1) rotate(30deg);
  transform: scale(0.1) rotate(30deg);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  animation-delay: var(--delay) !important; }
  .custom-jackInTheBox.active {
    animation: jackInTheBox var(--duration) forwards;
    -webkit-animation: jackInTheBox var(--duration) forwards;
    -moz-animation: jackInTheBox var(--duration) forwards;
    -ms-animation: jackInTheBox var(--duration) forwards;
    -o-animation: jackInTheBox var(--duration) forwards; }

.custom-rollIn {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  animation-delay: var(--delay) !important; }
  .custom-rollIn.active {
    animation: rollIn var(--duration) forwards;
    -webkit-animation: rollIn var(--duration) forwards;
    -moz-animation: rollIn var(--duration) forwards;
    -ms-animation: rollIn var(--duration) forwards;
    -o-animation: rollIn var(--duration) forwards; }

.custom-rollOut {
  animation-delay: var(--delay) !important; }
  .custom-rollOut.active {
    animation: rollOut var(--duration) forwards;
    -webkit-animation: rollOut var(--duration) forwards;
    -moz-animation: rollOut var(--duration) forwards;
    -ms-animation: rollOut var(--duration) forwards;
    -o-animation: rollOut var(--duration) forwards; }

.custom-lightSpeedInRight {
  -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
  transform: translate3d(100%, 0, 0) skewX(-30deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedInRight.active {
    animation: lightSpeedInRight var(--duration) forwards;
    -webkit-animation: lightSpeedInRight var(--duration) forwards;
    -moz-animation: lightSpeedInRight var(--duration) forwards;
    -ms-animation: lightSpeedInRight var(--duration) forwards;
    -o-animation: lightSpeedInRight var(--duration) forwards; }

.custom-lightSpeedInLeft {
  -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
  transform: translate3d(-100%, 0, 0) skewX(30deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedInLeft.active {
    animation: lightSpeedInLeft var(--duration) forwards;
    -webkit-animation: lightSpeedInLeft var(--duration) forwards;
    -moz-animation: lightSpeedInLeft var(--duration) forwards;
    -ms-animation: lightSpeedInLeft var(--duration) forwards;
    -o-animation: lightSpeedInLeft var(--duration) forwards; }

.custom-lightSpeedOutRight {
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedOutRight.active {
    animation: lightSpeedOutRight var(--duration) forwards;
    -webkit-animation: lightSpeedOutRight var(--duration) forwards;
    -moz-animation: lightSpeedOutRight var(--duration) forwards;
    -ms-animation: lightSpeedOutRight var(--duration) forwards;
    -o-animation: lightSpeedOutRight var(--duration) forwards; }

.custom-lightSpeedOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedOutLeft.active {
    animation: lightSpeedOutLeft var(--duration) forwards;
    -webkit-animation: lightSpeedOutLeft var(--duration) forwards;
    -moz-animation: lightSpeedOutLeft var(--duration) forwards;
    -ms-animation: lightSpeedOutLeft var(--duration) forwards;
    -o-animation: lightSpeedOutLeft var(--duration) forwards; }

.custom-rotateIn {
  -webkit-transform: rotate3d(0, 0, 1, -200deg);
  transform: rotate3d(0, 0, 1, -200deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateIn.active {
    animation: rotateIn var(--duration) forwards;
    -webkit-animation: rotateIn var(--duration) forwards;
    -moz-animation: rotateIn var(--duration) forwards;
    -ms-animation: rotateIn var(--duration) forwards;
    -o-animation: rotateIn var(--duration) forwards; }

.custom-rotateInDownLeft {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInDownLeft.active {
    animation: rotateInDownLeft var(--duration) forwards;
    -webkit-animation: rotateInDownLeft var(--duration) forwards;
    -moz-animation: rotateInDownLeft var(--duration) forwards;
    -ms-animation: rotateInDownLeft var(--duration) forwards;
    -o-animation: rotateInDownLeft var(--duration) forwards; }

.custom-rotateInDownRight {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInDownRight.active {
    animation: rotateInDownRight var(--duration) forwards;
    -webkit-animation: rotateInDownRight var(--duration) forwards;
    -moz-animation: rotateInDownRight var(--duration) forwards;
    -ms-animation: rotateInDownRight var(--duration) forwards;
    -o-animation: rotateInDownRight var(--duration) forwards; }

.custom-rotateInUpLeft {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInUpLeft.active {
    animation: rotateInUpLeft var(--duration) forwards;
    -webkit-animation: rotateInUpLeft var(--duration) forwards;
    -moz-animation: rotateInUpLeft var(--duration) forwards;
    -ms-animation: rotateInUpLeft var(--duration) forwards;
    -o-animation: rotateInUpLeft var(--duration) forwards; }

.custom-rotateInUpRight {
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
  transform: rotate3d(0, 0, 1, -90deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInUpRight.active {
    animation: rotateInUpRight var(--duration) forwards;
    -webkit-animation: rotateInUpRight var(--duration) forwards;
    -moz-animation: rotateInUpRight var(--duration) forwards;
    -ms-animation: rotateInUpRight var(--duration) forwards;
    -o-animation: rotateInUpRight var(--duration) forwards; }

.custom-rotateOut {
  animation-delay: var(--delay) !important; }
  .custom-rotateOut.active {
    animation: rotateOut var(--duration) forwards;
    -webkit-animation: rotateOut var(--duration) forwards;
    -moz-animation: rotateOut var(--duration) forwards;
    -ms-animation: rotateOut var(--duration) forwards;
    -o-animation: rotateOut var(--duration) forwards; }

.custom-rotateOutDownLeft {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutDownLeft.active {
    animation: rotateOutDownLeft var(--duration) forwards;
    -webkit-animation: rotateOutDownLeft var(--duration) forwards;
    -moz-animation: rotateOutDownLeft var(--duration) forwards;
    -ms-animation: rotateOutDownLeft var(--duration) forwards;
    -o-animation: rotateOutDownLeft var(--duration) forwards; }

.custom-rotateOutDownRight {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutDownRight.active {
    animation: rotateOutDownRight var(--duration) forwards;
    -webkit-animation: rotateOutDownRight var(--duration) forwards;
    -moz-animation: rotateOutDownRight var(--duration) forwards;
    -ms-animation: rotateOutDownRight var(--duration) forwards;
    -o-animation: rotateOutDownRight var(--duration) forwards; }

.custom-rotateOutUpLeft {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutUpLeft.active {
    animation: rotateOutUpLeft var(--duration) forwards;
    -webkit-animation: rotateOutUpLeft var(--duration) forwards;
    -moz-animation: rotateOutUpLeft var(--duration) forwards;
    -ms-animation: rotateOutUpLeft var(--duration) forwards;
    -o-animation: rotateOutUpLeft var(--duration) forwards; }

.custom-rotateOutUpRight {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutUpRight.active {
    animation: rotateOutUpRight var(--duration) forwards;
    -webkit-animation: rotateOutUpRight var(--duration) forwards;
    -moz-animation: rotateOutUpRight var(--duration) forwards;
    -ms-animation: rotateOutUpRight var(--duration) forwards;
    -o-animation: rotateOutUpRight var(--duration) forwards; }

.custom-flip {
  animation-delay: var(--delay) !important; }
  .custom-flip.active {
    animation: flip var(--duration) forwards;
    -webkit-animation: flip var(--duration) forwards;
    -moz-animation: flip var(--duration) forwards;
    -ms-animation: flip var(--duration) forwards;
    -o-animation: flip var(--duration) forwards; }

.custom-flipInX {
  -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
  transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-flipInX.active {
    animation: flipInX var(--duration) forwards;
    -webkit-animation: flipInX var(--duration) forwards;
    -moz-animation: flipInX var(--duration) forwards;
    -ms-animation: flipInX var(--duration) forwards;
    -o-animation: flipInX var(--duration) forwards; }

.custom-flipInY {
  -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
  transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-flipInY.active {
    animation: flipInY var(--duration) forwards;
    -webkit-animation: flipInY var(--duration) forwards;
    -moz-animation: flipInY var(--duration) forwards;
    -ms-animation: flipInY var(--duration) forwards;
    -o-animation: flipInY var(--duration) forwards; }

.custom-flipOutX {
  animation-delay: var(--delay) !important; }
  .custom-flipOutX.active {
    animation: flipOutX var(--duration) forwards;
    -webkit-animation: flipOutX var(--duration) forwards;
    -moz-animation: flipOutX var(--duration) forwards;
    -ms-animation: flipOutX var(--duration) forwards;
    -o-animation: flipOutX var(--duration) forwards; }

.custom-flipOutY {
  animation-delay: var(--delay) !important; }
  .custom-flipOutY.active {
    animation: flipOutY var(--duration) forwards;
    -webkit-animation: flipOutY var(--duration) forwards;
    -moz-animation: flipOutY var(--duration) forwards;
    -ms-animation: flipOutY var(--duration) forwards;
    -o-animation: flipOutY var(--duration) forwards; }

.custom-flash {
  animation-delay: var(--delay) !important; }
  .custom-flash.active {
    animation: flash var(--duration) forwards;
    -webkit-animation: flash var(--duration) forwards;
    -moz-animation: flash var(--duration) forwards;
    -ms-animation: flash var(--duration) forwards;
    -o-animation: flash var(--duration) forwards; }

.custom-pulse {
  animation-delay: var(--delay) !important; }
  .custom-pulse.active {
    animation: pulse var(--duration) forwards;
    -webkit-animation: pulse var(--duration) forwards;
    -moz-animation: pulse var(--duration) forwards;
    -ms-animation: pulse var(--duration) forwards;
    -o-animation: pulse var(--duration) forwards; }

.custom-rubberBand {
  animation-delay: var(--delay) !important; }
  .custom-rubberBand.active {
    animation: rubberBand var(--duration) forwards;
    -webkit-animation: rubberBand var(--duration) forwards;
    -moz-animation: rubberBand var(--duration) forwards;
    -ms-animation: rubberBand var(--duration) forwards;
    -o-animation: rubberBand var(--duration) forwards; }

.custom-shakeX {
  animation-delay: var(--delay) !important; }
  .custom-shakeX.active {
    animation: shakeX var(--duration) forwards;
    -webkit-animation: shakeX var(--duration) forwards;
    -moz-animation: shakeX var(--duration) forwards;
    -ms-animation: shakeX var(--duration) forwards;
    -o-animation: shakeX var(--duration) forwards; }

.custom-shakeX {
  animation-delay: var(--delay) !important; }
  .custom-shakeX.active {
    animation: shakeX var(--duration) forwards;
    -webkit-animation: shakeX var(--duration) forwards;
    -moz-animation: shakeX var(--duration) forwards;
    -ms-animation: shakeX var(--duration) forwards;
    -o-animation: shakeX var(--duration) forwards; }

.custom-headShake {
  animation-delay: var(--delay) !important; }
  .custom-headShake.active {
    animation: headShake var(--duration) forwards;
    -webkit-animation: headShake var(--duration) forwards;
    -moz-animation: headShake var(--duration) forwards;
    -ms-animation: headShake var(--duration) forwards;
    -o-animation: headShake var(--duration) forwards; }

.custom-swing {
  animation-delay: var(--delay) !important; }
  .custom-swing.active {
    animation: swing var(--duration) forwards;
    -webkit-animation: swing var(--duration) forwards;
    -moz-animation: swing var(--duration) forwards;
    -ms-animation: swing var(--duration) forwards;
    -o-animation: swing var(--duration) forwards; }

.custom-tada {
  animation-delay: var(--delay) !important; }
  .custom-tada.active {
    animation: tada var(--duration) forwards;
    -webkit-animation: tada var(--duration) forwards;
    -moz-animation: tada var(--duration) forwards;
    -ms-animation: tada var(--duration) forwards;
    -o-animation: tada var(--duration) forwards; }

.custom-wobble {
  animation-delay: var(--delay) !important; }
  .custom-wobble.active {
    animation: wobble var(--duration) forwards;
    -webkit-animation: wobble var(--duration) forwards;
    -moz-animation: wobble var(--duration) forwards;
    -ms-animation: wobble var(--duration) forwards;
    -o-animation: wobble var(--duration) forwards; }

.custom-jello {
  animation-delay: var(--delay) !important; }
  .custom-jello.active {
    animation: jello var(--duration) forwards;
    -webkit-animation: jello var(--duration) forwards;
    -moz-animation: jello var(--duration) forwards;
    -ms-animation: jello var(--duration) forwards;
    -o-animation: jello var(--duration) forwards; }

.custom-heartBeat {
  animation-delay: var(--delay) !important; }
  .custom-heartBeat.active {
    animation: heartBeat var(--duration) forwards;
    -webkit-animation: heartBeat var(--duration) forwards;
    -moz-animation: heartBeat var(--duration) forwards;
    -ms-animation: heartBeat var(--duration) forwards;
    -o-animation: heartBeat var(--duration) forwards; }

.custom-backInDown {
  -webkit-transform: translateY(-1200px) scale(0.7);
  transform: translateY(-1200px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInDown.active {
    animation: backInDown var(--duration) forwards;
    -webkit-animation: backInDown var(--duration) forwards;
    -moz-animation: backInDown var(--duration) forwards;
    -ms-animation: backInDown var(--duration) forwards;
    -o-animation: backInDown var(--duration) forwards; }

.custom-backInLeft {
  -webkit-transform: translateX(-2000px) scale(0.7);
  transform: translateX(-2000px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInLeft.active {
    animation: backInLeft var(--duration) forwards;
    -webkit-animation: backInLeft var(--duration) forwards;
    -moz-animation: backInLeft var(--duration) forwards;
    -ms-animation: backInLeft var(--duration) forwards;
    -o-animation: backInLeft var(--duration) forwards; }

.custom-backInRight {
  -webkit-transform: translateX(2000px) scale(0.7);
  transform: translateX(2000px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInRight.active {
    animation: backInRight var(--duration) forwards;
    -webkit-animation: backInRight var(--duration) forwards;
    -moz-animation: backInRight var(--duration) forwards;
    -ms-animation: backInRight var(--duration) forwards;
    -o-animation: backInRight var(--duration) forwards; }

.custom-backInUp {
  -webkit-transform: translateY(1200px) scale(0.7);
  transform: translateY(1200px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInUp.active {
    animation: backInUp var(--duration) forwards;
    -webkit-animation: backInUp var(--duration) forwards;
    -moz-animation: backInUp var(--duration) forwards;
    -ms-animation: backInUp var(--duration) forwards;
    -o-animation: backInUp var(--duration) forwards; }

.custom-backOutDown {
  animation-delay: var(--delay) !important; }
  .custom-backOutDown.active {
    animation: backOutDown var(--duration) forwards;
    -webkit-animation: backOutDown var(--duration) forwards;
    -moz-animation: backOutDown var(--duration) forwards;
    -ms-animation: backOutDown var(--duration) forwards;
    -o-animation: backOutDown var(--duration) forwards; }

.custom-backOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-backOutLeft.active {
    animation: backOutLeft var(--duration) forwards;
    -webkit-animation: backOutLeft var(--duration) forwards;
    -moz-animation: backOutLeft var(--duration) forwards;
    -ms-animation: backOutLeft var(--duration) forwards;
    -o-animation: backOutLeft var(--duration) forwards; }

.custom-backOutRight {
  animation-delay: var(--delay) !important; }
  .custom-backOutRight.active {
    animation: backOutRight var(--duration) forwards;
    -webkit-animation: backOutRight var(--duration) forwards;
    -moz-animation: backOutRight var(--duration) forwards;
    -ms-animation: backOutRight var(--duration) forwards;
    -o-animation: backOutRight var(--duration) forwards; }

.custom-backOutUp {
  animation-delay: var(--delay) !important; }
  .custom-backOutUp.active {
    animation: backOutUp var(--duration) forwards;
    -webkit-animation: backOutUp var(--duration) forwards;
    -moz-animation: backOutUp var(--duration) forwards;
    -ms-animation: backOutUp var(--duration) forwards;
    -o-animation: backOutUp var(--duration) forwards; }

.range .range-slider {
  height: 0.2rem;
  width: 100%;
  background-color: #d9d9d9;
  position: relative; }
  .range .range-slider .progress {
    position: absolute;
    height: 0.2rem;
    top: 0;
    background-color: var(--color-pri); }

.range .range-input {
  position: relative; }
  .range .range-input input {
    position: absolute;
    top: -0.1rem;
    height: 0.2rem;
    width: 100%;
    transform: translateY(-50%);
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    margin: 0;
    cursor: pointer; }
    .range .range-input input:first-child::-webkit-slider-thumb {
      height: 1.6rem;
      width: 1.6rem; }
    .range .range-input input::-webkit-slider-thumb {
      height: 1.6rem;
      width: 1.6rem;
      border-radius: 50%;
      pointer-events: auto;
      -webkit-appearance: none;
      background-color: white;
      border: 0.3rem solid var(--color-pri); }
    .range .range-input input::-moz-slider-thumb {
      height: 1.6rem;
      width: 1.6rem;
      border: none;
      border-radius: 50%;
      border: 0.3rem solid var(--color-pri);
      pointer-events: auto;
      -moz-appearance: none;
      background-color: white;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16); }

.range .text {
  margin-top: 1.6rem;
  font-size: 1.6rem;
  color: #444444;
  margin-bottom: 0.8rem; }

.range .range-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: .8rem; }

.range .range-item {
  font-size: 1.4rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  font-weight: 500; }
  .range .range-item .name {
    margin-bottom: 0.5rem; }
  .range .range-item .price {
    border-radius: 2.4rem;
    padding: 1.4rem 1rem;
    background-color: white;
    min-width: 12rem;
    display: block;
    border: 0.1rem solid #EAEAEA; }

.swiper-wrapper.row {
  flex-wrap: nowrap; }

.swiper.rows .col {
  margin-bottom: 0; }

.swiper-container {
  overflow: hidden; }

.swiper-slide {
  height: initial;
  overflow: hidden; }

.swiper-pagination {
  position: static;
  margin-top: 1.6rem; }

.swiper-control {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  --size: 4rem; }
  @media screen and (max-width: 1250px) {
    .swiper-control {
      --size: 3rem; } }
  .swiper-control-btn {
    width: var(--size);
    height: var(--size);
    border-radius: 100rem;
    background-color: white;
    border: 0.1rem solid var(--color-pri);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    z-index: 5;
    position: relative;
    overflow: hidden;
    font-size: 1.6rem;
    color: var(--color-pri); }
    .swiper-control-btn::after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: var(--color-pri);
      transition: all 0.4s ease;
      z-index: 1;
      pointer-events: none; }
    .swiper-control-btn > * {
      position: relative;
      z-index: 2; }
    .swiper-control-btn.swiper-button-disabled {
      cursor: default;
      box-shadow: none;
      opacity: 0.5;
      display: none; }
    @media (hover: hover) {
      .swiper-control-btn:hover:not(.swiper-button-disabled) {
        color: white !important; }
        .swiper-control-btn:hover:not(.swiper-button-disabled)::after {
          left: 0;
          width: 100%; } }
  .swiper-control.white .swiper-control-btn {
    background-color: transparent;
    border-color: white;
    color: white; }
  @media screen and (max-width: 1350px) {
    .swiper-control.white.mobile-plus .swiper-control-btn {
      background-color: white;
      border-color: var(--color-pri);
      color: var(--color-pri); } }
  .swiper-control.posi .swiper-control-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
  .swiper-control.posi.minus .swiper-prev {
    right: calc(100% + 4rem); }
    @media screen and (max-width: 1350px) {
      .swiper-control.posi.minus .swiper-prev {
        right: unset;
        left: calc(var(--size) / 2 * -1); } }
  .swiper-control.posi.minus .swiper-next {
    left: calc(100% + 4rem); }
    @media screen and (max-width: 1350px) {
      .swiper-control.posi.minus .swiper-next {
        left: unset;
        right: calc(var(--size) / 2 * -1); } }
  @media screen and (max-width: 1200px) {
    .swiper-control.posi.minus.mobile-plus .swiper-prev {
      left: calc((var(--size) / 2 * -1) + (1.5rem / 2)); }
    .swiper-control.posi.minus.mobile-plus .swiper-next {
      right: calc((var(--size) / 2 * -1) + (1.5rem / 2)); } }
  .swiper-control.posi.midle .swiper-prev {
    left: calc(var(--size) / 2 * -1); }
  .swiper-control.posi.midle .swiper-next {
    right: calc(var(--size) / 2 * -1); }
  .swiper-control.posi.plus .swiper-prev {
    left: 1rem; }
  .swiper-control.posi.plus .swiper-next {
    right: 1rem; }
  @media screen and (max-width: 1200px) {
    .swiper-control.posi.mobile-plus .swiper-prev {
      left: calc((var(--size) / 2 * -1) + (1.5rem / 2)); }
    .swiper-control.posi.mobile-plus .swiper-next {
      right: calc((var(--size) / 2 * -1) + (1.5rem / 2)); } }

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.1);
  height: .1rem !important; }

.swiper-pagination-progressbar-fill {
  background-color: var(--color-second) !important; }

.txt-animated.ani-opa .char {
  opacity: 0;
  transition: 0.6s ease;
  transition-delay: calc(var(--delay) * var(--char-index)); }

.txt-animated.ani-opa.is-inview .char {
  opacity: 1; }

.txt-animated.ani-up .word {
  overflow: hidden; }

.txt-animated.ani-up .char {
  opacity: 0;
  transition: 0.6s ease;
  transition-delay: calc(var(--delay) * var(--char-index));
  transform: translateY(100%); }

.txt-animated.ani-up.is-inview .char {
  opacity: 1;
  transform: translateY(0); }

.load-img {
  position: relative;
  overflow: hidden; }
  .load-img::before, .load-img::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10; }
  .load-img img,
  .load-img form {
    opacity: 0;
    transition: 0.4s ease-in-out;
    transition-delay: 0.4s; }
  .load-img:before {
    background-color: var(--color-pri); }
  .load-img:after {
    background-color: #f8f8f8;
    transition-delay: .5s; }
  .load-img.is-inview img,
  .load-img.is-inview form {
    opacity: 1; }
  .load-img.is-inview:before {
    transition: 1.6s ease;
    width: 0; }
  .load-img.is-inview:after {
    transition: 0.8s ease;
    width: 0; }

.load-img-second {
  position: relative;
  overflow: hidden; }
  .load-img-second::before {
    position: absolute;
    content: "";
    inset: 0;
    background-color: #f8f8f8;
    z-index: 1;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition: 0.8s ease-out;
    -webkit-transition: 0.8s ease-out;
    -moz-transition: 0.8s ease-out;
    -ms-transition: 0.8s ease-out;
    -o-transition: 0.8s ease-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    pointer-events: none;
    z-index: 3; }
  .load-img-second::after {
    position: absolute;
    content: "";
    inset: 0;
    background-color: #f8f8f8;
    z-index: 1;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition: 0.8s ease-out;
    -webkit-transition: 0.8s ease-out;
    -moz-transition: 0.8s ease-out;
    -ms-transition: 0.8s ease-out;
    -o-transition: 0.8s ease-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    pointer-events: none;
    z-index: 3; }
  .load-img-second.is-inview::after {
    transform: translate(0, -110%); }
  .load-img-second.is-inview::before {
    transform: translate(0, 110%); }

.load-img-third {
  position: relative;
  overflow: hidden; }
  .load-img-third img {
    transform: translateY(-20px);
    opacity: 0;
    transition: 1.2s ease;
    -webkit-transition: 1.2s ease;
    -moz-transition: 1.2s ease;
    -ms-transition: 1.2s ease;
    -o-transition: 1.2s ease;
    transition-delay: 1s;
    -webkit-transition-delay: 1s;
    -moz-transition-delay: 1s;
    -ms-transition-delay: 1s;
    -o-transition-delay: 1s; }
  .load-img-third.is-inview::before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 100%;
    left: 0;
    margin-bottom: -10px;
    background-color: #e2dbc0;
    z-index: 3;
    animation: 1s reveal-img-before cubic-bezier(0.6, 0, 0.195, 1);
    animation-delay: 0.3s; }
  .load-img-third.is-inview img {
    transform: translate(-50%, -50%);
    opacity: 1;
    display: block; }
  .load-img-third.second.is-inview img {
    transform: translateY(0);
    opacity: 1;
    display: block; }

@keyframes reveal-img-before {
  0% {
    top: 0;
    right: 100%;
    bottom: 100%;
    margin-bottom: -10px; }
  40% {
    top: 0;
    right: 0;
    bottom: 100%;
    margin-bottom: -10px; }
  60% {
    top: 0; }
  80% {
    right: 0;
    bottom: -1px;
    margin-bottom: 0; }
  100% {
    top: 100%;
    right: 0;
    bottom: -1px;
    margin-bottom: 0; } }

@keyframes imgLoad {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-in-right {
  0% {
    transform: translate3D(-100%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-out-right {
  0% {
    transform: translate3D(0, 0, 0); }
  100% {
    transform: translate3D(100%, 0, 0); } }

@keyframes slide-in-right-50 {
  0% {
    transform: translate3D(-50%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-in-left {
  0% {
    transform: translate3D(100%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-out-left {
  0% {
    transform: translate3D(0, 0, 0); }
  100% {
    transform: translate3D(-100%, 0, 0); } }

@keyframes slide-in-top {
  0% {
    transform: translate3D(0, -100%, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-out-left-50 {
  0% {
    transform: translate3D(0, 0, 0); }
  100% {
    transform: translate3D(-50%, 0, 0); } }

@keyframes slide-in-left-50 {
  0% {
    transform: translate3D(50%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes revealRTL {
  0% {
    width: 0;
    right: 0; }
  65% {
    width: 100%;
    right: 0; }
  100% {
    width: 0;
    right: 100%; } }

.txt-focus {
  opacity: 0; }
  .txt-focus.is-inview {
    -webkit-animation: txt-focus 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: txt-focus 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; }

.hola {
  position: relative;
  pointer-events: none; }
  .hola::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    -webkit-mask: url(../assets/images/mask.png);
    mask: url(../assets/images/mask.png);
    -webkit-mask-size: 2300% 100%;
    mask-size: 2300% 100%;
    pointer-events: none;
    z-index: 5; }
  .hola.is-inview::after {
    animation: MaskPlay 1.2s steps(22) 0.3s forwards;
    -webkit-animation: MaskPlay 1.2s steps(22) 0.3s forwards;
    -moz-animation: MaskPlay 1.2s steps(22) 0.3s forwards;
    -ms-animation: MaskPlay 1.2s steps(22) 0.3s forwards;
    -o-animation: MaskPlay 1.2s steps(22) 0.3s forwards; }

.txt-spl .word {
  overflow: hidden;
  line-height: 1.3;
  display: inline-flex; }
  .txt-spl .word .chars {
    display: inline-block;
    animation-delay: calc(var(--line-index) * 0.025s) !important;
    transform: translateY(100%); }

.txt-spl.down .chars {
  transform: translateY(-100%); }

.txt-spl.is-inview .chars {
  animation: txtSpl-up 1s cubic-bezier(0.65, 0, 0.35, 1) both; }

.txt-spl.is-inview.down .chars {
  animation: txtSpl-down 1.2s cubic-bezier(0.65, 0, 0.35, 1) both; }

@keyframes txtSpl-up {
  0% {
    transform: translateY(10rem); }
  100% {
    transform: translateY(0); } }

@keyframes txtSpl-down {
  0% {
    transform: translateY(-10rem); }
  100% {
    transform: translateY(0); } }

.reveal {
  --duration: 1s;
  transition: var(--duration) cubic-bezier(0.72, 0.04, 0.49, 0.82);
  transition-delay: var(--delay) !important; }
  .reveal.reveal-left {
    clip-path: polygon();
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    -ms-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    -o-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
    .reveal.reveal-left.is-inview {
      clip-path: polygon();
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -ms-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -o-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  .reveal.reveal-right {
    clip-path: polygon();
    -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    -moz-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    -ms-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    -o-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%); }
    .reveal.reveal-right.is-inview {
      clip-path: polygon();
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -ms-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -o-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  .reveal.reveal-up {
    clip-path: polygon();
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    -ms-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    -o-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%); }
    .reveal.reveal-up.is-inview {
      clip-path: polygon();
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -ms-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -o-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

.breadcrumb {
  padding: 1.2rem 0; }
  @media screen and (min-width: 1200px) {
    .breadcrumb-wrapper {
      width: fit-content; } }
  .breadcrumb-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    gap: .8rem; }
  .breadcrumb-item {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    gap: .8rem;
    font-weight: 400;
    color: var(--color-text); }
    @media only screen and (max-width: 425px) {
      .breadcrumb-item {
        font-size: 1.4rem; } }
    .breadcrumb-item:not(:first-child)::before {
      display: inline-block;
      content: '/';
      border-radius: 100rem;
      vertical-align: middle; }
    .breadcrumb-item:last-child {
      color: var(--color-text);
      pointer-events: none;
      font-weight: 600; }
    @media (hover: hover) {
      .breadcrumb-item:hover {
        text-decoration: underline; } }
  .breadcrumb-link {
    color: inherit;
    transition: .2s ease-in-out; }

.btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  border-radius: 1.6rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  overflow: hidden;
  position: relative;
  background: transparent;
  gap: 1rem;
  color: #ffffff;
  font-size: 1.8rem;
  height: 4.8rem;
  background-color: var(--color-pri);
  border: 0.1rem solid var(--color-pri); }
  @media only screen and (max-width: 1200px) {
    .btn {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .btn {
      font-size: 1.4rem; } }
  @media screen and (max-width: 425px) {
    .btn {
      height: 3.8rem;
      border-radius: .8rem; } }
  .btn > * {
    position: relative;
    z-index: 2; }
  .btn:after {
    position: absolute;
    content: "";
    width: 120%;
    height: 102%;
    top: 0;
    left: -13%;
    background: #ffffff;
    transition: transform 0.6s;
    z-index: 0;
    transform-origin: right;
    transform: skew(30deg) scaleX(0); }
  .btn .txt {
    position: relative;
    display: grid;
    place-items: center;
    height: 100%;
    padding: 1.6rem 4rem;
    transition: 0.4s;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    gap: 1rem;
    color: white;
    flex-shrink: 0; }
    @media screen and (max-width: 425px) {
      .btn .txt {
        padding: .8rem 1.6rem; } }
  .btn img {
    width: 1.6rem;
    height: 1.6rem;
    filter: brightness(0) invert(1); }
  @media (hover: hover) {
    .btn:hover .txt {
      color: var(--color-pri); }
    .btn:hover img {
      filter: brightness(0) saturate(100%) invert(15%) sepia(93%) saturate(4138%) hue-rotate(156deg) brightness(95%) contrast(101%); }
    .btn:hover::after {
      transform: skew(30deg) scaleX(1);
      transform-origin: left; } }
  .btn.black {
    background-color: black; }
    .btn.black .txt {
      color: white; }
    @media (hover: hover) {
      .btn.black:hover .txt {
        color: #ffffff; } }
  .btn.white {
    background-color: white;
    border: 0.1rem solid var(--color-pri); }
    .btn.white .txt {
      color: var(--color-pri); }
    .btn.white::after {
      background: var(--color-pri); }
    @media (hover: hover) {
      .btn.white:hover .txt {
        color: #ffffff; } }
  .btn.bd-white {
    background-color: white;
    border: unset;
    height: 3.2rem;
    flex-shrink: 0; }
    .btn.bd-white::after {
      background: var(--color-hover) !important; }
    .btn.bd-white .txt {
      padding: .8rem 1.6rem;
      color: var(--color-pri);
      font-weight: 600;
      font-size: 1.2rem;
      flex-direction: row-reverse; }
      @media screen and (max-width: 800px) {
        .btn.bd-white .txt {
          padding: .4rem .8rem; } }
      .btn.bd-white .txt img {
        filter: unset; }
    .btn.bd-white::after {
      background: var(--color-pri); }
    @media (hover: hover) {
      .btn.bd-white:hover .txt {
        color: #ffffff; }
        .btn.bd-white:hover .txt img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(10%) hue-rotate(238deg) brightness(104%) contrast(103%); } }
  .btn.second {
    background-color: var(--color-second);
    border: 0.1rem solid var(--color-second); }
    .btn.second .txt {
      color: white; }
    .btn.second::after {
      background-color: white; }
    @media (hover: hover) {
      .btn.second:hover img {
        filter: brightness(0) saturate(100%) invert(32%) sepia(34%) saturate(7124%) hue-rotate(200deg) brightness(88%) contrast(84%); }
      .btn.second:hover .txt {
        color: var(--color-second); } }
  .btn.third {
    background-color: white;
    border: 0.1rem solid var(--color-second); }
    .btn.third .txt {
      color: var(--color-second); }
    .btn.third::after {
      background-color: var(--color-second); }
    .btn.third img {
      filter: none; }
    @media (hover: hover) {
      .btn.third:hover .txt {
        color: white; }
      .btn.third:hover img {
        filter: brightness(0) invert(1); } }
  .btn.red {
    background-color: var(--color-red);
    border: 0.1rem solid var(--color-red); }
    .btn.red .txt {
      color: white; }
    .btn.red::after {
      background-color: white; }
    @media (hover: hover) {
      .btn.red:hover img {
        filter: brightness(0) saturate(100%) invert(17%) sepia(44%) saturate(6405%) hue-rotate(355deg) brightness(94%) contrast(92%); }
      .btn.red:hover .txt {
        color: var(--color-red); } }
  .btn-gr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; }
  .btn.center {
    margin-left: auto;
    margin-right: auto; }
  .btn.full {
    width: 100%; }
  .btn.btn-pri {
    padding: 0 1.2rem; }
    @media screen and (max-width: 500px) {
      .btn.btn-pri {
        padding: 0; } }
    @media screen and (max-width: 1200px) {
      .btn.btn-pri .txt {
        font-size: 1.4rem; } }
  .btn.bg-trans {
    background-color: transparent; }
    .btn.bg-trans .txt {
      color: var(--color-pri); }
    .btn.bg-trans::after {
      background-color: var(--color-pri); }
    @media (hover: hover) {
      .btn.bg-trans:hover .txt {
        color: #ffffff; } }
  .btn.arrow .txt {
    padding-right: .4rem;
    gap: 2rem; }
  .btn.w200 {
    width: 20rem;
    max-width: 100%; }

.seeDetail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  transition: 0.4s; }
  @media (hover: hover) {
    .seeDetail:hover {
      color: var(--color-second); } }

.link {
  display: block;
  color: var(--color-pri);
  width: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  transition: .3s; }
  @media only screen and (max-width: 425px) {
    .link {
      font-size: 1.4rem; } }
  @media (hover: hover) {
    .link:hover {
      color: var(--color-second); } }
  .link.center {
    text-align: center; }

.input {
  font-family: "Roboto", sans-serif;
  width: 100%; }
  .input-area {
    height: auto; }

.re-label {
  color: #000000;
  font-weight: 700;
  margin-bottom: 0.8rem;
  display: block;
  font-size: 1.4rem; }

.re-input {
  padding: 1.4rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  border: none;
  outline: none;
  display: block;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: .8rem;
  font-size: 1.4rem;
  font-weight: 600; }
  .re-input:focus {
    border-color: var(--color-second); }
  .re-input.placeholder {
    color: var(--color-text); }
  .re-input:-moz-placeholder {
    color: var(--color-text); }
  .re-input::-moz-placeholder {
    color: var(--color-text); }
  .re-input:-ms-input-placeholder {
    color: var(--color-text); }
  .re-input::-webkit-input-placeholder {
    color: var(--color-text); }

textarea {
  resize: none; }

form .wpcf7-not-valid-tip {
  bottom: .1rem;
  right: .6rem; }

form .btn .wpcf7-spinner {
  position: absolute; }

form .wpcf7-form-control-wrap {
  display: block;
  position: relative; }

.wpcf7-response-output {
  text-align: center; }

.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-top: 2rem; }
  @media screen and (max-width: 800px) {
    .page-numbers {
      margin-top: calc(2rem / 2); } }
  .page-numbers > li:not(:first-child) {
    margin-left: 8px; }
  .page-numbers > li > .page-numbers {
    width: 4rem;
    height: 4rem;
    border-radius: 100rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: var(--color-text);
    font-weight: 400;
    font-size: 1.4rem; }
    @media screen and (max-width: 500px) {
      .page-numbers > li > .page-numbers {
        width: 3rem;
        height: 3rem; } }
    @media screen and (max-width: 500px) {
      .page-numbers > li > .page-numbers {
        width: 3rem;
        height: 3rem; } }
    @media screen and (max-width: 350px) {
      .page-numbers > li > .page-numbers {
        width: 2.5rem;
        height: 2.5rem; } }
    .page-numbers > li > .page-numbers::after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: #F5F7F7;
      transition: all 0.4s ease;
      z-index: 1;
      pointer-events: none; }
    .page-numbers > li > .page-numbers:after {
      z-index: -1; }
    @media (hover: hover) {
      .page-numbers > li > .page-numbers:hover:not(.dot) {
        color: var(--color-text); }
        .page-numbers > li > .page-numbers:hover:not(.dot)::after {
          left: 0;
          width: 100%; } }
    .page-numbers > li > .page-numbers.current {
      color: var(--color-text);
      font-weight: 600; }
      .page-numbers > li > .page-numbers.current::after {
        left: 0;
        width: 100%; }
    .page-numbers > li > .page-numbers.dot {
      background-color: transparent; }
    .page-numbers > li > .page-numbers i {
      font-weight: 400; }

.pro-item-2 {
  width: calc(100% / 2); }

.pro-item-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 500px) {
    .pro-item-3 {
      width: calc(100% / 2); } }

.pro-item-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 500px) {
    .pro-item-4 {
      width: calc(100% / 2); } }

.pro-item-5 {
  width: calc(100% / 5); }
  @media screen and (max-width: 500px) {
    .pro-item-5 {
      width: calc(100% / 2); } }

.prose-item-2 {
  width: calc(100% / 2); }

.prose-item-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 500px) {
    .prose-item-3 {
      width: calc(100% / 2); } }

.prose-item-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 500px) {
    .prose-item-4 {
      width: calc(100% / 2); } }

.prose-item-5 {
  width: calc(100% / 5); }
  @media screen and (max-width: 500px) {
    .prose-item-5 {
      width: calc(100% / 2); } }

.proth-item-2 {
  width: calc(100% / 2); }

.proth-item-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 500px) {
    .proth-item-3 {
      width: calc(100% / 2); } }

.proth-item-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 500px) {
    .proth-item-4 {
      width: calc(100% / 2); } }

.proth-item-5 {
  width: calc(100% / 5); }
  @media screen and (max-width: 500px) {
    .proth-item-5 {
      width: calc(100% / 2); } }

@media screen and (max-width: 1200px) {
  .search-mona {
    position: fixed;
    top: 6rem;
    right: 1.5rem;
    left: 1.5rem;
    z-index: 101;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }
    .search-mona.open {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0); } }

.search-mona-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  @media screen and (min-width: 1201px) {
    .search-mona-overlay {
      display: none; } }
  .search-mona-overlay.open {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0); }

.search-mona-wrapper {
  position: relative; }

.search-mona-close {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  cursor: pointer;
  width: 3.4rem;
  height: 3.4rem;
  bottom: calc(100% + 0.5rem);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border: 0.2rem solid white;
  border-radius: 100rem;
  z-index: 101; }
  @media screen and (min-width: 1201px) {
    .search-mona-close {
      display: none; } }
  .search-mona-close i {
    color: white;
    font-size: 2.4rem;
    font-weight: 300; }

.search-wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: stretch;
  min-width: 250px;
  background-color: #F2F2F2;
  border-radius: 0.8rem; }
  @media screen and (max-width: 1200px) {
    .search-wrap {
      background-color: transparent;
      border-bottom: 1px solid white;
      border-radius: 0; } }

.search-input {
  font-family: "Roboto", sans-serif;
  outline: none;
  border: none;
  font-size: 1.6rem;
  color: var(--color-text);
  flex: 1;
  height: 4rem;
  padding: 0 1.2rem;
  background-color: transparent; }
  @media only screen and (max-width: 425px) {
    .search-input {
      font-size: 1.4rem; } }
  @media screen and (max-width: 1200px) {
    .search-input {
      color: white; } }
  .search-input::-webkit-input-placeholder {
    color: var(--color-text); }
    @media screen and (max-width: 1200px) {
      .search-input::-webkit-input-placeholder {
        color: white; } }
  .search-input::-moz-input-placeholder {
    color: var(--color-text); }
    @media screen and (max-width: 1200px) {
      .search-input::-moz-input-placeholder {
        color: white; } }

.search-btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-shrink: 0;
  width: 4rem;
  background-color: transparent;
  cursor: pointer; }
  .search-btn:hover img {
    transform: scale(1.05); }
  .search-btn img {
    width: 1.6rem;
    height: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .search-btn img {
        filter: brightness(0) invert(1); } }

.f-14 {
  font-size: 1.4rem; }
  @media only screen and (max-width: 600px) {
    .f-14 {
      font-size: 1.2rem; } }

.f-16 {
  font-size: 1.6rem; }
  @media only screen and (max-width: 425px) {
    .f-16 {
      font-size: 1.4rem; } }

.f-18 {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-18 {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-18 {
      font-size: 1.4rem; } }

.f-20 {
  font-size: 2rem; }
  @media only screen and (max-width: 1200px) {
    .f-20 {
      font-size: 1.8rem; } }

.f-22 {
  font-size: 2.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-22 {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .f-22 {
      font-size: 1.9rem; } }

.f-24 {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-24 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-24 {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 425px) {
    .f-24 {
      font-size: 1.6rem; } }

.f-26 {
  font-size: 2.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-26 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-26 {
      font-size: 1.6rem; } }

.f-28 {
  font-size: 2.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-28 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-28 {
      font-size: 1.8rem; } }

.f-30 {
  font-size: 3rem; }
  @media only screen and (max-width: 1200px) {
    .f-30 {
      font-size: 2rem; } }

.f-32 {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-32 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-32 {
      font-size: 2rem; } }

.f-34 {
  font-size: 3.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-34 {
      font-size: 2.4rem; } }

.f-36 {
  font-size: 3.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-36 {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-36 {
      font-size: 2rem; } }

.f-38 {
  font-size: 3.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-38 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-38 {
      font-size: 2.4rem; } }

.f-40 {
  font-size: 4rem; }
  @media only screen and (max-width: 1200px) {
    .f-40 {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-40 {
      font-size: 2.4rem; } }

.f-42 {
  font-size: 4.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-42 {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-42 {
      font-size: 2.6rem; } }

.f-44 {
  font-size: 4.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-44 {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-44 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-44 {
      font-size: 1.4rem; } }

.f-46 {
  font-size: 4.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-46 {
      font-size: 3.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-46 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-46 {
      font-size: 1.6rem; } }

.f-48 {
  font-size: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-48 {
      font-size: 3.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-48 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 300px) {
    .f-48 {
      font-size: 2.4rem; } }

.f-50 {
  font-size: 5rem; }
  @media only screen and (max-width: 1200px) {
    .f-50 {
      font-size: 4rem; } }
  @media only screen and (max-width: 800px) {
    .f-50 {
      font-size: 3rem; } }
  @media only screen and (max-width: 600px) {
    .f-50 {
      font-size: 2rem; } }

.f-52 {
  font-size: 5.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-52 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-52 {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-52 {
      font-size: 2.2rem; } }

.f-54 {
  font-size: 5.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-54 {
      font-size: 4.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-54 {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-54 {
      font-size: 2.4rem; } }

.f-56 {
  font-size: 5.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-56 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-56 {
      font-size: 3.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-56 {
      font-size: 2.6rem; } }

.f-58 {
  font-size: 5.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-58 {
      font-size: 4.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-58 {
      font-size: 3.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-58 {
      font-size: 2.8rem; } }

.f-60 {
  font-size: 6rem; }
  @media only screen and (max-width: 1200px) {
    .f-60 {
      font-size: 5.5rem; } }
  @media only screen and (max-width: 800px) {
    .f-60 {
      font-size: 4.5rem; } }
  @media only screen and (max-width: 600px) {
    .f-60 {
      font-size: 3.5rem; } }

.f-62 {
  font-size: 6.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-62 {
      font-size: 5.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-62 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-62 {
      font-size: 3.2rem; } }

.f-64 {
  font-size: 6.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-64 {
      font-size: 5.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-64 {
      font-size: 4.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-64 {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 460px) {
    .f-64 {
      font-size: 3rem; } }

.f-66 {
  font-size: 6.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-66 {
      font-size: 5.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-66 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-66 {
      font-size: 3.6rem; } }

.f-68 {
  font-size: 6.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-68 {
      font-size: 5.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-68 {
      font-size: 4.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-68 {
      font-size: 3.8rem; } }

.f-70 {
  font-size: 7rem; }
  @media only screen and (max-width: 1200px) {
    .f-70 {
      font-size: 6rem; } }
  @media only screen and (max-width: 800px) {
    .f-70 {
      font-size: 5rem; } }
  @media only screen and (max-width: 600px) {
    .f-70 {
      font-size: 4rem; } }

.f-72 {
  font-size: 7.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-72 {
      font-size: 6.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-72 {
      font-size: 5.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-72 {
      font-size: 4.2rem; } }

.f-74 {
  font-size: 7.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-74 {
      font-size: 6.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-74 {
      font-size: 5.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-74 {
      font-size: 4.4rem; } }

.f-76 {
  font-size: 7.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-76 {
      font-size: 5.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-76 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-76 {
      font-size: 3.6rem; } }

.f-78 {
  font-size: 7.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-78 {
      font-size: 5.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-78 {
      font-size: 4.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-78 {
      font-size: 3.8rem; } }

.f-80 {
  font-size: 8rem; }
  @media only screen and (max-width: 1200px) {
    .f-80 {
      font-size: 5rem; } }
  @media only screen and (max-width: 800px) {
    .f-80 {
      font-size: 4.5rem; } }
  @media only screen and (max-width: 600px) {
    .f-80 {
      font-size: 4rem;
      line-height: 1.5 !important; } }
  @media only screen and (max-width: 460px) {
    .f-80 {
      font-size: 3rem;
      line-height: 1.5 !important; } }

.f-82 {
  font-size: 8.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-82 {
      font-size: 7.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-82 {
      font-size: 6.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-82 {
      font-size: 5.2rem; } }

.f-84 {
  font-size: 8.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-84 {
      font-size: 7.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-84 {
      font-size: 6.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-84 {
      font-size: 5.4rem; } }

.f-86 {
  font-size: 8.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-86 {
      font-size: 7.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-86 {
      font-size: 6.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-86 {
      font-size: 5.6rem; } }

.f-88 {
  font-size: 8.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-88 {
      font-size: 7.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-88 {
      font-size: 6.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-88 {
      font-size: 5.8rem; } }

.f-90 {
  font-size: 9rem; }
  @media only screen and (max-width: 1200px) {
    .f-90 {
      font-size: 8rem; } }
  @media only screen and (max-width: 800px) {
    .f-90 {
      font-size: 7rem; } }
  @media only screen and (max-width: 600px) {
    .f-90 {
      font-size: 6rem; } }

.f-92 {
  font-size: 9.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-92 {
      font-size: 8.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-92 {
      font-size: 7.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-92 {
      font-size: 6.2rem; } }

.f-94 {
  font-size: 9.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-94 {
      font-size: 8.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-94 {
      font-size: 7.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-94 {
      font-size: 6.4rem; } }

.f-96 {
  font-size: 9.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-96 {
      font-size: 8.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-96 {
      font-size: 7.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-96 {
      font-size: 6.6rem; } }
  @media only screen and (max-width: 500px) {
    .f-96 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 400px) {
    .f-96 {
      font-size: 3.6rem; } }

.f-98 {
  font-size: 9.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-98 {
      font-size: 8.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-98 {
      font-size: 7.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-98 {
      font-size: 6.8rem; } }

.f-100 {
  font-size: 10rem; }
  @media only screen and (max-width: 1200px) {
    .f-100 {
      font-size: 9rem; } }
  @media only screen and (max-width: 800px) {
    .f-100 {
      font-size: 8rem; } }
  @media only screen and (max-width: 600px) {
    .f-100 {
      font-size: 7rem; } }
  @media only screen and (max-width: 460px) {
    .f-100 {
      font-size: 6rem; } }

.f-120 {
  font-size: 12rem; }
  @media only screen and (max-width: 1200px) {
    .f-120 {
      font-size: 11rem; } }
  @media only screen and (max-width: 800px) {
    .f-120 {
      font-size: 10rem; } }
  @media only screen and (max-width: 600px) {
    .f-120 {
      font-size: 9rem; } }
  @media only screen and (max-width: 460px) {
    .f-120 {
      font-size: 7rem; } }

.f-140 {
  font-size: 14rem; }
  @media only screen and (max-width: 1200px) {
    .f-140 {
      font-size: 13rem; } }
  @media only screen and (max-width: 800px) {
    .f-140 {
      font-size: 12rem; } }
  @media only screen and (max-width: 600px) {
    .f-140 {
      font-size: 10rem; } }
  @media only screen and (max-width: 460px) {
    .f-140 {
      font-size: 6rem; } }

.f-160 {
  font-size: 16rem; }
  @media only screen and (max-width: 1200px) {
    .f-160 {
      font-size: 14rem; } }
  @media only screen and (max-width: 800px) {
    .f-160 {
      font-size: 14rem; } }
  @media only screen and (max-width: 600px) {
    .f-160 {
      font-size: 13rem; } }
  @media only screen and (max-width: 460px) {
    .f-160 {
      font-size: 8rem; } }

.f-180 {
  font-size: 18rem; }
  @media only screen and (max-width: 1200px) {
    .f-180 {
      font-size: 16rem; } }
  @media only screen and (max-width: 800px) {
    .f-180 {
      font-size: 15rem; } }
  @media only screen and (max-width: 600px) {
    .f-180 {
      font-size: 14rem; } }
  @media only screen and (max-width: 460px) {
    .f-180 {
      font-size: 9rem; } }

.f-200 {
  font-size: 20rem; }
  @media only screen and (max-width: 1200px) {
    .f-200 {
      font-size: 16rem; } }
  @media only screen and (max-width: 800px) {
    .f-200 {
      font-size: 13rem; } }
  @media only screen and (max-width: 600px) {
    .f-200 {
      font-size: 10rem; } }
  @media only screen and (max-width: 460px) {
    .f-200 {
      font-size: 8rem; } }

.wrap-text {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  .wrap-text .icon {
    width: 3.2rem;
    display: block;
    margin-right: 1.2rem; }

.t-white {
  color: white; }

.t-main {
  color: var(--color-pri); }

.t-second {
  color: var(--color-second); }

.t-third {
  color: #408630; }

.t-text-2 {
  color: #040404; }

.t-pink {
  color: #ed907a; }

.t-1D1E41 {
  color: #1d1e41; }

.t-underline {
  text-decoration: underline; }

.t-line {
  position: relative;
  display: inline-block; }
  .t-line::before {
    position: absolute;
    content: "";
    min-width: 48rem;
    width: calc(100% + 3rem);
    height: 2.8rem;
    background: var(--color-pri);
    bottom: 0%;
    transform: translateY(0%);
    z-index: -1; }
    @media screen and (max-width: 1200px) {
      .t-line::before {
        min-width: 32rem;
        height: 1.4rem; } }
    @media screen and (max-width: 800px) {
      .t-line::before {
        min-width: 26rem;
        height: 1rem; } }
  .t-line.right::before {
    right: 0; }
  .t-line.left::before {
    left: 0; }
  .t-line.line-2 .text::before {
    bottom: 0.8rem;
    top: auto;
    transform: none;
    background: #d9d9d9;
    height: 0.2rem; }
  .t-line.short {
    margin-top: 0; }
    .t-line.short::before, .t-line.short::after {
      position: absolute;
      content: "";
      width: 6.6rem;
      height: 0.1rem;
      background: var(--color-pri);
      top: 50%;
      transform: translateY(-50%); }
    .t-line.short::before {
      right: calc(100% + 0.9rem); }
    .t-line.short::after {
      left: calc(100% + 0.9rem); }
  .t-line-db {
    position: relative;
    padding-bottom: 1rem; }
    .t-line-db::before, .t-line-db:after {
      position: absolute;
      content: "";
      width: 5rem;
      height: 0.2rem;
      background: var(--color-second);
      left: 50%; }
    .t-line-db::before {
      bottom: 0;
      transform: translateX(-10%); }
    .t-line-db::after {
      bottom: 0.5rem;
      transform: translateX(-60%); }
    .t-line-db.line-db-2::after {
      left: 0;
      transform: none; }
    .t-line-db.line-db-2::before {
      left: 0;
      transform: translateX(50%); }

.t-break {
  white-space: pre-line; }

.t-stroke {
  text-shadow: 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem black, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white;
  -webkit-font-smoothing: antialiased;
  color: var(--color-second); }

.t-pre-line {
  white-space: pre-line; }

.t-sp-1 {
  letter-spacing: 0.06em; }

.t-sd-1 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.t-font-2 {
  font-family: "SVN-Taken by Vultures"; }

.t-pre-lỉne {
  white-space: pre-line; }

.t-linear {
  background: linear-gradient(90deg, #39b16c 2.67%, #38af6b 3.67%, #255361 71.67%, #1e2f5e 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.text-line {
  padding-bottom: 2rem;
  position: relative; }
  .text-line::before {
    position: absolute;
    content: "";
    height: 0.1rem;
    width: 12rem;
    background: linear-gradient(0deg, #0eb252 47.37%, #72c595 102.98%);
    opacity: 0.5;
    bottom: 0;
    left: 0; }
    @media screen and (max-width: 450px) {
      .text-line::before {
        left: 50%;
        transform: translateX(-50%); } }

.fw-1 {
  font-weight: 100; }

.fw-2 {
  font-weight: 200; }

.fw-4 {
  font-weight: 400; }

.fw-3 {
  font-weight: 300; }

.fw-5 {
  font-weight: 500; }

.fw-6 {
  font-weight: 600; }

.fw-7 {
  font-weight: 700; }

.fw-8 {
  font-weight: 800; }

.fw-b {
  font-weight: bold; }

.fw-i {
  font-style: italic; }

.t-upper {
  text-transform: uppercase; }

.t-non-upper {
  text-transform: unset; }

.t-center {
  text-align: center; }

.tt-main {
  color: var(--color-text);
  font-weight: 600; }

.c-pri {
  color: var(--color-pri); }

.c-second {
  color: var(--color-second); }

.c-third {
  color: #408630; }

.c-four {
  color: #FCB445; }

.c-five {
  color: #f05123; }

.c-white {
  color: white; }

.c-black {
  color: #000000; }

.c-txt {
  color: var(--color-text); }

.c-txt-s {
  color: var(--color-text-second); }

.bc-pri {
  background-color: var(--color-pri); }

.bc-second {
  background-color: var(--color-second); }

.bc-third {
  background-color: #408630; }

.bc-four {
  background-color: #FCB445; }

.bc-five {
  background-color: #f05123; }

.over-hd {
  overflow: hidden; }

.z-i-1 {
  z-index: 1; }

.z-i-2 {
  z-index: 2; }

.z-i-3 {
  z-index: 3; }

.z-i-4 {
  z-index: 4; }

.z-i-5 {
  z-index: 5; }

.m-mid {
  margin: 0 auto; }

.m-end {
  margin-left: auto; }

.m-top {
  margin-top: 2.4rem; }

.m-start {
  margin-right: auto; }

.f-mid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }

.f-end {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end; }

.lg-download, .lg-autoplay-button, .lg-fullscreen, #lg-zoom-in, #lg-zoom-out
, #lg-actual-size, #lg-share, .lg-flip-ver, .lg-flip-hor, .lg-rotate-left, .lg-rotate-right {
  display: none; }

.tabPanel {
  display: none; }

.tabBtn {
  cursor: pointer; }

.slider {
  position: relative; }

.relative {
  position: relative; }

.hv-bd {
  position: relative; }
  .hv-bd::before {
    content: '';
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    border: 0.1rem solid white;
    z-index: 1;
    transition: .3s all ease-in-out;
    transform: scale(1.1);
    opacity: 0;
    border-radius: 1rem; }
  .hv-bd img {
    transition: .3s all ease-in-out; }
  @media (hover: hover) {
    .hv-bd:hover::before {
      opacity: 1;
      transform: scale(1); }
    .hv-bd:hover img {
      transform: translate(-50%, -50%) scale(1.05);
      -webkit-transform: translate(-50%, -50%) scale(1.05);
      -moz-transform: translate(-50%, -50%) scale(1.05);
      -o-transform: translate(-50%, -50%) scale(1.05); } }

.gItem {
  cursor: pointer; }

.parallaxJS img {
  position: relative; }

@media screen and (min-width: 1201px) {
  .hidden-desk {
    display: none !important; } }

@media screen and (max-width: 1200px) {
  .hidden-mobile {
    display: none !important; } }

.full {
  width: 100%; }

.wp-block-image img {
  display: block;
  margin: 0 auto; }

.calendar-table td {
  width: 3.2rem !important;
  height: 3.2rem !important;
  border: 0.4rem solid white !important;
  border-radius: 1.2rem !important; }

.calendar-table td.today {
  background: #DFE4EA;
  color: var(--color-pri); }

.calendar-table td.active {
  background: var(--color-pri);
  color: white; }
  @media (hover: hover) {
    .calendar-table td.active:hover {
      background: var(--color-pri); } }

.calendar-table th.next {
  background: #DFE4EA;
  width: 3.2rem !important;
  height: 3.2rem !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .calendar-table th.next span {
    transform: none !important;
    border: none !important; }
    .calendar-table th.next span::after {
      content: "\f054";
      font-family: "Font Awesome 6 Pro";
      color: var(--color-pri); }

.calendar-table th.prev {
  background: #DFE4EA;
  width: 3.2rem !important;
  height: 3.2rem !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .calendar-table th.prev span {
    transform: none !important;
    border: none !important; }
    .calendar-table th.prev span::after {
      content: "\f053";
      font-family: "Font Awesome 6 Pro";
      color: var(--color-pri); }

.daterangepicker {
  border: none;
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  border-radius: 1rem; }
  .daterangepicker::before, .daterangepicker::after {
    content: none; }

.star {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  .star-flex {
    gap: 0.3rem; }
  .star .star-list {
    height: unset; }
  .star .star-empty i {
    color: #dedede;
    font-size: 1.4rem; }
  .star .star-filter i {
    color: #FFC107;
    font-size: 1.4rem; }

.rating {
  display: flex;
  gap: 0.5rem; }
  .rating .stars {
    cursor: pointer; }
    .rating .stars i {
      color: #FCC21F; }
    .rating .stars.active i {
      color: #FCC21F;
      font-weight: 900; }
  .rating .star {
    width: 2rem;
    height: 2rem;
    background: gray;
    display: block; }

.form {
  --height:4.4rem;
  --bdr:0.8rem;
  --bdcl:var(--color-pri); }
  .form-list {
    --cg: 2.4rem;
    --rg: 2.4rem; }
    @media screen and (max-width: 1200px) {
      .form-list {
        --cg: 1.2rem;
        --rg: 1.2rem; } }
  .form-ip .text {
    display: block;
    margin-bottom: 1rem;
    color: var(--color-pri);
    font-weight: 500; }
    .form-ip .text .red {
      color: red;
      font-weight: 400; }
  .form-ip .input {
    position: relative; }
    .form-ip .input .seepassJS {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: #dedede;
      cursor: pointer; }
  .form-ip input,
  .form-ip textarea {
    border: 0.1rem solid var(--color-pri);
    border-radius: 0.8rem;
    outline: none;
    padding: 1.3rem 2rem;
    width: 100%;
    background-color: transparent;
    transition: 0.4s;
    font-size: 1.6rem;
    height: 4.4rem; }
    .form-ip input.placeholder,
    .form-ip textarea.placeholder {
      color: #64748B; }
    .form-ip input:-moz-placeholder,
    .form-ip textarea:-moz-placeholder {
      color: #64748B; }
    .form-ip input::-moz-placeholder,
    .form-ip textarea::-moz-placeholder {
      color: #64748B; }
    .form-ip input:-ms-input-placeholder,
    .form-ip textarea:-ms-input-placeholder {
      color: #64748B; }
    .form-ip input::-webkit-input-placeholder,
    .form-ip textarea::-webkit-input-placeholder {
      color: #64748B; }
    .form-ip input:focus,
    .form-ip textarea:focus {
      border-color: var(--color-pri); }
  .form-ip textarea {
    min-height: 8.4rem; }
  .form-ip.per5 {
    width: 50%; }
    @media screen and (max-width: 500px) {
      .form-ip.per5 {
        width: 100%; } }
  .form-ip .select2-container--default .select2-selection--single {
    border-radius: 0.8rem;
    background-color: transparent;
    border-color: var(--color-pri); }
  .form-ip .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #64748B;
    font-size: 1.6rem; }
  .form-ip .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-radius: 0.8rem !important; }
  .form-ip .select2-container .select2-selection--single .select2-selection__rendered {
    font-weight: 400;
    font-size: 1.6rem; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-2 {
  margin-bottom: 0.2rem; }

.mb-4 {
  margin-bottom: 0.4rem; }

.mb-6 {
  margin-bottom: 0.6rem; }

.mb-8 {
  margin-bottom: 0.8rem; }

.mb-8s:not(:last-child) {
  margin-bottom: 0.8rem; }

.mb-10 {
  margin-bottom: 1rem; }

.mb-10s:not(:last-child) {
  margin-bottom: 1rem; }

.mb-12 {
  margin-bottom: 1.2rem; }

.mb-12s:not(:last-child) {
  margin-bottom: 1.2rem; }

.mb-14 {
  margin-bottom: 1.4rem; }

.mb-14s:not(:last-child) {
  margin-bottom: 1.4rem; }

.mb-15 {
  margin-bottom: 1.5rem; }

.mb-15s:not(:last-child) {
  margin-bottom: 1.5rem; }

.mb-16 {
  margin-bottom: 1.6rem; }

.mb-16s:not(:last-child) {
  margin-bottom: 1.6rem; }

.mb-18 {
  margin-bottom: 1.8rem; }

.mb-18s:not(:last-child) {
  margin-bottom: 1.8rem; }

.mb-20 {
  margin-bottom: 2rem; }

.mb-20s:not(:last-child) {
  margin-bottom: 2rem; }

.mb-24 {
  margin-bottom: 2.4rem; }

.mb-24s:not(:last-child) {
  margin-bottom: 2.4rem; }

.mb-25 {
  margin-bottom: 2.5rem; }

.mb-25s:not(:last-child) {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 3rem; }

.mb-30s:not(:last-child) {
  margin-bottom: 3rem; }

.mb-32 {
  margin-bottom: 3.2rem; }

.mb-32s:not(:last-child) {
  margin-bottom: 3.2rem; }

.mb-40 {
  margin-bottom: 4rem; }

.mb-40s:not(:last-child) {
  margin-bottom: 4rem; }

.mb-48 {
  margin-bottom: 4.8rem; }

.mb-48s:not(:last-child) {
  margin-bottom: 4.8rem; }

.mb-56 {
  margin-bottom: 5.6rem; }

.mb-56s:not(:last-child) {
  margin-bottom: 5.6rem; }

.mb-64 {
  margin-bottom: 6.4rem; }

.mb-64s:not(:last-child) {
  margin-bottom: 6.4rem; }

.mb-72 {
  margin-bottom: 7.2rem; }

.mb-72s:not(:last-child) {
  margin-bottom: 7.2rem; }

.mb-80 {
  margin-bottom: 8rem; }

.mb-80s:not(:last-child) {
  margin-bottom: 8rem; }

.ss-mg {
  margin: var(--pd-sc) 0; }

.ss-mg-t {
  margin-top: var(--pd-sc); }

.ss-mg-b {
  margin-bottom: var(--pd-sc); }

.ss-pd {
  padding: var(--pd-sc) 0; }

.ss-pd-t {
  padding-top: var(--pd-sc); }

.ss-pd-b {
  padding-bottom: var(--pd-sc); }

.recheck-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .recheck-item.active .recheck-checkbox::before {
    opacity: 1; }
  .recheck-item.active .recheck-radio {
    border: 0.1rem solid var(--color-pri); }
    .recheck-item.active .recheck-radio::before {
      opacity: 1; }

.recheck-checkbox {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  background-color: transparent;
  border: 1px solid var(--color-pri);
  position: relative;
  flex-shrink: 0; }
  .recheck-checkbox::before {
    content: "";
    position: absolute;
    left: -0.2rem;
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    background-image: url(../assets/images/tick.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-size: cover;
    transition: 0.1s ease-in-out;
    opacity: 0; }

.recheck-radio {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100rem;
  border: 0.1rem solid var(--color-text-second);
  flex-shrink: 0;
  position: relative; }
  .recheck-radio::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100rem;
    background-color: var(--color-pri);
    opacity: 0; }

.recheck-method {
  width: 4.8rem;
  height: 4.8rem;
  border: 0.1rem solid #EBEBEB;
  border-radius: 0.8rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-left: 0.8rem; }
  .recheck-method img {
    width: 2.4rem;
    height: 2.4rem; }

.recheck-text {
  margin-left: 0.8rem;
  line-height: 1.1;
  color: var(--color-text); }

.star {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-content: start; }
  .star-list {
    position: relative;
    height: 2rem; }
  .star-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    .star-flex .icon {
      width: 1.8rem;
      height: 1.8rem; }
      .star-flex .icon:not(:first-child) {
        margin-left: 0.4rem; }
  .star-filter {
    overflow: hidden;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 0; }

@media screen and (max-width: 1200px) {
  .side-fixed {
    position: fixed;
    width: 320px;
    max-width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #FFF;
    z-index: 51;
    transition: 0.4s ease-in-out;
    opacity: 0;
    transform: translateX(110%);
    visibility: hidden; }
    .side-fixed.open {
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible; } }

@media screen and (max-width: 1200px) {
  .side-fixed-wrap {
    padding: 5.5rem 1.5rem 1.5rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto; } }

.side-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
  transition: 0.5s ease-in-out;
  opacity: 0;
  transform: translateX(110%);
  visibility: hidden; }
  .side-overlay.open {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible; }
  @media screen and (min-width: 1201px) {
    .side-overlay {
      display: none; } }

.side-open:not(.no-style) {
  width: 4rem;
  height: 4rem;
  position: fixed;
  right: 0;
  z-index: 40;
  background-color: var(--color-pri);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 100rem 0 0 100rem;
  cursor: pointer;
  transition: 0.4s ease-in-out; }
  .side-open:not(.no-style).close {
    opacity: 0;
    transform: translate(100%, -50%);
    visibility: visible; }
  @media screen and (min-width: 1201px) {
    .side-open:not(.no-style) {
      display: none; } }
  .side-open:not(.no-style)-wrap {
    width: 3rem;
    height: 3rem;
    border-radius: 100rem;
    background-color: white;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .side-open:not(.no-style)-wrap .icon {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.6rem; }

.side-open-wrap .icon {
  color: #ffffff; }

.side-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.5rem;
  padding: 0 0 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: var(--color-second); }
  @media screen and (min-width: 1201px) {
    .side-top {
      display: none; } }
  .side-top .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.side-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  background-color: var(--color-pri);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 0.4rem; }
  .side-close .icon {
    font-size: 1.8rem;
    font-weight: 400;
    transition: 0.6s ease-in-out;
    color: #ffffff; }
  @media screen and (min-width: 1201px) {
    .side-close {
      display: none; } }

.social-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  gap: 2.4rem; }

.social-link {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.3s ease-in-out;
  cursor: pointer; }
  .social-link:hover {
    transform: translateY(-5px); }
  .social-link img {
    width: 100%;
    display: block; }

.cmini {
  position: absolute;
  top: calc(100% + 1.2rem);
  right: 0;
  border-radius: 1.2rem;
  overflow: hidden;
  background-color: #F2FBFF;
  z-index: 2;
  min-width: 38rem;
  max-width: 38rem;
  min-height: 38rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 1rem);
  pointer-events: none;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  flex-direction: column; }
  @media screen and (max-width: 500px) {
    .cmini {
      right: -3.5em; } }
  @media screen and (max-width: 400px) {
    .cmini {
      min-width: 30rem;
      max-width: 30rem;
      min-height: 30rem; } }
  .cmini-main {
    padding: 2.4rem; }
    @media screen and (max-width: 500px) {
      .cmini-main {
        padding: 1.6rem; } }
  .cmini-head {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1.6rem; }
    .cmini-head .title {
      color: var(--color-text);
      font-weight: 600; }
  .cmini-body {
    max-height: 35.5vh;
    overflow-y: auto;
    margin-right: -1.2rem;
    padding-right: 1.2rem;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .cmini-body::-webkit-scrollbar {
      width: 0.3rem;
      border-radius: 1rem; }
    .cmini-body::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .cmini-body::-webkit-scrollbar-thumb {
      background: var(--color-pri);
      border-radius: 1rem; }
    .cmini-body::-webkit-scrollbar-thumb:hover {
      background: var(--color-pri); }
  .cmini-wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .cmini-img {
    display: block;
    width: 8rem;
    height: 8rem;
    border-radius: 1.2rem;
    overflow: hidden;
    flex-shrink: 0;
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    border: 1px solid transparent; }
    @media screen and (min-width: 1201px) {
      .cmini-img:hover {
        border: 1px solid var(--color-second); } }
    .cmini-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .cmini-content {
    margin-left: 1.6rem;
    flex: 1; }
  .cmini-name {
    color: var(--color-text);
    font-weight: 500;
    margin-bottom: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    @media screen and (min-width: 1201px) {
      .cmini-name:hover {
        color: var(--color-second); } }
  .cmini-fl {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .cmini-sl {
    color: var(--color-text); }
  .cmini-price {
    color: var(--color-second);
    font-weight: 500; }
  .cmini-total {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;
    padding-top: 0; }
    @media screen and (max-width: 500px) {
      .cmini-total {
        padding: 0 1.6rem 1.6rem 1.6rem; } }
    .cmini-total .title {
      font-weight: 600; }
    .cmini-total .cmini-price {
      font-size: 1.8rem; }
  .cmini-action-btn {
    display: block;
    padding: 1.2rem;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    background-color: var(--color-pri);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    @media screen and (min-width: 1201px) {
      .cmini-action-btn:hover {
        opacity: 0.6; } }
    @media screen and (max-width: 500px) {
      .cmini-action-btn {
        padding: 0.8rem; } }
    .cmini-action-btn.second {
      background-color: var(--color-second); }
  .cmini-no-cart {
    text-align: center;
    width: 100%;
    min-height: inherit;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column; }
    .cmini-no-cart img {
      width: 6rem;
      height: 6rem; }
    .cmini-no-cart .text {
      margin-top: 1.6rem; }

.c.second .c-head {
  border: none; }

.c.second .c-row {
  padding: 0; }

@media screen and (max-width: 1200px) {
  .c.second .c-col-first {
    width: 80%; } }

@media screen and (max-width: 768px) {
  .c.second .c-col-first {
    width: 100%; } }

@media screen and (max-width: 1200px) {
  .c.second .c-col-second {
    width: 20%; } }

@media screen and (max-width: 768px) {
  .c.second .c-col-second {
    width: 100%; } }

@media screen and (max-width: 1200px) {
  .c.second .c-col-third {
    width: 30%; } }

@media screen and (max-width: 768px) {
  .c.second .c-col-third {
    width: 100%; } }

.c.second .c-col-four {
  width: calc(100%*(1/12)); }
  @media screen and (max-width: 1200px) {
    .c.second .c-col-four {
      width: 30%; } }
  @media screen and (max-width: 768px) {
    .c.second .c-col-four {
      width: 100%; } }

.c.second .c-col-five {
  width: calc(100%*(2/12)); }
  @media screen and (max-width: 1200px) {
    .c.second .c-col-five {
      width: 30%; } }

.c.second .c-item {
  background-color: #FAFAFA;
  border: none;
  padding: 1.2rem;
  border-radius: 0.8rem; }
  .c.second .c-item:not(:last-child) {
    margin-bottom: 1.6rem; }

.c.second .c-delete {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 0.5rem; }
  @media screen and (max-width: 1200px) {
    .c.second .c-delete {
      top: initial;
      bottom: 0.5rem;
      right: 1rem; } }

.c.second .c-price .price {
  font-size: 1.6rem;
  color: var(--color-second); }
  .c.second .c-price .price.old {
    color: #B3B3B3; }

@media screen and (max-width: 375px) {
  .c .count {
    justify-content: space-between;
    border: 0.1rem solid #EBEBEB;
    padding: 0.8rem;
    border-radius: 0.8rem; } }

.c .count-btn {
  border: 1px solid #999999; }
  @media screen and (min-width: 1201px) {
    .c .count-btn:hover {
      background-color: white; } }

.c-item {
  position: relative; }

.c-row {
  justify-content: space-between; }

.c-col-first {
  width: calc(100%*(5/12)); }
  @media screen and (max-width: 768px) {
    .c-col-first {
      width: calc(100%*(9/12)); } }
  @media screen and (max-width: 375px) {
    .c-col-first {
      width: 100%; } }

.c-col-second {
  width: calc(100%*(2/12)); }
  @media screen and (max-width: 768px) {
    .c-col-second {
      width: calc(100%*(3/12)); } }
  @media screen and (max-width: 375px) {
    .c-col-second {
      width: 100%; } }

.c-col-third {
  width: calc(100%*(2/12)); }
  @media screen and (max-width: 768px) {
    .c-col-third {
      width: calc(100%*(5/12)); } }
  @media screen and (max-width: 375px) {
    .c-col-third {
      width: 100%; } }

.c-col-four {
  width: calc(100%*(2/12)); }
  @media screen and (max-width: 768px) {
    .c-col-four {
      width: calc(100%*(5/12)); } }
  @media screen and (max-width: 375px) {
    .c-col-four {
      width: 90%; } }

.c-col-five {
  width: calc(100%*(1/12)); }
  @media screen and (max-width: 768px) {
    .c-col-five {
      width: calc(100%*(2/12)); } }
  @media screen and (max-width: 375px) {
    .c-col-five {
      width: 10%; } }

.c-head {
  border-bottom: 0.1rem solid #EBEBEB;
  padding-bottom: 1.6rem; }
  @media screen and (max-width: 375px) {
    .c-head .c-col-first {
      width: 50%; } }
  @media screen and (max-width: 768px) {
    .c-head .c-col-second {
      display: none;
      text-align: right; } }
  @media screen and (max-width: 375px) {
    .c-head .c-col-second {
      width: 50%; } }
  @media screen and (max-width: 768px) {
    .c-head .c-col-third {
      display: none; } }
  @media screen and (max-width: 768px) {
    .c-head .c-col-four {
      display: none; } }
  @media screen and (max-width: 768px) {
    .c-head .c-col-five {
      display: none; } }

.c-title {
  color: #1A1A1A;
  font-weight: 700; }

.c-product {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  .c-product-img {
    width: 7.8rem;
    height: 7.8rem;
    border-radius: 0.8rem;
    overflow: hidden;
    flex-shrink: 0; }
    .c-product-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .c-product-content {
    margin-left: 0.8rem; }
    .c-product-content .name {
      color: #1A1A1A;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media screen and (max-width: 500px) {
        .c-product-content .name {
          font-size: 1.4rem; } }
      .c-product-content .name:hover {
        color: var(--color-second); }

.c-body .c-item {
  border-bottom: 0.1rem solid #EBEBEB; }

.c-body .c-row {
  padding: 1.6rem 0;
  align-items: center;
  position: relative; }

.c-price {
  width: fit-content;
  text-align: right; }
  @media screen and (max-width: 768px) {
    .c-price {
      width: 100%; } }
  .c-price .price {
    color: var(--color-text); }
    .c-price .price.old {
      font-size: 1.2rem;
      color: var(--color-text);
      text-decoration: line-through; }
  .c-price-total {
    color: var(--color-second); }

.c-delete {
  text-align: end;
  cursor: pointer;
  display: block; }
  @media screen and (min-width: 1201px) {
    .c-delete:hover i {
      transform: rotate(360deg); } }
  .c-delete i {
    font-size: 2.4rem;
    font-weight: 300;
    transition: 0.6s ease-in-out;
    display: inline-block; }

.c-all {
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid #EBEBEB; }
  .c-all-wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: auto; }
    @media screen and (max-width: 768px) {
      .c-all-wrap {
        width: 100%; } }
    .c-all-wrap .title {
      font-size: 1.8rem;
      font-weight: 500;
      color: var(--color-text); }

.c-price-total-all {
  font-weight: 500;
  font-size: 1.8rem; }

.c .waiting {
  color: #F2C524; }

.c .success {
  color: #63BD62; }

.c .cancle {
  color: #F57242; }

.fsocial {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 49;
  transition: 0.5s linear;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .fsocial {
      right: 1.5rem; } }
  .fsocial .icon {
    height: 5rem;
    width: 5rem;
    display: flex;
    margin-top: 1.2rem;
    transition: 0.3s ease-in-out;
    padding: 1rem 0.8rem;
    position: relative;
    border-radius: 100rem;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
    @media screen and (max-width: 1440px) {
      .fsocial .icon {
        width: 4rem;
        height: 4rem;
        padding: 1rem 0.5rem; } }
    .fsocial .icon img {
      border-radius: unset;
      object-fit: contain; }
    .fsocial .icon .circlejs {
      animation: growAndFade 3s infinite ease-out;
      background-color: dodgerblue;
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      height: 6.7rem;
      width: 6.7rem;
      left: 50%;
      top: 50%;
      z-index: -1; }
    .fsocial .icon .circle1 {
      animation-delay: 1s; }
    .fsocial .icon .circle2 {
      animation-delay: 2s; }
    .fsocial .icon .circle3 {
      animation-delay: 3s; }
    @media (hover: hover) {
      .fsocial .icon:hover {
        transform: scale(1.1); } }
    .fsocial .icon img {
      width: 100%;
      height: auto;
      animation: shake-lr 4s linear infinite both; }
    .fsocial .icon:nth-child(1) {
      background: #ffffff;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      background: #2196F3; }
      @media screen and (min-width: 1441px) {
        .fsocial .icon:nth-child(1) {
          padding: unset; } }
      .fsocial .icon:nth-child(1) img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
        animation: phoneRing 1.5s infinite linear; }
    .fsocial .icon:nth-child(2) {
      background: #2196F3;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      @media screen and (min-width: 1441px) {
        .fsocial .icon:nth-child(2) {
          padding: unset; } }
      @media screen and (min-width: 1441px) {
        .fsocial .icon:nth-child(2) img {
          width: 4rem;
          height: 4rem; } }

.text-hori .char {
  transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  transform: translateX(4rem);
  opacity: 0; }

.text-hori.is-inview .char {
  transform: translateX(0);
  opacity: 1; }

.text-verti .char {
  transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  transform: translateY(4rem);
  opacity: 0; }

.text-verti.is-inview .char {
  transform: translateY(0);
  opacity: 1; }

.text-scale .char {
  transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  transform: scale(0);
  opacity: 0; }

.text-scale.is-inview .char {
  transform: scale(1);
  opacity: 1; }

.count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  width: fit-content;
  padding: 0.5rem;
  gap: 1rem; }
  .count-btn {
    width: 3rem;
    height: 3rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    border-radius: 0.4rem;
    transition: 0.4s; }
    .count-btn i {
      font-size: 1rem; }
    @media (hover: hover) {
      .count-btn:hover {
        background: var(--color-pri);
        color: white; } }

.hover-rec::after {
  content: '';
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  border: 0.1rem solid transparent;
  border-radius: inherit;
  transition: 0.2s;
  pointer-events: none; }

@media (hover: hover) {
  .hover-rec:hover::after {
    left: 2%;
    top: 2%;
    right: 2%;
    bottom: 2%;
    border-color: var(--color-second); } }

.title {
  width: 63.7rem;
  max-width: 100%;
  margin: auto; }
  .title .tsub {
    color: #2167B2;
    text-align: center;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase; }
    @media only screen and (max-width: 425px) {
      .title .tsub {
        font-size: 1.4rem; } }
  .title .title-pri {
    color: var(--color-text);
    font-size: 4.8rem;
    font-weight: 600;
    line-height: normal; }
    @media only screen and (max-width: 1200px) {
      .title .title-pri {
        font-size: 3.8rem; } }
    @media only screen and (max-width: 800px) {
      .title .title-pri {
        font-size: 2.8rem; } }
    @media only screen and (max-width: 300px) {
      .title .title-pri {
        font-size: 2.4rem; } }
    .title .title-pri.t-white {
      color: white; }
  .title .title-second {
    color: var(--color-text);
    text-align: center;
    font-size: 4.2rem;
    font-weight: 500;
    position: relative;
    line-height: normal;
    text-wrap: pretty; }
    @media only screen and (max-width: 1200px) {
      .title .title-second {
        font-size: 3.2rem; } }
    @media only screen and (max-width: 800px) {
      .title .title-second {
        font-size: 2.6rem; } }
  .title .title-sub {
    color: var(--color-text-second);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    margin: auto;
    text-wrap: pretty; }
    @media only screen and (max-width: 425px) {
      .title .title-sub {
        font-size: 1.4rem; } }

.checker {
  cursor: pointer;
  display: flex;
  gap: 1rem;
  user-select: none; }
  .checker .sqr {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    position: relative;
    display: block; }
    .checker .sqr::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/check-empty.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 0;
      top: 0; }
  .checker .circle {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    position: relative;
    display: block; }
    .checker .circle::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/radio-empty.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 0;
      top: 0; }
  .checker .txt {
    display: block; }
    @media screen and (max-width: 500px) {
      .checker .txt {
        font-size: 1.4rem; } }
  .checker input {
    display: none; }
  .checker input:checked ~ .sqr::after {
    background-image: url("../assets/images/check-fill.svg"); }
  .checker input:checked ~ .circle::after {
    background-image: url("../assets/images/radio-fill.svg"); }

.banner {
  position: relative; }
  .banner .wave-bg {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -.1rem;
    z-index: 1; }
    .banner .wave-bg-inner {
      display: block;
      padding-top: calc((233 / 1728) * 100%);
      position: relative;
      overflow: hidden; }
      .banner .wave-bg-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom; }
  .banner .bg {
    position: absolute;
    height: 100%;
    width: 100%; }
    .banner .bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .banner .bg::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #FFF -5.76%, #FFF 27.83%, rgba(255, 255, 255, 0.74) 41.27%, rgba(255, 255, 255, 0) 54.71%); }
  .banner-ctn {
    min-height: calc(100vw*(628/1728));
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 4rem 0;
    padding-top: calc(var(--size-hd) + 2.4rem); }
  .banner .title {
    margin: unset;
    width: 100%; }
    .banner .title .title-pri {
      color: var(--color-text);
      font-size: 3.8rem; }
      @media only screen and (max-width: 1200px) {
        .banner .title .title-pri {
          font-size: 2.8rem; } }
      @media only screen and (max-width: 800px) {
        .banner .title .title-pri {
          font-size: 2.4rem; } }
  .banner .breadcrumb .container {
    padding: 0; }
  .banner .breadcrumb-item {
    color: var(--color-text); }
    .banner .breadcrumb-item:last-child {
      color: var(--color-text);
      font-weight: 600; }

.bn .bn-item {
  width: 100%;
  height: fit-content; }
  @media screen and (min-width: 1201px) {
    .bn .bn-item {
      height: 100vh; } }
  .bn .bn-item .image-inner {
    width: 100%; }
    @media screen and (min-width: 1201px) {
      .bn .bn-item .image-inner {
        height: 100vh; } }
    @media screen and (max-width: 1200px) {
      .bn .bn-item .image-inner {
        display: block;
        padding-top: calc((800 / 1728) * 100%);
        position: relative;
        overflow: hidden; } }
    @media screen and (max-width: 1200px) {
      .bn .bn-item .image-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    @media screen and (min-width: 1201px) {
      .bn .bn-item .image-inner img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.bn .bnSlider {
  position: relative; }
  @media screen and (min-width: 1201px) {
    .bn .bnSlider .swiper {
      height: 100vh; } }

@media screen and (min-width: 1201px) {
  .bn .bn-control {
    content: '';
    position: absolute;
    right: 3.6rem;
    top: 50%;
    z-index: 4;
    transform: translateY(-50%); }
    .bn .bn-control .swiper-control {
      display: block;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 20.3rem; }
    .bn .bn-control .swiper-control-btn {
      background: transparent;
      border: unset; }
      .bn .bn-control .swiper-control-btn::after {
        display: none; } }
    @media screen and (min-width: 1201px) and (hover: hover) {
      .bn .bn-control .swiper-control-btn:hover {
        scale: 1.1; } }

@media screen and (min-width: 1201px) {
    .bn .bn-control .swiper-next {
      margin-top: unset;
      height: fit-content; }
    .bn .bn-control .swiper-button-disabled {
      opacity: unset;
      width: 100%;
      height: fit-content;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; } }

@media screen and (max-width: 1200px) {
  .bn .bn-control .swiper-control .swiper-control-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    background: transparent;
    border: unset; }
    .bn .bn-control .swiper-control .swiper-control-btn img {
      rotate: -90deg; }
  .bn .bn-control .swiper-control .swiper-prev {
    left: 1rem; }
  .bn .bn-control .swiper-control .swiper-next {
    right: 1rem; } }

.bn .bn-pagi {
  margin: 0 auto;
  content: '';
  position: absolute;
  right: 4.6rem;
  z-index: 5;
  width: 1.9rem !important; }
  @media screen and (max-width: 1200px) {
    .bn .bn-pagi {
      display: none; } }
  @media screen and (max-width: 1280px) {
    .bn .bn-pagi {
      right: 4.2rem; } }
  .bn .bn-pagi .swiper-pagination-bullet {
    text-align: center;
    width: 1.5rem !important;
    height: 1rem;
    width: fit-content;
    color: #ffffff;
    background-color: transparent;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase; }
    @media only screen and (max-width: 425px) {
      .bn .bn-pagi .swiper-pagination-bullet {
        font-size: 1.4rem; } }
  .bn .bn-pagi .swiper-pagination-bullet-active {
    width: 1.5rem;
    /* Larger width for active bullet */
    background-color: transparent;
    /* Different background color for active bullet */ }
  .bn .bn-pagi .swiper-pagination-bullet-active-main {
    position: relative; }

.blog-sect .prod-search {
  width: 43rem; }
  @media screen and (max-width: 575px) {
    .blog-sect .prod-search {
      width: 100%; } }

.blog-aside-wr {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 801px) {
    .blog-aside-wr {
      row-gap: 3.2rem; } }

@media screen and (max-width: 800px) {
  .blog-list-wr {
    width: 100%; }
    .blog-list-wr .blog-list.row {
      --cg: 1.6rem; } }

.blog-aside {
  position: sticky;
  top: var(--size-hd);
  height: 100%; }
  @media screen and (max-width: 800px) {
    .blog-aside {
      position: unset;
      width: 100%; } }
  .blog-aside-wr .benefit-item {
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .blog-aside-wr .benefit-item {
        margin-bottom: 1.6rem; } }
    .blog-aside-wr .benefit-item:last-child {
      margin-bottom: 0; }
    .blog-aside-wr .benefit-item-wr {
      align-items: center; }
  .blog-aside-wr .benefit {
    margin-top: 0; }
  @media screen and (max-width: 800px) {
    .blog-aside-wr .benefit-wr {
      display: flex;
      flex-wrap: wrap;
      margin: 0 calc(-1 * var(--it-pd));
      row-gap: var(--it-pd); } }
  @media screen and (max-width: 800px) {
    .blog-aside-wr .benefit-wr .benefit-item {
      width: 100%;
      padding: 0 var(--it-pd); } }

.blog-card {
  width: 50%;
  padding: 0 var(--it-pd); }
  @media screen and (max-width: 390px) {
    .blog-card {
      width: 100%; } }
  .blog-card .thumb {
    display: block;
    padding-top: calc((288 / 384) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 2.4rem;
    margin-bottom: 1.6rem; }
    .blog-card .thumb img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s; }
    @media (hover: hover) {
      .blog-card .thumb:hover img {
        transform: translate(-50%, -50%) scale(1.1); } }
    .blog-card .thumb .tag {
      min-width: 4.7rem;
      position: absolute;
      top: 2.4rem;
      left: 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 0.8rem;
      color: #F5F7F7;
      pointer-events: none;
      background-color: var(--color-pri); }
      .blog-card .thumb .tag .day {
        font-size: 2.4rem;
        font-weight: 600; }
      .blog-card .thumb .tag .month {
        font-size: 1.2rem; }
  .blog-card .blog-card-name .link {
    color: var(--color-text);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .blog-card .blog-card-name .link {
        font-size: 1.6rem;
        margin-bottom: 0; } }
    @media (hover: hover) {
      .blog-card .blog-card-name .link:hover {
        color: var(--color-pri); } }
  .blog-card .desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.4rem; }
  .blog-card .blog-card-link {
    font-weight: 500;
    padding: 1.6rem 0;
    display: flex;
    align-items: center;
    color: var(--color-pri); }
    @media screen and (max-width: 1024px) {
      .blog-card .blog-card-link {
        font-size: 1.4rem;
        padding: 1.2rem 0; } }
    .blog-card .blog-card-link .ic {
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      flex-shrink: 0;
      margin-left: 1.2rem;
      transition: .3s; }
      @media screen and (max-width: 1024px) {
        .blog-card .blog-card-link .ic {
          width: 1.2rem;
          height: 1.2rem; } }
      .blog-card .blog-card-link .ic img {
        width: 100%;
        height: 100%; }
    @media (hover: hover) {
      .blog-card .blog-card-link:hover .ic {
        transform: translateX(0.2rem); } }

@media screen and (max-width: 800px) {
  .topic-block {
    margin-bottom: 2.4rem; } }

.topic-block .topic-block-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #282B27;
  font-size: 2.4rem;
  font-weight: 600;
  border-bottom: 0.1rem solid #E0E0E0;
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem; }
  @media screen and (max-width: 800px) {
    .topic-block .topic-block-heading {
      padding-bottom: 1.2rem;
      margin-bottom: 1.2rem; } }
  .topic-block .topic-block-heading .ic {
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    display: none; }
    .topic-block .topic-block-heading .ic img {
      width: 100%;
      height: 100%; }

.topic-block #ez-toc-container {
  background: transparent;
  border: unset;
  border-radius: unset;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: unset;
  width: 100%;
  display: table;
  margin-bottom: unset;
  padding: 0 2rem; }
  @media screen and (max-width: 800px) {
    .topic-block #ez-toc-container {
      padding: 0; } }
  .topic-block #ez-toc-container .ez-toc-btn {
    display: none; }
  .topic-block #ez-toc-container .ez-toc-title {
    color: var(--TextColor-NeutralPrimary, #282B27);
    font-family: "Roboto", sans-serif;
    font-size: 2.4rem;
    font-weight: 600;
    width: 100%;
    padding: 2.5rem 0; }
    @media only screen and (max-width: 1200px) {
      .topic-block #ez-toc-container .ez-toc-title {
        font-size: 2.2rem; } }
    @media only screen and (max-width: 800px) {
      .topic-block #ez-toc-container .ez-toc-title {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 425px) {
      .topic-block #ez-toc-container .ez-toc-title {
        font-size: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .topic-block #ez-toc-container .ez-toc-title {
        padding: 1.25rem 0; } }
  .topic-block #ez-toc-container a {
    display: block;
    color: var(--TextColor-NeutralPrimary, #282B27);
    font-family: "Roboto", sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    padding: 1.4rem 0;
    width: 100%;
    border-bottom: 1px solid var(--BoderColor-BorderNeutralSecondary, #E0E2DE); }
    @media only screen and (max-width: 1200px) {
      .topic-block #ez-toc-container a {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .topic-block #ez-toc-container a {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 425px) {
      .topic-block #ez-toc-container a {
        font-size: 1.4rem; } }
    @media screen and (max-width: 800px) {
      .topic-block #ez-toc-container a {
        padding: 1.2rem 0; } }
    @media screen and (max-width: 425px) {
      .topic-block #ez-toc-container a {
        padding: .6rem 0; } }

.blog-topic {
  position: sticky;
  top: var(--size-hd);
  height: 100%; }
  @media screen and (max-width: 800px) {
    .blog-topic {
      position: fixed;
      z-index: 50;
      right: 0;
      bottom: 0;
      width: 35rem;
      background-color: #fff;
      padding: 0;
      transform: translate(100%, 0);
      transition: all .3s; } }
  @media screen and (max-width: 800px) and (max-width: 575px) {
    .blog-topic {
      width: 85%; } }
  @media screen and (max-width: 800px) {
      .blog-topic.open {
        transform: translate(0, 0); } }
  @media screen and (max-width: 800px) and (max-width: 800px) {
    .blog-topic .blog-topic-wr {
      padding: 2rem; } }
  @media screen and (max-width: 800px) {
      .blog-topic .filter-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translate(calc(-100% - 2rem), -50%);
        width: 4rem;
        height: 4rem;
        border-radius: 10rem 0 0 10rem;
        background-color: var(--color-pri); }
        .blog-topic .filter-btn i {
          font-size: 1.6rem;
          color: #fff; } }

.blog-topic-item:last-child .blog-topic-heading {
  margin-bottom: 0; }

.blog-topic-item.active .blog-topic-heading .ic i {
  transform: rotate(180deg); }

.blog-topic-item .blog-topic-heading {
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid #E0E0E0;
  font-weight: 500;
  font-size: 2rem; }
  @media screen and (max-width: 768px) {
    .blog-topic-item .blog-topic-heading {
      font-size: 1.8rem;
      padding-bottom: 1.2rem;
      margin-bottom: 1.2rem; } }
  .blog-topic-item .blog-topic-heading .ic i {
    font-size: 1.6rem;
    transition: all .3s; }

.blog-topic-item .blog-topic-content-list {
  padding-bottom: 2.4rem; }

.blog-topic-item .blog-topic-content-item {
  cursor: pointer; }
  .blog-topic-item .blog-topic-content-item:last-child .blog-card-link {
    border-bottom: none; }
  .blog-topic-item .blog-topic-content-item .blog-topic-link {
    display: flex;
    padding: 2rem 0;
    border-bottom: 0.1rem solid #E0E0E0; }

@media screen and (max-width: 800px) {
  .most-blog .most-blog-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-1 * var(--it-pd));
    row-gap: 3.2rem; } }
  @media screen and (max-width: 800px) and (max-width: 425px) {
    .most-blog .most-blog-list {
      row-gap: 1.6rem; } }

.most-blog .tt-main {
  margin-bottom: 2.8rem; }
  @media screen and (max-width: 800px) {
    .most-blog .tt-main {
      margin-bottom: 1.4rem; } }

.most-blog .blog-card {
  width: 100%;
  background-color: #F5F7F7;
  margin-bottom: 1.6rem;
  padding: 2rem;
  padding-top: 1.6rem !important;
  border-radius: 1.6rem; }
  @media screen and (max-width: 800px) {
    .most-blog .blog-card {
      padding-top: unset !important;
      width: 50%;
      padding: 0 var(--it-pd);
      background-color: transparent;
      margin-bottom: unset !important; } }
  @media screen and (max-width: 390px) {
    .most-blog .blog-card {
      width: 100%; } }
  @media screen and (max-width: 800px) {
    .most-blog .blog-card .blog-card-inner {
      background-color: #F5F7F7;
      padding: 1.2rem;
      padding-bottom: unset; } }
  .most-blog .blog-card:last-child {
    margin-bottom: 0; }
  .most-blog .blog-card .thumb {
    display: none; }

.page-blog {
  overflow: unset !important; }
  .page-blog .blog-card {
    padding: 0 calc(var(--rg) / 2);
    margin-bottom: var(--cg); }
    @media screen and (max-width: 425px) {
      .page-blog .blog-card {
        --rg: 1.6rem;
        --cg: 1.6rem; } }
    .page-blog .blog-card .author {
      display: none; }
  @media screen and (max-width: 575px) {
    .page-blog .benefit-item-wr {
      justify-content: start !important; } }
  @media screen and (max-width: 800px) {
    .page-blog .benefit {
      margin-bottom: 3.2rem; } }

.content-blog-wr {
  padding: 4rem 0 6rem; }
  .content-blog-wr .inner .hd .mobile {
    display: block !important;
    position: unset;
    width: 100%;
    min-width: 100%;
    transform: translateX(0); }

.aside {
  position: sticky;
  top: 8rem;
  height: 100%;
  border-radius: 0.4rem;
  border: 0.1rem solid #EDEDED;
  background-color: #ffffff; }
  @media screen and (max-width: 991px) {
    .aside {
      width: 25rem;
      position: fixed;
      z-index: 10;
      top: 50%;
      left: 0;
      padding-top: var(--size-hd);
      transform: translate(-100%, -50%);
      transition: .4s; }
      .aside-wr ul li {
        font-size: 1.4rem !important;
        text-wrap: pretty; } }
  @media screen and (max-width: 500px) {
    .aside {
      width: 50%; } }
  .aside.open {
    transform: translate(0, -50%); }
  .aside .bar {
    position: absolute;
    top: 50%;
    transform: translate(100%, -50%);
    right: 0;
    width: 3.5rem;
    height: 3.2rem;
    background-color: #ffffff;
    box-shadow: 1rem 0rem 3rem 0rem rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer; }
    @media screen and (min-width: 992px) {
      .aside .bar {
        display: none; } }
    .aside .bar .line {
      display: block;
      width: 100%;
      height: 0.16rem;
      background-color: var(--color-pri); }
  .aside .inner {
    padding: 2.4rem;
    padding-bottom: .5rem;
    /* Track */
    /* Handle */ }
    .aside .inner::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem; }
    .aside .inner::-webkit-scrollbar-track {
      background: transparent; }
    .aside .inner::-webkit-scrollbar-thumb {
      background: var(--color-pri); }
    @media screen and (max-width: 1200px) {
      .aside .inner {
        height: 100%;
        overflow: scroll;
        padding: 1.4rem; } }
  .aside .aside-item {
    border-bottom: 0.1rem solid #E0E0E0; }
  .aside .aside-item.active .aside-item-hd .tt {
    color: var(--color-pri); }
  .aside .aside-item.active .aside-item-hd .ic {
    transform: rotate(180deg); }
    .aside .aside-item.active .aside-item-hd .ic i {
      color: var(--color-pri); }
  .aside .tt-aside {
    color: var(--color-second);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.6rem; }
  .aside .aside-item-hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 0;
    cursor: pointer; }
    .aside .aside-item-hd .tt {
      color: var(--color-second);
      font-weight: 600; }
    .aside .aside-item-hd .ic {
      transition: .4s; }
      .aside .aside-item-hd .ic i {
        color: #1E1E1E; }
  .aside .aside-item-bd {
    padding: 0 1.6rem 1.6rem 1.6rem; }
    .aside .aside-item-bd .content-item {
      margin-bottom: 0.8rem;
      transition: .4s; }
      .aside .aside-item-bd .content-item:last-child {
        margin-bottom: 0; }
      .aside .aside-item-bd .content-item:hover {
        color: var(--color-pri); }

@media screen and (max-width: 991px) {
  .content-wr {
    width: calc(((8/12)*100%)); } }

@media screen and (max-width: 768px) {
  .content-wr {
    width: 100%; } }

.content-wr .inner {
  border: 0.1rem solid #E0E0E0;
  padding: 2rem;
  border-radius: 0.4rem; }
  @media screen and (max-width: 1200px) {
    .content-wr .inner {
      padding: 1.4rem; } }

.content-wr .info-content {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2.4rem; }
  @media screen and (max-width: 1200px) {
    .content-wr .info-content {
      gap: 1.5rem; } }
  @media screen and (max-width: 500px) {
    .content-wr .info-content {
      flex-wrap: wrap;
      row-gap: 1rem; } }
  .content-wr .info-content .info {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-right: 1.6rem;
    border-right: 0.1rem solid #E0E0E0; }
    .content-wr .info-content .info:last-child {
      border-right: unset; }
    @media screen and (max-width: 330px) {
      .content-wr .info-content .info {
        border-right: unset; } }
    .content-wr .info-content .info .ic {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0; }
      .content-wr .info-content .info .ic img {
        width: 100%;
        height: 100%; }
    .content-wr .info-content .info .txt {
      font-weight: 500; }
      @media screen and (max-width: 1200px) {
        .content-wr .info-content .info .txt {
          font-size: 1.4rem; } }

.content-wr .tt-sec {
  color: #0A0A0A;
  font-weight: 600; }

.content-wr .main-content.wp-content {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem; }
  .content-wr .main-content.wp-content h2 {
    color: #0A0A0A;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%; }
  .content-wr .main-content.wp-content p {
    font-size: 1.6rem;
    line-height: 150%; }
  .content-wr .main-content.wp-content .caption {
    font-size: 1.4rem;
    padding-left: 1.6rem;
    border-left: 0.2rem solid var(--color-pri); }
  .content-wr .main-content.wp-content .img {
    width: 100%;
    height: 100%; }
    .content-wr .main-content.wp-content .img img {
      width: 100%;
      height: 100%; }

.content-wr .content-act {
  margin-top: 3.2rem;
  padding-top: 3.2rem;
  border-top: 0.1rem solid #E0E0E0; }
  .content-wr .content-act .content-act-wr .txt {
    color: #4D4D4D;
    margin-bottom: 1.6rem; }

.content-wr .share {
  margin-bottom: 1.6rem; }
  .content-wr .share .share-act {
    display: flex;
    align-items: center;
    gap: 1rem; }
    .content-wr .share .share-act .link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: #666666;
      transition: .4s; }
      .content-wr .share .share-act .link i {
        color: #ffffff;
        font-size: 1.6rem; }
      .content-wr .share .share-act .link:hover {
        background-color: var(--color-pri); }

.content-wr .tag-blog .tag-act {
  display: flex;
  align-items: center;
  gap: 1.2rem; }
  .content-wr .tag-blog .tag-act .link {
    display: flex;
    padding: 0.4rem 0.8rem;
    color: #4D4D4D;
    background-color: #F0F0F0;
    transition: .4s; }
    .content-wr .tag-blog .tag-act .link:hover {
      color: var(--color-pri);
      background-color: #FCEAE9; }

.side-blog {
  position: sticky;
  top: 8rem;
  height: 100%; }
  @media screen and (max-width: 991px) {
    .side-blog {
      width: calc(((4/12)*100%)); } }
  @media screen and (max-width: 768px) {
    .side-blog {
      width: 100%; } }
  .side-blog > .inner {
    padding: 2rem;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.4rem; }
    @media screen and (max-width: 1200px) {
      .side-blog > .inner {
        padding: 1.4rem; } }
  .side-blog .tt-sec {
    margin-bottom: 2.4rem;
    text-wrap: balance; }

.info-blog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4rem; }
  .info-blog .info-blog-item {
    display: flex;
    align-items: center; }
    .info-blog .info-blog-item:not(:last-child) {
      padding-right: 1.6rem;
      margin-right: 1.6rem;
      border-right: 0.1rem solid #E1E8E8; }
    .info-blog .info-blog-item:first-child .txt {
      font-size: 1.6rem;
      color: #101212;
      font-weight: 500; }
  .info-blog .ic {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    margin-right: 0.8rem; }
    .info-blog .ic img {
      width: 100%;
      height: 100%; }
  .info-blog .txt {
    font-size: 1.4rem;
    color: #606969; }

@media screen and (max-width: 800px) {
  .blog-dt-content {
    width: 100%; } }

.blog-dt-content .mona-content h1,
.blog-dt-content .mona-content h2,
.blog-dt-content .mona-content h3,
.blog-dt-content .mona-content h4,
.blog-dt-content .mona-content h5,
.blog-dt-content .mona-content h6 {
  color: #101212;
  font-weight: 500; }

.blog-dt-content .mona-content h1 {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .blog-dt-content .mona-content h1 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 600px) {
    .blog-dt-content .mona-content h1 {
      font-size: 2rem; } }

.blog-dt-content .mona-content h2 {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .blog-dt-content .mona-content h2 {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .blog-dt-content .mona-content h2 {
      font-size: 1.4rem; } }

.blog-dt-content .mona-content p {
  color: var(--color-text-second);
  font-size: 1.6rem; }
  @media only screen and (max-width: 425px) {
    .blog-dt-content .mona-content p {
      font-size: 1.4rem; } }

.blog-dt-content .mona-content img {
  border-radius: 3.2rem; }

.blog-dt-content .mona-content .wp-caption {
  border: unset; }

.blog-dt-content .mona-content iframe {
  aspect-ratio: 16/9;
  width: 100%; }

.blog-share {
  margin-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .blog-share {
      margin-top: 2.8rem; } }
  .blog-share .txt {
    color: #3A4040;
    margin-bottom: 1.6rem; }
  .blog-share .social-list {
    display: flex;
    gap: 0.8rem; }
    .blog-share .social-list .social-link {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%; }
      .blog-share .social-list .social-link img {
        width: 100%;
        height: 100%; }

.page-blog-dt {
  padding-top: calc(var(--size-hd) + 2.4rem);
  overflow: unset !important; }
  @media screen and (max-width: 800px) {
    .page-blog-dt .blog-dt-wr {
      flex-direction: column; } }
  @media screen and (max-width: 575px) {
    .page-blog-dt .benefit-item-wr {
      justify-content: start !important; } }
  @media (min-width: 500px) and (max-width: 800px) {
    .page-blog-dt .blog-dt-sect {
      margin-bottom: -6rem;
      padding-bottom: unset; } }

@media screen and (max-width: 900px) {
  .prod-main {
    width: 100%; } }

.prod-filter {
  position: sticky;
  top: var(--size-hd);
  height: 100%; }
  @media screen and (min-width: 901px) {
    .prod-filter .filter-btn {
      display: none; } }
  @media screen and (max-width: 900px) {
    .prod-filter {
      position: fixed;
      z-index: 50;
      right: 0;
      bottom: 0;
      width: 35rem;
      background-color: #fff;
      padding: 0;
      transform: translate(100%, 0);
      transition: all .3s; } }
  @media screen and (max-width: 900px) and (max-width: 575px) {
    .prod-filter {
      width: 85%; } }
  @media screen and (max-width: 900px) {
      .prod-filter.open {
        transform: translate(0, 0);
        overflow: hidden scroll; } }
  @media screen and (max-width: 900px) and (max-width: 800px) {
    .prod-filter .prod-filter-wr {
      padding: 2rem; } }
  @media screen and (max-width: 900px) {
      .prod-filter .filter-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translate(calc(-100% - 2rem), -50%);
        width: 4rem;
        height: 4rem;
        border-radius: 10rem 0 0 10rem;
        background-color: var(--color-pri); }
        .prod-filter .filter-btn i {
          font-size: 1.6rem;
          color: #fff; } }

.prod-card {
  width: 50%;
  padding: 0 var(--it-pd); }
  @media screen and (max-width: 390px) {
    .prod-card {
      width: 100%; } }
  .prod-card .thumb {
    display: block;
    padding-top: calc((288 / 384) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 2.4rem;
    margin-bottom: 1.6rem; }
    .prod-card .thumb img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s; }
    @media (hover: hover) {
      .prod-card .thumb:hover img {
        transform: translate(-50%, -50%) scale(1.1); } }
  .prod-card .prod-card-name .link {
    color: var(--color-text);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
    transition: .3s;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .prod-card .prod-card-name .link {
        font-size: 1.6rem;
        margin-bottom: 0; } }
    @media (hover: hover) {
      .prod-card .prod-card-name .link:hover {
        color: var(--color-pri); } }
  .prod-card .prod-card-link {
    font-weight: 500;
    padding: 1.6rem 0;
    display: flex;
    align-items: center;
    color: var(--color-pri);
    transition: .3s; }
    @media screen and (max-width: 1024px) {
      .prod-card .prod-card-link {
        font-size: 1.4rem;
        padding: 1.2rem 0; } }
    .prod-card .prod-card-link .ic {
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      flex-shrink: 0;
      margin-left: 1.2rem;
      transition: .3s; }
      @media screen and (max-width: 1024px) {
        .prod-card .prod-card-link .ic {
          width: 1.2rem;
          height: 1.2rem; } }
      .prod-card .prod-card-link .ic img {
        width: 100%;
        height: 100%; }
    @media (hover: hover) {
      .prod-card .prod-card-link:hover .ic {
        transform: translateX(0.5rem); } }

.prod-search-wr {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  background-color: #F5F7F7;
  padding-right: 1.2rem;
  overflow: hidden;
  border-radius: 1.2rem;
  margin-bottom: 2.4rem; }
  .prod-search-wr .input {
    width: 100%;
    height: 100%;
    flex: 1; }
    .prod-search-wr .input input {
      font-size: 1.6rem;
      width: 100%;
      height: 100%;
      padding: 1.2rem;
      color: #3A4040; }
      .prod-search-wr .input input::placeholder {
        color: #3A4040; }
  .prod-search-wr .btn-prod-search {
    width: 2.4rem;
    height: 2.4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer; }
    .prod-search-wr .btn-prod-search img {
      width: 1.6rem;
      height: 1.6rem;
      object-fit: contain; }

.prod-filter-item:last-child .prod-filter-heading {
  margin-bottom: 0;
  border-bottom: unset; }

.prod-filter-item.active .prod-filter-heading .plus .line {
  background-color: var(--color-pri); }
  .prod-filter-item.active .prod-filter-heading .plus .line:last-child {
    transform: translate(-50%, 0) rotate(90deg); }

.prod-filter-item.active .prod-filter-parent {
  color: var(--color-pri); }

.prod-filter-item .prod-filter-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2.4rem;
  border-bottom: 0.1rem solid #E0E0E0;
  margin-bottom: 2.4rem; }
  .prod-filter-item .prod-filter-heading .plus {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 1rem;
    cursor: pointer; }
    .prod-filter-item .prod-filter-heading .plus .line {
      position: absolute;
      background-color: #101212;
      -webkit-transition: all .4s;
      transition: all .4s; }
      .prod-filter-item .prod-filter-heading .plus .line:first-child {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
        height: 0.1rem; }
      .prod-filter-item .prod-filter-heading .plus .line:last-child {
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0.1rem;
        height: 100%; }

.prod-filter-item .prod-filter-content-list {
  padding-bottom: 2.4rem; }

.prod-filter-item .prod-filter-content-item {
  cursor: pointer; }
  .prod-filter-item .prod-filter-content-item input {
    display: none; }
    .prod-filter-item .prod-filter-content-item input:checked ~ .txt-prod {
      color: #101212;
      background-color: #F5F7F7; }
  .prod-filter-item .prod-filter-content-item .txt-prod {
    color: #3A4040;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem;
    transition: all .3s;
    border-radius: 0.8rem; }
  .prod-filter-item .prod-filter-content-item .total {
    font-size: 1.2rem; }

.page-products .banner .wave-bg,
.tax-product .banner .wave-bg {
  display: none; }

.page-products .prod-card .prod-card-name .link,
.tax-product .prod-card .prod-card-name .link {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.page-products .prod-card .prod-card-link,
.tax-product .prod-card .prod-card-link {
  padding: unset; }

.page-products .prod-list,
.tax-product .prod-list {
  row-gap: unset; }
  @media screen and (max-width: 800px) {
    .page-products .prod-list.row,
    .tax-product .prod-list.row {
      --rg: 1.6rem;
      --cg: 1.6rem; } }
  @media screen and (max-width: 425px) {
    .page-products .prod-list,
    .tax-product .prod-list {
      --rg: 1.6rem;
      --cg: 1.6rem; } }

.page-products .prod-card,
.tax-product .prod-card {
  width: calc(100% / 3);
  padding: 0 calc(var(--cg) / 2);
  margin-bottom: var(--rg); }
  @media screen and (max-width: 600px) {
    .page-products .prod-card,
    .tax-product .prod-card {
      width: calc(100% / 2); } }
  @media screen and (max-width: 375px) {
    .page-products .prod-card,
    .tax-product .prod-card {
      width: 100%; } }
  .page-products .prod-card img,
  .tax-product .prod-card img {
    object-fit: contain; }
  @media screen and (max-width: 425px) {
    .page-products .prod-card .prod-card-inner .thumb,
    .tax-product .prod-card .prod-card-inner .thumb {
      margin-bottom: .8rem; } }

.page_type-search .prod-card {
  width: calc(100% / 4); }
  @media screen and (max-width: 1200px) {
    .page_type-search .prod-card {
      width: calc(100% / 3); } }
  @media screen and (max-width: 800px) {
    .page_type-search .prod-card {
      width: calc(100% / 2); } }
  @media screen and (max-width: 375px) {
    .page_type-search .prod-card {
      width: 100%; } }

.page-products .prod-card .prod-card-name .link,
.tax-product .prod-card .prod-card-name .link {
  text-wrap: pretty; }

.banner .bg img {
  object-position: right; }

.prod-dt-heading .thumb {
  display: block;
  padding-top: calc((728 / 1216) * 100%);
  position: relative;
  overflow: hidden;
  border-radius: 3.2rem; }
  .prod-dt-heading .thumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain; }

.prod-desc-block {
  padding-top: 4rem; }
  .prod-desc-block .tt-main {
    margin-bottom: 2.4rem; }
  .prod-desc-block p iframe {
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%; }

.prod-desc-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
  row-gap: 1.6rem; }
  .prod-desc-info .info-item {
    display: flex;
    margin-bottom: 0.8rem; }
    @media screen and (max-width: 575px) {
      .prod-desc-info .info-item {
        font-size: 1.4rem; } }
    .prod-desc-info .info-item:last-child {
      margin-bottom: 0; }
    .prod-desc-info .info-item .label-info {
      width: fit-content;
      color: #606969; }
    .prod-desc-info .info-item .info-dt {
      color: #3A4040;
      font-weight: 600;
      margin-left: 0.4rem;
      flex: 1; }
  .prod-desc-info .info-ct {
    display: flex;
    flex-wrap: nowrap;
    row-gap: 1.6rem;
    align-items: center;
    gap: 1.2rem; }
    @media screen and (max-width: 375px) {
      .prod-desc-info .info-ct .btn .txt {
        padding: 1.6rem 3rem; } }

.feature-prod .feature-item {
  margin-bottom: unset; }
  @media screen and (max-width: 800px) {
    .feature-prod .feature-item {
      width: 50%; } }
  @media screen and (max-width: 450px) {
    .feature-prod .feature-item {
      width: 100%; } }
  .feature-prod .feature-item .inner {
    position: relative;
    z-index: 2;
    height: 100%; }
  .feature-prod .feature-item .bg {
    position: absolute;
    z-index: -2;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.6rem;
    overflow: hidden; }
    .feature-prod .feature-item .bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .feature-prod .feature-item .bg::after {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 35.42%, rgba(0, 0, 0, 0.7) 74.9%); }

.feature-prod .feature-content {
  padding: 36rem 2.4rem 2.4rem 2.4rem;
  color: #F5F7F7;
  font-weight: 500; }
  @media screen and (max-width: 1200px) {
    .feature-prod .feature-content {
      padding: 20rem 1.6rem 1.6rem 1.6rem; } }
  @media screen and (max-width: 575px) {
    .feature-prod .feature-content {
      padding: 15rem 1.2rem 1.2rem 1.2rem; } }

.param-tb {
  border-radius: 1.6rem;
  box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08); }

.param-tb-head {
  border-radius: 1.6rem 1.6rem 0px 0px;
  border-bottom: 1px solid #EDF2F2;
  background-color: #156CC9; }

.param-tb-item {
  width: 50%;
  padding: 1.6rem 0.8rem 1.6rem 1.6rem;
  line-height: 150%; }
  @media screen and (max-width: 575px) {
    .param-tb-item {
      font-size: 1.4rem;
      padding: 1.2rem 0.4rem 1.2rem 1.2rem; } }

.param-tb-row {
  display: flex; }

.param-tb-head-wr .param-tb-item {
  color: #F5F7F7;
  font-weight: 600; }

.param-tb-body-wr .param-tb-row:last-child .param-tb-item {
  border-bottom: none; }

.param-tb-body-wr .param-tb-item {
  border-bottom: 1px solid #D1D1D1; }
  .param-tb-body-wr .param-tb-item:first-child {
    position: relative; }
    .param-tb-body-wr .param-tb-item:first-child::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 80%;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #EDF2F2; }

.param-note {
  margin-top: 2.4rem; }
  .param-note .inner {
    border-radius: 1.6rem;
    border-left: 4px solid #156CC9;
    background-color: #FFF;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    padding: 2.4rem; }
    @media screen and (max-width: 1024px) {
      .param-note .inner {
        padding: 1.6rem; } }
  .param-note .mona-content {
    color: #3A4040;
    font-size: 1.4rem;
    font-weight: 500; }

.prod-slider-sect .container {
  max-width: 100%; }

.prod-slider-heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1.6rem;
  align-items: center;
  padding-bottom: 4.8rem; }
  @media screen and (min-width: 1248px) {
    .prod-slider-heading {
      padding-left: calc(var(--half) - 1.6rem);
      padding-right: calc(var(--half) - 1.6rem); } }
  @media screen and (max-width: 1200px) {
    .prod-slider-heading {
      padding-bottom: 2.8rem; } }

.prod-slider-wr {
  width: calc(100vw - (100vw - 120rem)/2);
  margin-left: auto; }
  @media screen and (max-width: 1260px) {
    .prod-slider-wr {
      width: 100%;
      overflow: hidden; } }
  .prod-slider-wr .swiper {
    margin: 0 calc(-1 * var(--it-pd)); }
  .prod-slider-wr .swiper-slide {
    width: calc(3/12*100%);
    padding: 0 var(--it-pd); }
    @media screen and (max-width: 767px) {
      .prod-slider-wr .swiper-slide {
        width: 40%; } }
    @media screen and (max-width: 575px) {
      .prod-slider-wr .swiper-slide {
        width: 50%; } }
    @media screen and (max-width: 450px) {
      .prod-slider-wr .swiper-slide {
        width: 75%; } }
    .prod-slider-wr .swiper-slide .prod-card {
      width: 100%;
      padding: 0; }

.page-blog-dt .prod-slider-sect {
  padding-bottom: unset; }

.page-product-dt .thumbSwiper {
  width: 40%;
  max-width: 100%;
  margin: auto;
  position: relative; }
  @media screen and (max-width: 800px) {
    .page-product-dt .thumbSwiper {
      width: 40rem; } }
  @media screen and (min-width: 2000px) {
    .page-product-dt .thumbSwiper {
      width: 35%; } }
  .page-product-dt .thumbSwiper .thumb-pagi {
    content: '';
    position: absolute;
    bottom: 0; }
  .page-product-dt .thumbSwiper .swiper-pagination-bullet-active {
    background: var(--color-pri) !important; }

.page-product-dt .thumb {
  display: block;
  padding-top: calc((1 / 1) * 100%);
  position: relative;
  overflow: hidden; }
  .page-product-dt .thumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover; }

.page-product-dt .prod-card .thumb {
  display: block;
  padding-top: calc((288 / 384) * 100%);
  position: relative;
  overflow: hidden; }

.page-product-dt .featureProdSlider {
  width: 100%;
  --rg: 3.2rem;
  --cg: 3.2rem; }
  @media screen and (max-width: 800px) {
    .page-product-dt .featureProdSlider {
      --rg: 1.6rem;
      --cg: 1.6rem; } }
  .page-product-dt .featureProdSlider .swiper {
    margin: 0 calc(var(--cg) / 2 * -1); }
  .page-product-dt .featureProdSlider .row {
    margin: unset;
    margin-bottom: var(--rg); }
    @media screen and (max-width: 800px) {
      .page-product-dt .featureProdSlider .row {
        --rg: 1.6rem;
        --cg: 1.6rem; } }
  @media screen and (max-width: 800px) {
    .page-product-dt .featureProdSlider .feature-item {
      width: 50%; } }
  @media screen and (max-width: 425px) {
    .page-product-dt .featureProdSlider .feature-item {
      width: 100%; } }
  .page-product-dt .featureProdSlider .swiper-pagination {
    margin-top: unset; }
    .page-product-dt .featureProdSlider .swiper-pagination .swiper-pagination-bullet-active {
      background: var(--color-pri); }

.page-product-dt .prod-dt-heading .tt-main {
  font-size: 3.8rem;
  width: 45rem;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .page-product-dt .prod-dt-heading .tt-main {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 800px) {
    .page-product-dt .prod-dt-heading .tt-main {
      font-size: 2.4rem; } }
  @media screen and (min-width: 2000px) {
    .page-product-dt .prod-dt-heading .tt-main {
      font-size: 3.8rem;
      margin-bottom: 1.8rem; } }
  @media only screen and (min-width: 2000px) and (max-width: 1200px) {
    .page-product-dt .prod-dt-heading .tt-main {
      font-size: 2.8rem; } }
  @media only screen and (min-width: 2000px) and (max-width: 800px) {
    .page-product-dt .prod-dt-heading .tt-main {
      font-size: 2.4rem; } }
  @media screen and (max-width: 800px) {
    .page-product-dt .prod-dt-heading .tt-main {
      margin-bottom: calc(4.8rem / 2); } }
  @media screen and (max-width: 1280px) {
    .page-product-dt .prod-dt-heading .tt-main {
      margin-bottom: 1.8rem; } }

@media screen and (min-width: 2000px) {
  .page-product-dt .prod-dt-sect {
    --pd-sc: 3rem; } }

@media screen and (max-width: 1280px) {
  .page-product-dt .prod-dt-sect {
    --pd-sc: 3rem; } }

.page-product-dt .prodSldier .thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain; }

.form-ct-sect {
  position: relative;
  z-index: 2;
  margin-top: -20%; }
  @media screen and (max-width: 800px) {
    .form-ct-sect {
      margin-top: 0; } }

.form-ct-row {
  align-items: flex-end; }
  .form-ct-row .info-ct-item {
    border-bottom: 1px solid #E1E8E8; }
    .form-ct-row .info-ct-item:last-child {
      margin-bottom: 0;
      border-bottom: none; }
    .form-ct-row .info-ct-item .inner {
      padding: 4rem; }
      @media screen and (max-width: 1200px) {
        .form-ct-row .info-ct-item .inner {
          padding: 2rem; } }
    .form-ct-row .info-ct-item .info-ct-item-wr {
      display: flex;
      align-items: center; }
    .form-ct-row .info-ct-item .ic {
      display: flex;
      width: 8.3rem;
      height: 8.3rem;
      flex-shrink: 0;
      border-radius: 1.6rem; }
      @media screen and (max-width: 1200px) {
        .form-ct-row .info-ct-item .ic {
          width: 6.3rem;
          height: 6.3rem; } }
      .form-ct-row .info-ct-item .ic img {
        width: 100%;
        height: 100%; }
    .form-ct-row .info-ct-item .info-dt {
      display: flex;
      flex-direction: column;
      margin-left: 1.4rem; }
      @media screen and (max-width: 1200px) {
        .form-ct-row .info-ct-item .info-dt {
          margin-left: 0.8rem; } }
    .form-ct-row .info-ct-item .info-label {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 0.4rem; }
      @media screen and (max-width: 1200px) {
        .form-ct-row .info-ct-item .info-label {
          font-size: 2rem; } }
    .form-ct-row .info-ct-item .txt {
      color: #606969;
      font-size: 2rem;
      transition: .3s; }
      @media (hover: hover) {
        .form-ct-row .info-ct-item .txt:hover {
          color: var(--color-pri); } }
      @media screen and (max-width: 1200px) {
        .form-ct-row .info-ct-item .txt {
          font-size: 1.6rem; } }
  .form-ct-row .info-ct-wr,
  .form-ct-row .form-ct-wr {
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    background-color: #fff;
    border-radius: 3.2rem; }
  @media screen and (max-width: 800px) {
    .form-ct-row .info-ct,
    .form-ct-row .form-ct {
      width: 100%; } }

.form-ct-wr {
  border-radius: 3.2rem; }
  .form-ct-wr .inner {
    padding: 4rem; }
    @media screen and (max-width: 1200px) {
      .form-ct-wr .inner {
        padding: 2rem; } }
  .form-ct-wr .tt-main {
    padding-bottom: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .form-ct-wr .tt-main {
        padding-bottom: 2rem; } }

.form-group .group {
  margin-bottom: 2.2rem; }
  @media screen and (max-width: 800px) {
    .form-group .group {
      margin-bottom: calc(2.2rem / 2); } }
  @media screen and (min-width: 2000px) {
    .form-group .group {
      margin-bottom: 1.6rem; } }
  .form-group .group:last-child {
    margin-bottom: 0; }
  .form-group .group .btn {
    width: 100%; }

.form-group .label-group {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.4rem; }

.form-group .input,
.form-group .textarea {
  border-radius: 0.8rem;
  background-color: #F5F7F7; }
  .form-group .input input,
  .form-group .input textarea,
  .form-group .textarea input,
  .form-group .textarea textarea {
    font-size: 1.6rem;
    padding: 1.2rem 1.6rem;
    color: #121212;
    font-weight: 600; }

.form-group .input {
  width: 100%;
  height: 4.8rem; }
  .form-group .input input {
    width: 100%;
    height: 100%; }

.form-group .textarea {
  width: 100%;
  height: 15.2rem; }
  .form-group .textarea textarea {
    resize: none;
    width: 100% !important;
    height: 100%; }

.benefit {
  background-color: #F5F7F7;
  margin-top: 5rem;
  border-radius: 1.5rem; }
  @media screen and (max-width: 1200px) {
    .benefit {
      margin-top: 3rem; } }
  .benefit .inner {
    padding: 2rem; }
  @media screen and (max-width: 700px) {
    .benefit .benefit-item {
      width: 50%; } }
  @media screen and (max-width: 575px) {
    .benefit .benefit-item {
      width: 100%; } }
  .benefit .benefit-item-wr {
    display: flex; }
    @media screen and (max-width: 575px) {
      .benefit .benefit-item-wr {
        justify-content: center; } }
    .benefit .benefit-item-wr .ic {
      display: flex;
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      margin-right: 1.6rem; }
      @media screen and (max-width: 1200px) {
        .benefit .benefit-item-wr .ic {
          width: 3.2rem;
          height: 3.2rem;
          margin-right: 0.8rem; } }
      .benefit .benefit-item-wr .ic img {
        width: 100%;
        height: 100%; }
  .benefit .benefit-content {
    display: flex;
    flex-direction: column; }
    .benefit .benefit-content .label {
      font-weight: 600; }
    .benefit .benefit-content .desc {
      font-size: 1.2rem;
      color: #606969; }

.map-wr {
  display: block;
  padding-top: calc((608 / 1216) * 100%);
  position: relative;
  overflow: hidden;
  border-radius: 3.2rem; }
  .map-wr iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover; }

.faq-wr .tt-main {
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .faq-wr .tt-main {
      margin-bottom: 2.8rem; } }

.faq-block .faq-item {
  margin-bottom: 1.6rem;
  padding: 2.4rem 1.6rem;
  background-color: #F5F7F7;
  border-radius: 1.6rem; }
  .faq-block .faq-item i {
    transition: .3s; }
  @media screen and (max-width: 1200px) {
    .faq-block .faq-item {
      padding: 1.6rem; } }
  .faq-block .faq-item:last-child {
    margin-bottom: 0; }
  .faq-block .faq-item.active i {
    transform: rotate(180deg); }

.faq-block .faq-head {
  font-size: 2.4rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .faq-block .faq-head {
      font-size: 2rem; } }

.faq-block .faq-content .desc {
  padding-top: 1.6rem;
  color: #3A4040; }

@media screen and (max-width: 425px) {
  .page-contact .form-ct-sect {
    padding-top: 2rem; } }

.page-contact .faq-sect {
  padding-bottom: 4rem; }

.tn .the-num {
  content: '';
  position: absolute;
  z-index: 2;
  pointer-events: auto;
  animation: k-up-down 3s infinite linear both;
  width: 24rem;
  max-width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(12, 16, 27, 0.15);
  border-radius: 3.2rem; }
  @media screen and (max-width: 1440px) {
    .tn .the-num {
      width: 17rem; } }
  .tn .the-num-wr {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 2.2rem 3.7rem;
    border-radius: 3.2rem;
    background: var(--White, #FFF);
    box-shadow: 0.4rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 1440px) {
      .tn .the-num-wr {
        padding: 1.1rem 1.85rem;
        border-radius: 1.6rem; } }
    .tn .the-num-wr .ic {
      margin-top: .75rem;
      width: 3.6rem;
      height: 3.6rem; }
      .tn .the-num-wr .ic img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .tn .the-num-wr .tnum-wr {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center; }
    .tn .the-num-wr .tnum .num,
    .tn .the-num-wr .tnum .plus {
      color: var(--m002, #00783E);
      text-align: center;
      font-size: 4.8rem;
      font-weight: 500;
      line-height: normal; }
      @media only screen and (max-width: 1200px) {
        .tn .the-num-wr .tnum .num,
        .tn .the-num-wr .tnum .plus {
          font-size: 3.8rem; } }
      @media only screen and (max-width: 800px) {
        .tn .the-num-wr .tnum .num,
        .tn .the-num-wr .tnum .plus {
          font-size: 2.8rem; } }
      @media only screen and (max-width: 300px) {
        .tn .the-num-wr .tnum .num,
        .tn .the-num-wr .tnum .plus {
          font-size: 2.4rem; } }
      @media screen and (max-width: 1440px) {
        .tn .the-num-wr .tnum .num,
        .tn .the-num-wr .tnum .plus {
          font-size: 3.4rem; } }
    .tn .the-num-wr .text {
      color: #3A4040;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400; }
      @media only screen and (max-width: 600px) {
        .tn .the-num-wr .text {
          font-size: 1.2rem; } }

.tn .the-num:nth-child(1) {
  animation-delay: 0;
  top: 25%;
  left: -18%; }

.tn .the-num:nth-child(2) {
  animation-delay: .5s;
  top: 12%;
  right: -8%; }

.tn .the-num:nth-child(3) {
  animation-delay: 1s;
  bottom: 27%;
  right: -15%; }

.tn .the-num:nth-child(4) {
  animation-delay: 1.5s;
  bottom: -13%;
  right: -22%; }

@media screen and (max-width: 800px) {
  .ab {
    padding-top: 2rem; } }

@media screen and (max-width: 800px) {
  .ab-wr.row {
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 15rem; } }
  @media screen and (max-width: 800px) and (max-width: 600px) {
    .ab-wr.row {
      padding-bottom: 13rem; } }
  @media screen and (max-width: 800px) and (max-width: 425px) {
    .ab-wr.row {
      padding-bottom: 10rem; } }

@media screen and (max-width: 800px) {
    .ab-wr.row .col {
      width: 100%; } }
  @media screen and (max-width: 800px) and (min-width: 501px) {
    .ab-wr.row .ab-lf {
      width: 60%; } }

.ab .ab-lf {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: end; }
  .ab .ab-lf .tl-decor1, .ab .ab-lf .tl-decor2, .ab .ab-lf .tl-decor3, .ab .ab-lf .tl-decor4 {
    content: '';
    position: absolute;
    z-index: 4;
    pointer-events: none; }
  .ab .ab-lf .tl-decor1 {
    top: 0;
    left: 0; }
    @media screen and (min-width: 901px) {
      .ab .ab-lf .tl-decor1 {
        animation: leaf 5s infinite linear; } }
  .ab .ab-lf .tl-decor2 {
    top: 0;
    right: 0; }
    @media screen and (min-width: 901px) {
      .ab .ab-lf .tl-decor2 {
        animation: leaf 6s infinite linear; } }
  .ab .ab-lf .tl-decor3 {
    bottom: 0;
    right: 0; }
    @media screen and (min-width: 901px) {
      .ab .ab-lf .tl-decor3 {
        animation: leaf 10s infinite linear; } }
  .ab .ab-lf .tl-decor4 {
    bottom: 0;
    left: 0; }
    @media screen and (min-width: 901px) {
      .ab .ab-lf .tl-decor4 {
        animation: leaf 12s infinite linear; } }
  .ab .ab-lf .image {
    position: relative;
    width: 100%; }
    .ab .ab-lf .image .decor,
    .ab .ab-lf .image .decor1 {
      content: '';
      position: absolute;
      bottom: -15%;
      left: 0;
      width: 180%;
      transform: translateX(-60%);
      z-index: 1; }
      .ab .ab-lf .image .decor-inner,
      .ab .ab-lf .image .decor1-inner {
        display: block;
        padding-top: calc((199 / 950) * 100%);
        position: relative;
        overflow: hidden; }
        .ab .ab-lf .image .decor-inner img,
        .ab .ab-lf .image .decor1-inner img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: contain; }
    .ab .ab-lf .image .decor1 {
      z-index: 4; }
    .ab .ab-lf .image .decor2 {
      content: '';
      position: absolute;
      top: 0;
      left: -20%;
      width: 130%;
      height: 130%;
      transform: translateX(-30%);
      z-index: -1; }
      .ab .ab-lf .image .decor2 img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top; }
    .ab .ab-lf .image .frame {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 100%;
      z-index: 3;
      border-radius: 50%;
      overflow: hidden; }
      .ab .ab-lf .image .frame img {
        animation: Spinner 10s infinite linear;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
        border-radius: 50%;
        overflow: hidden; }
    .ab .ab-lf .image-inner {
      border-radius: 50%;
      border: 2px solid transparent;
      display: block;
      padding-top: calc((1 / 1) * 100%);
      position: relative;
      overflow: hidden;
      z-index: 1; }
      .ab .ab-lf .image-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
        width: calc(100% - 4rem);
        height: calc(100% - 4rem); }

.ab .ab-rt {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: end; }
  .ab .ab-rt .wrapper .title {
    width: 100%;
    margin-bottom: 3.2rem; }
    .ab .ab-rt .wrapper .title .tsub {
      text-align: left;
      color: var(--color-text); }
    .ab .ab-rt .wrapper .title-second {
      margin-bottom: 1.2rem;
      text-align: left;
      font-size: 4rem;
      font-weight: 600;
      line-height: normal; }
      @media screen and (max-width: 800px) {
        .ab .ab-rt .wrapper .title-second {
          margin-bottom: calc(1.2rem / 2); } }
      @media only screen and (max-width: 1200px) {
        .ab .ab-rt .wrapper .title-second {
          font-size: 3.2rem; } }
      @media only screen and (max-width: 600px) {
        .ab .ab-rt .wrapper .title-second {
          font-size: 2.4rem; } }
    @media screen and (max-width: 800px) {
      .ab .ab-rt .wrapper .title {
        margin-bottom: calc(3.2rem / 2); } }
    .ab .ab-rt .wrapper .title-sub {
      text-align: left; }

@media screen and (max-width: 900px) {
  .abu {
    padding-top: 5rem; } }

@media screen and (max-width: 800px) {
  .abu {
    margin-top: -12rem; } }

@media screen and (max-width: 500px) {
  .abu {
    padding-top: 6rem;
    padding-bottom: 6rem; } }

@media screen and (max-width: 425px) {
  .abu {
    margin-top: -10rem; } }

.abu-wr {
  justify-content: space-between; }
  @media screen and (max-width: 900px) {
    .abu-wr.row {
      flex-direction: column;
      align-items: center; }
      .abu-wr.row .col {
        width: 100%; }
      .abu-wr.row .abu-lf .title {
        margin: unset; }
      .abu-wr.row .abu-rt {
        width: 70%; } }
    @media screen and (max-width: 900px) and (max-width: 900px) {
      .abu-wr.row .abu-rt {
        margin-top: -10rem; } }
    @media screen and (max-width: 900px) and (max-width: 677px) {
      .abu-wr.row .abu-rt {
        width: 100%; } }
    @media screen and (max-width: 900px) and (max-width: 500px) {
      .abu-wr.row .abu-rt {
        margin-top: -6rem; } }

.abu-lf {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .abu-lf .wrapper {
    width: 100%;
    height: fit-content; }
    .abu-lf .wrapper .title .tsub {
      text-align: left;
      color: var(--color-text);
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase; }
      @media only screen and (max-width: 425px) {
        .abu-lf .wrapper .title .tsub {
          font-size: 1.4rem; } }
    .abu-lf .wrapper .title-second {
      margin-bottom: 1.2rem;
      font-size: 4.2rem;
      text-align: left;
      font-weight: 600; }
      @media screen and (max-width: 800px) {
        .abu-lf .wrapper .title-second {
          margin-bottom: calc(1.2rem / 2); } }
      @media only screen and (max-width: 1200px) {
        .abu-lf .wrapper .title-second {
          font-size: 3.2rem; } }
      @media only screen and (max-width: 800px) {
        .abu-lf .wrapper .title-second {
          font-size: 2.6rem; } }
    .abu-lf .wrapper .title-sub {
      width: 100%;
      margin-bottom: 3.2rem;
      text-align: left; }
      @media screen and (max-width: 800px) {
        .abu-lf .wrapper .title-sub {
          margin-bottom: calc(3.2rem / 2); } }

.abu-rt {
  position: relative; }
  .abu-rt .wrapper {
    display: block;
    padding-top: calc((523 / 592) * 100%);
    position: relative;
    overflow: hidden;
    position: relative;
    overflow: unset; }
    .abu-rt .wrapper .bg {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      z-index: 0;
      pointer-events: none; }
      .abu-rt .wrapper .bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    @media screen and (max-width: 500px) {
      .abu-rt .wrapper {
        padding-top: unset; } }
    .abu-rt .wrapper .bg {
      display: none; }
      .abu-rt .wrapper .bg img {
        object-fit: contain;
        object-position: bottom; }
    .abu-rt .wrapper .person {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 500px) {
        .abu-rt .wrapper .person {
          position: relative; } }
      .abu-rt .wrapper .person img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom; }
    @media screen and (max-width: 500px) {
      .abu-rt .wrapper .tn {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.8rem;
        margin-top: 2rem;
        row-gap: 1.6rem; } }
    @media screen and (max-width: 1440px) {
      .abu-rt .wrapper .tn .the-num:nth-child(1) {
        top: 34%;
        left: 0%; }
      .abu-rt .wrapper .tn .the-num:nth-child(2) {
        top: 24%;
        right: 2%; }
      .abu-rt .wrapper .tn .the-num:nth-child(3) {
        bottom: 34%;
        right: -2%; }
      .abu-rt .wrapper .tn .the-num:nth-child(4) {
        bottom: 5%;
        right: -6%; } }
    @media screen and (max-width: 1300px) {
      .abu-rt .wrapper .tn .the-num:nth-child(3) {
        bottom: 28%;
        right: 1%; }
      .abu-rt .wrapper .tn .the-num:nth-child(4) {
        bottom: -4%;
        right: 1%; } }
    @media screen and (max-width: 500px) {
      .abu-rt .wrapper .tn .the-num {
        background: unset;
        box-shadow: unset; }
        .abu-rt .wrapper .tn .the-num:nth-child(1), .abu-rt .wrapper .tn .the-num:nth-child(2), .abu-rt .wrapper .tn .the-num:nth-child(3), .abu-rt .wrapper .tn .the-num:nth-child(4) {
          width: 50%;
          position: relative;
          animation: unset;
          top: unset;
          left: unset;
          right: unset;
          bottom: unset;
          padding-left: .8rem;
          padding-right: .8rem; } }
    @media screen and (max-width: 500px) and (max-width: 343px) {
      .abu-rt .wrapper .tn .the-num:nth-child(1), .abu-rt .wrapper .tn .the-num:nth-child(2), .abu-rt .wrapper .tn .the-num:nth-child(3), .abu-rt .wrapper .tn .the-num:nth-child(4) {
        width: 100%; } }
    @media screen and (max-width: 500px) {
          .abu-rt .wrapper .tn .the-num:nth-child(1) .the-num-wr, .abu-rt .wrapper .tn .the-num:nth-child(2) .the-num-wr, .abu-rt .wrapper .tn .the-num:nth-child(3) .the-num-wr, .abu-rt .wrapper .tn .the-num:nth-child(4) .the-num-wr {
            height: 100%; }
            .abu-rt .wrapper .tn .the-num:nth-child(1) .the-num-wr .text, .abu-rt .wrapper .tn .the-num:nth-child(2) .the-num-wr .text, .abu-rt .wrapper .tn .the-num:nth-child(3) .the-num-wr .text, .abu-rt .wrapper .tn .the-num:nth-child(4) .the-num-wr .text {
              text-wrap: balance; } }

.partner .title {
  position: relative;
  z-index: 3; }
  .partner .title .tsub {
    margin-bottom: .4rem; }
  .partner .title-second {
    margin-bottom: 1.2rem; }
    @media screen and (max-width: 800px) {
      .partner .title-second {
        margin-bottom: calc(1.2rem / 2); } }

.partner-bg {
  padding: 20.5rem 0;
  position: relative; }
  .partner-bg .bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none; }
    .partner-bg .bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .partner-bg .bg img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.partner-wr.row {
  margin-bottom: unset; }
  .partner-wr.row .col {
    margin-bottom: unset; }

@media screen and (max-width: 1200px) {
  .partner .partner-item {
    width: calc(100% / 5); } }

@media screen and (max-width: 900px) {
  .partner .partner-item {
    width: calc(100% / 4); } }

@media screen and (max-width: 800px) {
  .partner .partner-item {
    width: calc(100% / 3); } }

@media screen and (max-width: 425px) {
  .partner .partner-item {
    width: calc(100% / 2); } }

.partner .partner-item-wr {
  display: block;
  padding-top: calc((158 / 280) * 100%);
  position: relative;
  overflow: hidden; }
  .partner .partner-item-wr img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain; }

.prd {
  overflow: hidden !important; }
  .prd .title {
    margin-bottom: 17.8rem; }
    @media screen and (max-width: 800px) {
      .prd .title {
        margin-bottom: calc(17.8rem / 2); } }
    @media screen and (max-width: 1440px) {
      .prd .title {
        margin-bottom: 9.8rem; } }
    @media screen and (max-width: 1200px) {
      .prd .title {
        margin-bottom: 2.4rem; } }
    @media screen and (min-width: 2000px) {
      .prd .title {
        margin-bottom: 9.8rem; } }
    .prd .title .tsub {
      margin-bottom: .4rem; }
    .prd .title-second {
      margin-bottom: 1.2rem; }
      @media screen and (max-width: 800px) {
        .prd .title-second {
          margin-bottom: calc(1.2rem / 2); } }
      @media screen and (max-width: 425px) {
        .prd .title-second {
          text-wrap: balance; } }

.prd-item {
  width: 100%; }
  .prd-item-wr {
    width: 100%;
    position: relative;
    border-radius: 3.2rem;
    overflow: hidden; }
    .prd-item-wr .image {
      position: relative; }
      .prd-item-wr .image::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        border-radius: 3.2rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.7) 75%);
        pointer-events: none; }
      .prd-item-wr .image-inner {
        display: block;
        padding-top: calc((740 / 592) * 100%);
        position: relative;
        overflow: hidden; }
        .prd-item-wr .image-inner img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .prd-item-wr .content {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      top: unset;
      padding: 2rem;
      z-index: 2; }
      .prd-item-wr .content .tt {
        margin-bottom: 1.2rem;
        color: #F5F7F7;
        font-size: 2.2rem;
        font-weight: 600;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media screen and (max-width: 800px) {
          .prd-item-wr .content .tt {
            margin-bottom: calc(1.2rem / 2); } }
        @media only screen and (max-width: 1200px) {
          .prd-item-wr .content .tt {
            font-size: 2rem; } }
        @media only screen and (max-width: 800px) {
          .prd-item-wr .content .tt {
            font-size: 1.9rem; } }
      .prd-item-wr .content .des {
        color: #F5F7F7;
        font-size: 1.2rem;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media screen and (max-width: 768px) {
          .prd-item-wr .content .des {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden; } }

.prdSlider {
  width: calc(384/1728 * 100%);
  max-width: 100%;
  margin: auto; }
  @media screen and (max-width: 1800px) {
    .prdSlider {
      width: calc(300/1728 * 100%); } }
  @media screen and (max-width: 1200px) {
    .prdSlider {
      width: calc(100% / 3.08); } }
  @media screen and (max-width: 1200px) {
    .prdSlider {
      width: 100%;
      overflow: hidden; }
      .prdSlider .swiper {
        margin: 0 -.8rem; }
      .prdSlider .swiper-slide {
        padding: 0 .8rem;
        width: calc(100% / 3); } }
    @media screen and (max-width: 1200px) and (max-width: 500px) {
      .prdSlider .swiper-slide {
        width: 100%; } }
  @media screen and (max-width: 768px) {
    .prdSlider {
      width: 100%;
      overflow: hidden; }
      .prdSlider .swiper {
        margin: 0 -.8rem; }
      .prdSlider .swiper-slide {
        padding: 0 .8rem;
        width: calc(100% / 2); } }
    @media screen and (max-width: 768px) and (max-width: 500px) {
      .prdSlider .swiper-slide {
        width: 100%; } }
  @media screen and (max-width: 500px) {
    .prdSlider {
      width: 100%; } }
  .prdSlider .swiper {
    overflow: unset; }
  .prdSlider .swiper-slide {
    position: relative;
    z-index: 5; }
    @media screen and (min-width: 1201px) {
      .prdSlider .swiper-slide {
        transition: .3s linear;
        transition-delay: .3s; }
        .prdSlider .swiper-slide::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 5;
          border-radius: 3.2rem;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
          opacity: 1;
          pointer-events: none;
          transition: .3s ease;
          transition-delay: .3s; }
        .prdSlider .swiper-slide .content {
          transform: translateY(30%);
          opacity: 0;
          transition: .3s ease;
          transition-delay: .6s; } }
  .prdSlider .swiper-slide-active {
    z-index: 6; }
    @media screen and (min-width: 1201px) {
      .prdSlider .swiper-slide-active {
        transform: scale(1.5); } }
    .prdSlider .swiper-slide-active::before {
      opacity: 0; }
    .prdSlider .swiper-slide-active .content {
      transform: translateY(0);
      opacity: 1; }
  .prdSlider .prd-control {
    padding-top: 17.8rem;
    justify-content: center; }
    @media screen and (max-width: 800px) {
      .prdSlider .prd-control {
        padding-top: calc(17.8rem / 2); } }
    @media screen and (max-width: 1400px) {
      .prdSlider .prd-control {
        padding-top: 9.8rem; } }
    @media screen and (max-width: 1200px) {
      .prdSlider .prd-control {
        padding-top: 2.4rem; } }
    @media screen and (min-width: 2000px) {
      .prdSlider .prd-control {
        padding-top: 9.8rem; } }

@media screen and (max-width: 930px) {
  .his-lf {
    display: none; } }

@media screen and (max-width: 930px) {
  .his-rt {
    width: 100%; } }

.his .hisLfSlider {
  height: 66.4rem;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .his .hisLfSlider {
      height: 56.4rem; } }
  @media screen and (max-width: 950px) {
    .his .hisLfSlider {
      height: 46.4rem; } }
  .his .hisLfSlider .swiper {
    overflow: unset; }
  .his .hisLfSlider .swiper-slide {
    transform-origin: left center !important;
    transition: opacity 0.8s ease, margin-left 0.8s ease, color .4s ease !important;
    height: fit-content !important; }
    .his .hisLfSlider .swiper-slide .num {
      position: relative;
      width: fit-content;
      transition: .4s; }
      .his .hisLfSlider .swiper-slide .num::before {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        left: 0;
        bottom: 0;
        background: var(--color-pri);
        transition: .4s;
        transition-delay: .8s; }
  .his .hisLfSlider .swiper-slide-active .num {
    position: relative;
    background: #ffffff;
    color: var(--color-pri);
    transition: unset;
    transition-delay: unset; }
    .his .hisLfSlider .swiper-slide-active .num::before {
      content: '';
      position: absolute;
      width: 100%;
      transition: unset;
      transition-delay: unset; }

.his .his-lf-item {
  --opa: 0.8;
  height: fit-content; }
  .his .his-lf-item .num {
    color: rgba(16, 18, 18, var(--opa));
    font-size: 6.4rem;
    font-weight: 600;
    height: fit-content; }
    @media only screen and (max-width: 1200px) {
      .his .his-lf-item .num {
        font-size: 5.4rem; } }
    @media only screen and (max-width: 800px) {
      .his .his-lf-item .num {
        font-size: 4.4rem; } }
    @media only screen and (max-width: 600px) {
      .his .his-lf-item .num {
        font-size: 3.4rem; } }
    @media only screen and (max-width: 460px) {
      .his .his-lf-item .num {
        font-size: 3rem; } }

.his .his-rt-item-wr {
  position: relative; }
  .his .his-rt-item-wr .image {
    position: relative; }
    @media screen and (max-width: 500px) {
      .his .his-rt-item-wr .image {
        border-radius: 3.2rem;
        overflow: hidden; } }
    @media screen and (max-width: 500) {
      .his .his-rt-item-wr .image {
        border-radius: 3.2rem;
        overflow: hidden; } }
    .his .his-rt-item-wr .image::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.7) 95%);
      z-index: 1;
      pointer-events: none; }
    .his .his-rt-item-wr .image-inner {
      display: block;
      padding-top: calc((740 / 1008) * 100%);
      position: relative;
      overflow: hidden; }
      .his .his-rt-item-wr .image-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .his .his-rt-item-wr .content {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    top: unset;
    padding: 4rem;
    z-index: 3; }
    @media screen and (max-width: 800px) {
      .his .his-rt-item-wr .content {
        padding: 2rem; } }
    @media screen and (max-width: 500px) {
      .his .his-rt-item-wr .content {
        position: relative;
        padding-top: 1.6rem;
        padding-left: unset;
        padding-right: unset; } }
    .his .his-rt-item-wr .content .num {
      color: #F5F7F7;
      font-size: 3.4rem;
      font-weight: 600;
      line-height: normal;
      transform: scale(--sc); }
      @media screen and (min-width: 801px) {
        .his .his-rt-item-wr .content .num {
          display: none; } }
      @media only screen and (max-width: 1200px) {
        .his .his-rt-item-wr .content .num {
          font-size: 2.4rem; } }
      @media screen and (max-width: 500px) {
        .his .his-rt-item-wr .content .num {
          color: var(--color-pri); } }
    .his .his-rt-item-wr .content .tt {
      margin-bottom: 1.2rem;
      color: #F5F7F7;
      font-size: 3.2rem;
      font-weight: 600;
      line-height: normal; }
      @media screen and (max-width: 800px) {
        .his .his-rt-item-wr .content .tt {
          margin-bottom: calc(1.2rem / 2); } }
      @media only screen and (max-width: 1200px) {
        .his .his-rt-item-wr .content .tt {
          font-size: 2.2rem; } }
      @media only screen and (max-width: 600px) {
        .his .his-rt-item-wr .content .tt {
          font-size: 2rem; } }
      @media screen and (max-width: 500px) {
        .his .his-rt-item-wr .content .tt {
          color: var(--color-text); } }
    .his .his-rt-item-wr .content .des {
      color: #F5F7F7;
      font-size: 1.6rem;
      font-weight: 400; }
      @media only screen and (max-width: 425px) {
        .his .his-rt-item-wr .content .des {
          font-size: 1.4rem; } }
      @media screen and (max-width: 500px) {
        .his .his-rt-item-wr .content .des {
          color: var(--color-text-second); } }
      @media screen and (max-width: 425px) {
        .his .his-rt-item-wr .content .des {
          height: 8rem;
          overflow: hidden auto; } }
    .his .his-rt-item-wr .content .mona-content h3 {
      color: #ffffff;
      font-size: 3.2rem; }
      @media only screen and (max-width: 1200px) {
        .his .his-rt-item-wr .content .mona-content h3 {
          font-size: 2.2rem; } }
      @media only screen and (max-width: 600px) {
        .his .his-rt-item-wr .content .mona-content h3 {
          font-size: 2rem; } }
      @media screen and (max-width: 500px) {
        .his .his-rt-item-wr .content .mona-content h3 {
          color: var(--color-text); } }
    .his .his-rt-item-wr .content .mona-content p {
      color: #ffffff;
      font-size: 1.6rem; }
      @media only screen and (max-width: 425px) {
        .his .his-rt-item-wr .content .mona-content p {
          font-size: 1.4rem; } }
      @media screen and (max-width: 500px) {
        .his .his-rt-item-wr .content .mona-content p {
          color: var(--color-text-second); } }

.his .hisRtSlider {
  border-radius: 3.2rem;
  overflow: hidden; }

.rv {
  position: relative; }
  @media screen and (max-width: 425px) {
    .rv {
      overflow: hidden; } }
  @media screen and (min-width: 2000px) {
    .rv {
      padding-bottom: 4rem; } }
  .rv .wave-bg {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -.1rem;
    z-index: 2;
    pointer-events: none; }
    @media screen and (max-width: 1440px) {
      .rv .wave-bg {
        bottom: -2.1rem; } }
    .rv .wave-bg-inner {
      display: block;
      padding-top: calc((233 / 1728) * 100%);
      position: relative;
      overflow: hidden; }
      .rv .wave-bg-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom; }
  .rv .pn {
    content: '';
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (max-width: 500px) {
      .rv .pn {
        display: none; } }
  .rv .title {
    width: 41.1rem;
    margin: unset;
    margin-bottom: 4.8rem; }
    @media screen and (max-width: 800px) {
      .rv .title {
        margin-bottom: calc(4.8rem / 2); } }
    .rv .title .tsub {
      text-align: left; }
      @media screen and (max-width: 425px) {
        .rv .title .tsub {
          text-align: center; } }
    .rv .title-second {
      text-align: left; }
      @media screen and (max-width: 425px) {
        .rv .title-second {
          text-align: center;
          text-wrap: balance; } }
  .rv-wr.row {
    margin-bottom: unset; }
    @media screen and (min-width: 2000px) {
      .rv-wr.row {
        align-items: end; } }
    @media screen and (max-width: 800px) {
      .rv-wr.row {
        flex-direction: column;
        align-items: center; }
        .rv-wr.row .col {
          width: 100%; }
        .rv-wr.row .rv-lf {
          width: 60%; } }
    .rv-wr.row .col {
      margin-bottom: unset; }
  .rv .rv-lf {
    position: relative; }
    .rv .rv-lf .bg {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      z-index: 0;
      pointer-events: none; }
      .rv .rv-lf .bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .rv .rv-lf .bg {
      transform: translateY(-10%) translateX(-50%);
      width: 200%; }
      .rv .rv-lf .bg img {
        object-fit: contain; }
    .rv .rv-lf .pn {
      width: 32.6rem;
      max-width: 100%;
      height: 100%;
      transform: translateX(-80%); }
      @media screen and (max-width: 800px) {
        .rv .rv-lf .pn {
          display: none; } }
      @media screen and (min-width: 2000px) {
        .rv .rv-lf .pn {
          left: 6rem; } }
      .rv .rv-lf .pn-wr {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 4; }
        .rv .rv-lf .pn-wr .pn-decor {
          content: '';
          position: absolute;
          border-radius: 50%;
          animation: k-up-down 3s infinite linear both; }
          .rv .rv-lf .pn-wr .pn-decor img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(1) {
            width: 6.683rem;
            height: 6.683rem; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(2) {
            width: 5.595rem;
            height: 5.595rem; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(3) {
            width: 4.507rem;
            height: 4.507rem; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(4) {
            width: 6.22rem;
            height: 6.22rem; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(5) {
            width: 10.34rem;
            height: 10.34rem; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(6) {
            width: 5.984rem;
            height: 5.984rem; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(1) {
            top: 0;
            right: 2%; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(2) {
            top: 10%;
            left: 0%; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(3) {
            top: 17%;
            left: 50%;
            transform: translateX(-50%); }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(4) {
            top: 24%;
            right: 0; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(5) {
            top: 33%;
            left: 50%;
            transform: translateX(-60%); }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(6) {
            top: 47%;
            left: 0; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(1) {
            animation-delay: .4s; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(2) {
            animation-delay: .8s; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(3) {
            animation-delay: 1.6s; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(4) {
            animation-delay: .8s; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(5) {
            animation-delay: .6s; }
          .rv .rv-lf .pn-wr .pn-decor:nth-child(6) {
            animation-delay: .4s; }
  .rv .rv-lf-item {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: end; }
    .rv .rv-lf-item .image {
      width: 100%;
      height: fit-content; }
      @media (min-width: 501px) and (max-width: 1800px) {
        .rv .rv-lf-item .image {
          margin-bottom: 2rem; } }
      .rv .rv-lf-item .image-inner {
        display: block;
        padding-top: calc((710 / 488) * 100%);
        position: relative;
        overflow: hidden; }
        .rv .rv-lf-item .image-inner img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: contain; }
  @media screen and (max-width: 800px) {
    .rv .rv-rt {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      flex-direction: column-reverse; } }
  .rv .rv-rt-item-wr {
    width: 100%;
    padding: 1rem 2rem;
    margin: 1.6rem 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: 1.6rem; }
  .rv .rv-rt-item .ava {
    width: 8rem;
    height: 8rem; }
    .rv .rv-rt-item .ava img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .rv .rv-rt-item .name {
    flex: 1; }
    .rv .rv-rt-item .name .dname {
      margin-bottom: .4rem;
      color: var(--color-text);
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      text-wrap: nowrap; }
      @media only screen and (max-width: 425px) {
        .rv .rv-rt-item .name .dname {
          font-size: 1.4rem; } }
    .rv .rv-rt-item .name .sname {
      color: #828C8C;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400; }
      @media only screen and (max-width: 600px) {
        .rv .rv-rt-item .name .sname {
          font-size: 1.2rem; } }
  .rv .rv-rt-item.swiper-slide-thumb .rv-rt-item-wr {
    transition: .3s; }
  .rv .rv-rt-item.swiper-slide-thumb-active .rv-rt-item-wr {
    background: rgba(255, 255, 255, 0.01);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05); }
  @media screen and (max-width: 800px) {
    .rv .rvLfSlider {
      display: none; } }
  .rv .rvRtSlider {
    margin-bottom: 13.5rem; }
    @media screen and (max-width: 800px) {
      .rv .rvRtSlider {
        margin-bottom: calc(13.5rem / 2); } }
    @media screen and (min-width: 2000px) {
      .rv .rvRtSlider {
        margin-bottom: 4rem; } }
    @media screen and (max-width: 1440px) {
      .rv .rvRtSlider {
        margin-bottom: 6rem; } }
  @media screen and (max-width: 1440px) and (max-width: 800px) {
    .rv .rvRtSlider {
      margin-bottom: calc(6rem / 2); } }
    @media screen and (max-width: 1440px) {
      .rv .rvRtSlider .rv-rt-item.swiper-slide {
        width: calc(100% / 2.6) !important; } }
    @media screen and (max-width: 1200px) {
      .rv .rvRtSlider .rv-rt-item.swiper-slide {
        width: calc(100% / 3) !important; } }
    @media screen and (max-width: 950px) {
      .rv .rvRtSlider .rv-rt-item.swiper-slide {
        width: calc(100% / 2) !important; } }
    @media screen and (max-width: 580px) {
      .rv .rvRtSlider .rv-rt-item.swiper-slide {
        width: 100% !important; } }
    @media screen and (min-width: 2000px) {
      .rv .rvRtSlider .rv-rt-item.swiper-slide {
        width: calc(100% / 2) !important; } }
  .rv .rv-rt-content {
    width: 100%; }
    .rv .rv-rt-content-wr .quote,
    .rv .rv-rt-content-wr .quote1 {
      width: 4rem;
      height: 4rem; }
      @media screen and (max-width: 800px) {
        .rv .rv-rt-content-wr .quote,
        .rv .rv-rt-content-wr .quote1 {
          width: 3rem;
          height: 3rem; } }
      @media screen and (max-width: 425px) {
        .rv .rv-rt-content-wr .quote,
        .rv .rv-rt-content-wr .quote1 {
          width: 2rem;
          height: 2rem; } }
      .rv .rv-rt-content-wr .quote img,
      .rv .rv-rt-content-wr .quote1 img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .rv .rv-rt-content-wr .quote {
      margin-bottom: 1.2rem; }
      @media screen and (max-width: 800px) {
        .rv .rv-rt-content-wr .quote {
          margin-bottom: calc(1.2rem / 2); } }
    .rv .rv-rt-content-wr .quote1 {
      margin-left: auto; }
    .rv .rv-rt-content-wr .text {
      margin-bottom: 1.2rem;
      color: #3A4040;
      font-size: 2.4rem;
      font-weight: 300; }
      @media screen and (max-width: 800px) {
        .rv .rv-rt-content-wr .text {
          margin-bottom: calc(1.2rem / 2); } }
      @media only screen and (max-width: 1200px) {
        .rv .rv-rt-content-wr .text {
          font-size: 2.2rem; } }
      @media only screen and (max-width: 800px) {
        .rv .rv-rt-content-wr .text {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 425px) {
        .rv .rv-rt-content-wr .text {
          font-size: 1.6rem; } }
  .rv .rvRtContentSlider {
    width: 100%;
    padding-right: var(--half); }
  .rv .rv-rt-control {
    padding-top: 4rem;
    padding-bottom: 17rem; }
    @media screen and (max-width: 800px) {
      .rv .rv-rt-control {
        padding-top: calc(4rem / 2); } }
    @media screen and (max-width: 800px) {
      .rv .rv-rt-control {
        padding-bottom: calc(17rem / 2); } }
    @media screen and (max-width: 1440px) {
      .rv .rv-rt-control {
        padding-top: 2rem;
        padding-bottom: 6rem; } }
    @media screen and (max-width: 425px) {
      .rv .rv-rt-control {
        padding-top: 0;
        padding-bottom: 4rem; } }
    @media screen and (min-width: 2000px) {
      .rv .rv-rt-control {
        padding-bottom: 4rem; } }
    @media screen and (max-width: 800px) {
      .rv .rv-rt-control {
        order: -1; } }

.author-name {
  color: var(--color-text);
  font-size: 1.4rem;
  font-weight: 400;
  padding-right: .8rem; }
  @media only screen and (max-width: 600px) {
    .author-name {
      font-size: 1.2rem; } }

.author .dtime {
  padding-left: .8rem;
  position: relative;
  color: var(--color-text-second);
  font-size: 1.4rem;
  font-weight: 400; }
  @media only screen and (max-width: 600px) {
    .author .dtime {
      font-size: 1.2rem; } }
  .author .dtime::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-left: 1px solid #828C8C; }

@media screen and (min-width: 1201px) {
  .blogs {
    padding-bottom: 2rem; } }

@media screen and (max-width: 425px) {
  .blogs {
    padding-bottom: 2rem; } }

.blogs .head {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 425px) {
    .blogs .head {
      flex-direction: column;
      align-items: start; }
      .blogs .head .title {
        margin-bottom: .8rem !important; }
        .blogs .head .title-second {
          text-wrap: balance; } }
  @media screen and (max-width: 800px) {
    .blogs .head {
      margin-bottom: calc(4.8rem / 2); } }
  .blogs .head .title {
    margin: unset;
    width: 41.1rem; }
    .blogs .head .title .tsub {
      text-align: left; }
    .blogs .head .title-second {
      text-align: left; }

.blogs-wr {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 3.2rem; }
  @media screen and (min-width: 426px) {
    .blogs-wr {
      margin: 0 -.8rem; } }
  @media screen and (max-width: 1200px) {
    .blogs-wr {
      row-gap: 1.6rem; } }
  @media screen and (max-width: 700px) {
    .blogs-wr {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr); } }
  @media screen and (max-width: 425px) {
    .blogs-wr {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(5, 1fr); } }
  @media screen and (min-width: 1233px) {
    .blogs-wr {
      margin: 0 -1.6rem; } }

.blogs .blog-card {
  margin-bottom: unset !important;
  width: 100%; }
  @media screen and (min-width: 426px) {
    .blogs .blog-card:nth-child(1) {
      grid-column: 1/3;
      grid-row: 1/3; }
    .blogs .blog-card:nth-child(2) .author,
    .blogs .blog-card:nth-child(2) .desc, .blogs .blog-card:nth-child(3) .author,
    .blogs .blog-card:nth-child(3) .desc, .blogs .blog-card:nth-child(4) .author,
    .blogs .blog-card:nth-child(4) .desc, .blogs .blog-card:nth-child(5) .author,
    .blogs .blog-card:nth-child(5) .desc {
      display: none; } }
  @media screen and (max-width: 425px) {
    .blogs .blog-card {
      padding: unset; } }

.blogs .blog-card-inner .thumb {
  margin-bottom: 1.2rem;
  border-radius: 1.6rem;
  display: block;
  padding-top: calc((146 / 280) * 100%);
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 800px) {
    .blogs .blog-card-inner .thumb {
      margin-bottom: calc(1.2rem / 2); } }
  .blogs .blog-card-inner .thumb .tag {
    top: 1.2rem;
    left: 1.2rem; }

.blogs .blog-card-name .link {
  font-size: 1.6rem;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: unset; }
  @media only screen and (max-width: 425px) {
    .blogs .blog-card-name .link {
      font-size: 1.4rem; } }

.blogs .blog-card-content .author {
  margin-bottom: .6rems; }

.blogs .blog-card-name {
  margin-bottom: 1.2rem; }
  @media screen and (max-width: 800px) {
    .blogs .blog-card-name {
      margin-bottom: calc(1.2rem / 2); } }

.blogs .blog-card-link {
  display: none; }

.blogs .blog-card .desc {
  font-size: 1.6rem; }
  @media only screen and (max-width: 425px) {
    .blogs .blog-card .desc {
      font-size: 1.4rem; } }

.blogs .blog-card:nth-child(1) .blog-card-inner .thumb {
  margin-bottom: 2.4rem; }
  @media screen and (max-width: 800px) {
    .blogs .blog-card:nth-child(1) .blog-card-inner .thumb {
      margin-bottom: calc(2.4rem / 2); } }
  @media screen and (max-width: 768px) {
    .blogs .blog-card:nth-child(1) .blog-card-inner .thumb {
      padding-top: calc((170 / 280) * 100%); } }
  .blogs .blog-card:nth-child(1) .blog-card-inner .thumb .tag {
    top: 2.4rem;
    left: 2.4rem; }

.blogs .blog-card:nth-child(1) .blog-card-name .link {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .blogs .blog-card:nth-child(1) .blog-card-name .link {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) {
    .blogs .blog-card:nth-child(1) .blog-card-name .link {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 425px) {
    .blogs .blog-card:nth-child(1) .blog-card-name .link {
      font-size: 1.6rem; } }

@media screen and (max-width: 700px) {
  .blogs .blog-card:nth-child(1) {
    margin-bottom: 2rem; } }

.blogs .blog-card:not(:nth-child(1)) .blog-card-inner .thumb {
  padding-top: calc((170 / 280) * 100%); }

.page-home {
  padding-top: unset; }
  .page-home .banner-ctn .content {
    width: 66.8rem;
    max-width: 100%; }
    @media screen and (max-width: 600px) {
      .page-home .banner-ctn .content {
        width: 100%; } }
    .page-home .banner-ctn .content .tsub {
      margin-bottom: 1.2rem;
      width: fit-content;
      padding: 1.2rem;
      color: #F5F7F7;
      font-size: 4.8rem;
      font-weight: 600;
      border-radius: 1.6rem;
      line-height: normal;
      background: radial-gradient(1526.76% 267.9% at -101.2% 117.07%, #0052AA 5.92%, #156CC9 65.73%); }
      @media screen and (max-width: 800px) {
        .page-home .banner-ctn .content .tsub {
          margin-bottom: calc(1.2rem / 2); } }
      @media only screen and (max-width: 1200px) {
        .page-home .banner-ctn .content .tsub {
          font-size: 3.8rem; } }
      @media only screen and (max-width: 800px) {
        .page-home .banner-ctn .content .tsub {
          font-size: 2.8rem; } }
      @media only screen and (max-width: 300px) {
        .page-home .banner-ctn .content .tsub {
          font-size: 2.4rem; } }
    .page-home .banner-ctn .content .title {
      width: 100%; }
      .page-home .banner-ctn .content .title-pri {
        margin-bottom: 2.4rem;
        line-height: normal; }
        @media screen and (max-width: 800px) {
          .page-home .banner-ctn .content .title-pri {
            margin-bottom: calc(2.4rem / 2); } }
      .page-home .banner-ctn .content .title-sub {
        margin-bottom: 3.2rem;
        width: 100%;
        text-align: left; }
        @media screen and (max-width: 800px) {
          .page-home .banner-ctn .content .title-sub {
            margin-bottom: calc(3.2rem / 2); } }
  @media screen and (max-width: 1200px) {
    .page-home .banner-ctn {
      width: 100%;
      height: calc(100vh - var(--size-hd));
      min-height: unset;
      justify-content: end;
      padding-bottom: 6rem; } }
  @media screen and (max-width: 425px) {
    .page-home .banner .bg::after {
      display: none; } }
  .page-home .banner .content {
    position: relative; }
    .page-home .banner .content::before {
      content: '';
      position: absolute;
      top: 0;
      left: -1.6rem;
      right: -1.6rem;
      bottom: 0;
      height: 100%;
      width: calc(100% + 3.2rem);
      background: #ffffff;
      z-index: -1;
      transform: translateY(25%); }
      @media screen and (min-width: 426px) {
        .page-home .banner .content::before {
          display: none; } }
  .page-home .banner .wave-bg1 {
    position: absolute;
    width: 100%;
    top: 0;
    left: -1.6rem;
    right: -1.6rem;
    width: calc(100% + 3.2rem);
    z-index: -1;
    transform: translateY(-50%); }
    @media screen and (min-width: 426px) {
      .page-home .banner .wave-bg1 {
        display: none; } }
    .page-home .banner .wave-bg1-inner {
      display: block;
      padding-top: calc((147 / 339) * 100%);
      position: relative;
      overflow: hidden; }
      .page-home .banner .wave-bg1-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top left; }
  .page-home .partner,
  .page-home .prd,
  .page-home .his {
    padding-bottom: 10rem; }
    @media screen and (max-width: 800px) {
      .page-home .partner,
      .page-home .prd,
      .page-home .his {
        padding-bottom: calc(10rem / 2); } }
    @media screen and (max-width: 1200px) {
      .page-home .partner,
      .page-home .prd,
      .page-home .his {
        padding-bottom: 6rem; } }
  @media screen and (max-width: 1200px) and (max-width: 800px) {
    .page-home .partner,
    .page-home .prd,
    .page-home .his {
      padding-bottom: calc(6rem / 2); } }
  @media screen and (min-width: 426px) {
    .page-home .his {
      padding-top: 5rem; } }
  @media screen and (min-width: 426px) and (max-width: 800px) {
    .page-home .his {
      padding-top: calc(5rem / 2); } }
  .page-home .blogs .blog-card-name:not(:first-child) {
    margin-bottom: 0; }
  @media screen and (min-width: 501px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 6.4rem; } }
  @media only screen and (min-width: 501px) and (max-width: 1200px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 5.4rem; } }
  @media only screen and (min-width: 501px) and (max-width: 800px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 4.4rem; } }
  @media only screen and (min-width: 501px) and (max-width: 600px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 3.4rem; } }
  @media only screen and (min-width: 501px) and (max-width: 460px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 3rem; } }
  @media screen and (max-width: 500px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 500px) and (max-width: 1200px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 500px) and (max-width: 600px) {
    .page-home .the-num:first-child .the-num-wr .tnum .num,
    .page-home .the-num:first-child .the-num-wr .tnum .plus {
      font-size: 2rem; } }
  .page-home .the-num:not(:first-child) .the-num-wr .tnum .num,
  .page-home .the-num:not(:first-child) .the-num-wr .tnum .plus {
    font-size: 3.2rem; }
    @media only screen and (max-width: 1200px) {
      .page-home .the-num:not(:first-child) .the-num-wr .tnum .num,
      .page-home .the-num:not(:first-child) .the-num-wr .tnum .plus {
        font-size: 2.2rem; } }
    @media only screen and (max-width: 600px) {
      .page-home .the-num:not(:first-child) .the-num-wr .tnum .num,
      .page-home .the-num:not(:first-child) .the-num-wr .tnum .plus {
        font-size: 2rem; } }
  @media screen and (min-width: 1440px) {
    .page-home .the-num:nth-child(1) {
      left: -8%; }
    .page-home .the-num:nth-child(2) {
      top: 23%;
      right: -8%; }
    .page-home .the-num:nth-child(3) {
      bottom: 30%;
      right: -15%; }
    .page-home .the-num:nth-child(4) {
      bottom: -1%; } }
  @media screen and (min-width: 2000px) {
    .page-home .the-num:nth-child(2), .page-home .the-num:nth-child(3), .page-home .the-num:nth-child(4) {
      right: -2%; } }
  .page-home .partner {
    padding-bottom: unset; }
  .page-home .blogs {
    padding-top: 10rem;
    position: relative; }
    @media screen and (max-width: 800px) {
      .page-home .blogs {
        padding-top: calc(10rem / 2); } }
    @media screen and (max-width: 500px) {
      .page-home .blogs {
        padding-bottom: 2rem; } }
    .page-home .blogs::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #F6FDF5;
      z-index: -1;
      clip-path: polygon(0 0, 100% 85%, 100% 100%, 0% 100%); }
  .page-home .ft {
    position: relative; }
    .page-home .ft::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #F6FDF5;
      z-index: -1; }
    .page-home .ft-wr .bg {
      z-index: 0;
      pointer-events: none; }
    .page-home .ft-flex {
      position: relative; }

.main {
  overflow: hidden; }

.abc {
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 1200px) {
    .abc {
      padding-left: 1.6rem;
      padding-right: 1.6rem; } }
  .abc .bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none; }
    .abc .bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .abc .bg {
    transition: 1.6s linear;
    opacity: 0;
    width: calc(1027/ 1728 * 100%); }
    .abc .bg img {
      object-fit: contain; }
  .abc-wr {
    padding-top: 10.6rem;
    padding-bottom: 20rem; }
    @media screen and (max-width: 800px) {
      .abc-wr {
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    .abc-wr .image {
      position: relative;
      width: 100.8rem;
      max-width: 100%;
      margin: auto;
      opacity: 0; }
      .abc-wr .image-inner {
        display: block;
        padding-top: calc((568 / 1008) * 100%);
        position: relative;
        overflow: hidden; }
        .abc-wr .image-inner img {
          border-radius: 3.2rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
      @media screen and (min-width: 801px) {
        .abc-wr .image .abc-card {
          content: '';
          position: absolute;
          right: 4rem;
          bottom: 0;
          transform: translateY(50%);
          width: calc(522/ 1008 * 100%); } }
      @media screen and (max-width: 800px) {
        .abc-wr .image .abc-card {
          margin-top: 1.6rem;
          width: 100%; } }
      .abc-wr .image .abc-card-wr {
        padding: 4rem;
        border-radius: 3.2rem;
        background: #FFF;
        box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08); }
        .abc-wr .image .abc-card-wr .title {
          width: 100%; }
          .abc-wr .image .abc-card-wr .title-second {
            margin-bottom: 1.6rem;
            text-align: left;
            font-size: 4.2rem;
            font-weight: 600; }
            @media screen and (max-width: 800px) {
              .abc-wr .image .abc-card-wr .title-second {
                margin-bottom: calc(1.6rem / 2); } }
            @media only screen and (max-width: 1200px) {
              .abc-wr .image .abc-card-wr .title-second {
                font-size: 3.2rem; } }
            @media only screen and (max-width: 800px) {
              .abc-wr .image .abc-card-wr .title-second {
                font-size: 2.6rem; } }
          .abc-wr .image .abc-card-wr .title-sub {
            text-align: left; }
  .abc.is-inview .bg {
    opacity: 1; }
  .abc.is-inview .abc-wr .image {
    opacity: 1;
    animation: bounceInRight 1.6s forwards; }

.bg-f {
  position: relative; }
  .bg-f .bgf {
    padding-top: 15rem;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none; }
    .bg-f .bgf img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top; }

.exp {
  position: relative; }
  @media screen and (max-width: 800px) {
    .exp-wr.row {
      flex-direction: column;
      align-items: center; }
      .exp-wr.row .col {
        width: 70%; } }
    @media screen and (max-width: 800px) and (max-width: 500px) {
      .exp-wr.row .col {
        width: 100%; } }
  .exp-lf {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .exp-lf .wrapper {
      padding: 4rem;
      width: 52.2rem;
      max-width: 100%;
      border-radius: 3.2rem;
      background: #FFF;
      box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08); }
      .exp-lf .wrapper .title-second {
        margin-bottom: 1.6rem;
        text-align: left;
        font-weight: 600;
        font-size: 3.6rem; }
        @media screen and (max-width: 800px) {
          .exp-lf .wrapper .title-second {
            margin-bottom: calc(1.6rem / 2); } }
        @media only screen and (max-width: 1200px) {
          .exp-lf .wrapper .title-second {
            font-size: 3.2rem; } }
        @media only screen and (max-width: 600px) {
          .exp-lf .wrapper .title-second {
            font-size: 2rem; } }
      .exp-lf .wrapper .title-sub {
        text-align: left; }
  .exp-rt {
    position: relative; }
    .exp-rt .tl-decor1, .exp-rt .tl-decor2, .exp-rt .tl-decor3, .exp-rt .tl-decor4 {
      content: '';
      position: absolute;
      z-index: 2; }
    .exp-rt .tl-decor1 {
      top: 0;
      left: 0; }
    .exp-rt .tl-decor2 {
      top: 0;
      right: 0; }
    .exp-rt .tl-decor3 {
      bottom: 0;
      right: 0; }
    .exp-rt .tl-decor4 {
      bottom: 0;
      left: 0; }
    .exp-rt .image {
      width: 44.8rem;
      max-width: 100%;
      position: relative; }
      @media screen and (max-width: 425px) {
        .exp-rt .image {
          overflow: hidden; } }
      @media screen and (max-width: 1200px) {
        .exp-rt .image {
          width: 100%; } }
      .exp-rt .image-inner {
        display: block;
        padding-top: calc((1 / 1) * 100%);
        position: relative;
        overflow: hidden;
        animation: Spinner 18s linear infinite;
        border-radius: 50%;
        overflow: hidden; }
        .exp-rt .image-inner img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
          overflow: hidden; }
      .exp-rt .image .tn .the-num:nth-child(1) {
        top: -8%;
        left: 78%; }
        @media screen and (max-width: 425px) {
          .exp-rt .image .tn .the-num:nth-child(1) {
            top: 1%; } }
      .exp-rt .image .tn .the-num:nth-child(2) {
        top: 32%;
        left: 78%; }
      .exp-rt .image .tn .the-num:nth-child(3) {
        top: 72%;
        left: 78%; }
        @media screen and (max-width: 425px) {
          .exp-rt .image .tn .the-num:nth-child(3) {
            top: 64%; } }
      @media screen and (max-width: 1232px) {
        .exp-rt .image .tn .the-num:nth-child(1), .exp-rt .image .tn .the-num:nth-child(2), .exp-rt .image .tn .the-num:nth-child(3) {
          left: 55%; } }
  @media screen and (max-width: 1232px) and (max-width: 425px) {
    .exp-rt .image .tn .the-num:nth-child(1), .exp-rt .image .tn .the-num:nth-child(2), .exp-rt .image .tn .the-num:nth-child(3) {
      left: 40%; } }

.page-about .partner .title {
  width: 50.7rem; }
  @media screen and (max-width: 450px) {
    .page-about .partner .title-second {
      text-wrap: balance; } }

@media screen and (max-width: 1200px) {
  .page-about .partner-bg {
    padding-top: 6rem;
    padding-bottom: 6rem; } }

.page-about .blogs {
  padding-top: var(--ss-pd); }
  @media screen and (max-width: 500px) {
    .page-about .blogs {
      padding-bottom: var(--ss-pd); } }
