// side
.fsocial {
    position: fixed;
    // bottom: 13rem;
    bottom: 3rem;
    right: 3rem;
    z-index: 49;

    // @include max(1440px) {
    //     bottom: 9rem;
    // }

    @include max(768px) {
        right: 1.5rem;
    }

    transition: 0.5s linear;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .icon {
        height: 5rem;
        width: 5rem;
        display: flex;
        margin-top: 1.2rem;
        transition: 0.3s ease-in-out;
        padding: 1rem 0.8rem;
        position: relative;
        // border: 0.2rem solid white;
        border-radius: 100rem;
        background: white;

        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        @include max(1440px) {
            width: 4rem;
            height: 4rem;
            padding: 1rem 0.5rem;
        }

        img {
            border-radius: unset;
            object-fit: contain;
        }



        .circlejs {
            animation: growAndFade 3s infinite ease-out;
            background-color: dodgerblue;
            border-radius: 50%;
            opacity: 0;
            position: absolute;
            height: 6.7rem;
            width: 6.7rem;
            left: 50%;
            top: 50%;
            z-index: -1;
        }

        .circle1 {
            animation-delay: 1s;
        }

        .circle2 {
            animation-delay: 2s;
        }

        .circle3 {
            animation-delay: 3s;
        }

        // &:before {
        //     content: "";
        //     position: absolute;
        //     height: 100%;
        //     width: 100%;
        //     inset: 0;
        //     z-index: 0;
        //     border-radius: 100rem;
        // }

        @include hover {
            transform: scale(1.1);
        }

        img {
            width: 100%;
            height: auto;
            animation: shake-lr 4s linear infinite both;
        }

        &:nth-child(1) {
            @include min(1441px) {
                padding: unset;
            }

            background: $color-white;
            @include mid-flex;
            background: #2196F3;

            img {
                // color: $color-pri;
                width: 3rem;
                height: 3rem;
                object-fit: contain;
                animation: phoneRing 1.5s infinite linear;
            }
        }

        &:nth-child(2) {
            @include min(1441px) {
                padding: unset;
            }

            background: #2196F3;
            @include mid-flex;

            img {
                @include min(1441px) {
                    width: 4rem;
                    height: 4rem;
                }
            }
        }

        //   &:nth-child(1) {
        //     &:before {
        //       background-color: $color-pri;
        //     }
        //     .circlejs {
        //       background-color: #fff;
        //     }
        //   }
        //   &:nth-child(2) {
        //     &:before {
        //       background-color: rgba(0, 104, 255, 0.2);
        //     }
        //     .circlejs {
        //       background-color: rgba(0, 104, 255);
        //     }
        //   }


    }
}


// text animation
.text-hori {
    .char {
        transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
        transform: translateX(4rem);
        opacity: 0;
    }

    &.is-inview {
        .char {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.text-verti {
    .char {
        transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
        transform: translateY(4rem);
        opacity: 0;
    }

    &.is-inview {
        .char {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.text-scale {
    .char {
        transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
        transform: scale(0);
        opacity: 0;
    }

    &.is-inview {
        .char {
            transform: scale(1);
            opacity: 1;
        }
    }
}

// count
.count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid $color-border-01;
    border-radius: 0.8rem;
    width: fit-content;
    padding: 0.5rem;
    gap: 1rem;

    &-btn {
        width: 3rem;
        height: 3rem;
        @include mid-flex;
        cursor: pointer;
        border-radius: 0.4rem;
        transition: 0.4s;

        i {
            font-size: 1rem;
        }

        @include hover {
            background: var(--color-pri);
            color: white;
        }
    }
}

// mona-content
// .mona-content {
//     h3 {
//         @include fz-36;
//         font-weight: 600;
//     }

//     .note {
//         padding-left: 1rem;
//         border-left: 0.2rem solid var(--color-pri);

//         p {
//             color: var(--color-pri);
//             font-style: italic;
//             @include fz-14;
//         }
//     }

//     .quote {
//         padding-left: 3rem;
//         border-left: 0.2rem solid #F2794D;

//         p {
//             color: #F2794D;
//             font-style: italic;
//             @include fz-20;
//         }
//     }

//     blockquote {
//         margin: 0 !important;
//         padding-left: 3rem !important;
//         border-left: 0.2rem solid #F2794D !important;
//         background: transparent !important;

//         &::before {
//             content: none !important;
//         }

//         p {
//             color: #F2794D !important;
//             font-style: italic !important;
//             font-size: 2rem !important;
//         }
//     }

//     .wp-block-flex {
//         display: flex;
//         flex-wrap: wrap;
//         gap: 2.4rem;

//         @include max(650px) {
//             gap: 1.2rem;
//         }

//         .wp-block-image {
//             width: calc(100% / 3 - (2.4rem - (2.4rem / 3)));

//             @include max(650px) {
//                 width: calc(100% / 2 - (1.2rem - (1.2rem / 2)));
//             }
//         }
//     }

//     .wp-block-image {
//         border-radius: 1.6rem;

//         img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//         }
//     }
// }

// hover
.hover-rec {
    &::after {
        @include pseudo;
        @include full(0rem, 0rem, 0rem, 0rem);
        border: 0.1rem solid transparent;
        border-radius: inherit;
        transition: 0.2s;
        pointer-events: none;
    }

    @include hover {
        &::after {
            @include full(2%, 2%, 2%, 2%);
            border-color: var(--color-second);
        }
    }
}

// title
.title {
    width: 63.7rem;
    max-width: 100%;
    margin: auto;

    .tsub {
        color: #2167B2;
        text-align: center;
        @include fz-16;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
    }

    .title-pri {
        color: var(--color-text);
        @include fz-48;
        font-weight: 600;
        line-height: normal;

        &.t-white {
            color: white;
        }
    }

    .title-second {
        color: $color-text;
        text-align: center;
        @include fz-42;
        font-weight: 500;
        position: relative;
        line-height: normal;
        text-wrap: pretty;
    }

    .title-sub {
        color: $color-text-second;
        text-align: center;
        @include fz-16;
        font-weight: 400;
        margin: auto;
        text-wrap: pretty;
    }
}

// checker
.checker {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    user-select: none;

    .sqr {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        position: relative;
        display: block;

        &::after {
            @include pseudo;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/check-empty.svg");
            @include default-background;
            left: 0;
            top: 0;
        }
    }

    .circle {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        position: relative;
        display: block;

        &::after {
            @include pseudo;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/radio-empty.svg");
            @include default-background;
            left: 0;
            top: 0;
        }
    }

    .txt {
        display: block;

        @include max(500px) {
            font-size: 1.4rem;
        }
    }

    input {
        display: none;
    }

    input:checked~.sqr {
        &::after {
            background-image: url("../assets/images/check-fill.svg");
        }
    }

    input:checked~.circle {
        &::after {
            background-image: url("../assets/images/radio-fill.svg");
        }
    }
}

// list

// .prod-list {
//     @include max(1200px) {
//         --cg: 1.2rem;
//         --rg: 1.2rem;
//     }

//     @include max(700px) {
//         .col {
//             width: 50%;
//         }
//     }
// }

// // item
// .prod-item {
//     height: 100%;

//     .inner {
//         display: flex;
//         flex-direction: column;
//         border: 0.1rem solid #EDEDED;
//         border-radius: 0.4rem;
//         height: 100%;

//         .img {
//             width: 100%;

//             &-inner {
//                 @include imgPd(310, 384);
//                 border-radius: 0.4rem;
//                 transition: 0.4s;

//                 img {
//                     @include imgContain;
//                 }
//             }
//         }

//         .info {
//             padding: 1rem;
//             overflow: hidden;
//             max-height: 7rem;
//             transition: 0.4s;
//             margin-top: auto;

//             &-flex {
//                 display: flex;
//                 align-items: center;
//                 padding-bottom: 0.8rem;
//                 position: relative;
//                 margin-bottom: 1rem;

//                 &::after {
//                     @include pseudo;
//                     width: 0%;
//                     height: 0.2rem;
//                     background: var(--color-pri);
//                     bottom: 0;
//                     left: 0;
//                     transition: 0.4s;
//                 }
//             }

//             &-tt {
//                 @include fz-20;
//                 font-weight: 600;
//                 color: var(--color-text);
//                 line-height: 1.2;
//                 @include lc(2);

//                 @include max(850px) {
//                     font-size: 1.6rem;
//                 }

//                 @include max(700px) {
//                     font-size: 1.4rem;
//                 }
//             }

//             i {
//                 width: 3.6rem;
//                 height: 3.6rem;
//                 display: block;
//                 flex-shrink: 0;
//                 font-size: 2.4rem;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;

//                 @include max(700px) {
//                     width: 2rem;
//                     height: 2rem;
//                     font-size: 1.5rem;
//                 }
//             }

//             &-des {
//                 @include lc(3);
//                 line-height: 1.2;

//                 @include max(700px) {
//                     font-size: 1.2rem;
//                 }
//             }
//         }

//         @include hover {
//             .img {
//                 &-inner {
//                     @include imgPd(211, 384);
//                 }
//             }

//             .info {
//                 max-height: 15rem;

//                 &-flex {
//                     &::after {
//                         width: 100%;
//                     }
//                 }
//             }
//         }

//         @include max(1200px) {
//             .img {
//                 &-inner {
//                     @include imgPd(310, 384);
//                 }
//             }

//             .info {
//                 max-height: 15rem;

//                 &-flex {
//                     &::after {
//                         width: 100%;
//                     }
//                 }
//             }
//         }
//     }
// }