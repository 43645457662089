// @import url("../assets/font/Themify/stylesheet.css");
@import url("../assets/font/Mulish/stylesheet.css");
@import url("../assets/font/Roboto/stylesheet.css");
@import url("../assets/font/SVN-Poppins/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: $color-text;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;
    @include font-pri;
}

button {
    padding: 0;
    background-color: transparent;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include font-pri;
}

.main {
    min-height: 68vh;

    // padding-top: var(--size-hd);
    @include max(1200px) {
        padding-top: var(--size-hd);
        // padding-top: calc(var(--size-hd) / 2);
    }
}

.container {
    width: 100%;
    max-width: 124.8rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.6rem;
}

.ctn-f {
    width: 100%;
    max-width: 100%;
    height: 100%;
    // margin: 0 auto;
    padding: 0;

    @include max(1248px) {
        padding: 0 1.6rem;
    }
}

.ctn {
    // width: 100%;
    // max-width: var(--recontainer);
    // max-width: calc(124.8rem + var(--half));

    @include min(1249px) {
        width: var(--recontainer);
        max-width: unset;
    }

    @include max(1248px) {
        width: 100%;
        max-width: 124.8rem;
    }
    
    height: 100%;
    margin: 0;
    margin-left: auto;
    padding: 0 1.6rem;
}

.over {
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        display: none;
    }
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}

.load-item {
    display: none;
}

.show-hide-toggle {
    cursor: pointer;
}

img {
    max-width: 100%;
    border-radius: inherit;
    height: auto;
}

.global-title {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}

input,
textarea {
    border: none;
    background-color: transparent;
    outline: none;
}

.collapse-head {
    cursor: pointer;
}

.collapse-body {
    display: none;
}

.ss-pd-t {
    padding-top: var(--ss-pd);
}

.ss-pd-b {
    padding-bottom: var(--ss-pd);
}

.ss-pd {
    padding: var(--ss-pd) 0;
}