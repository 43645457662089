.content-blog-wr {
    padding: 4rem 0 6rem;

    .inner {
        .hd {
            .mobile {
                display: block !important;
                position: unset;
                width: 100%;
                min-width: 100%;
                transform: translateX(0);
            }
        }
    }
}

.aside {
    position: sticky;
    top: 8rem;
    height: 100%;
    border-radius: 0.4rem;
    border: 0.1rem solid $color-grey-second;
    background-color: $color-white;
    // overflow: scroll auto;
    // max-height: 90vh;

    @include max(991px) {
        width: 25rem;
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 0;
        padding-top: var(--size-hd);
        transform: translate(-100%, -50%);
        transition: .4s;

        &-wr {
            ul {
                li {
                    font-size: 1.4rem !important;
                    text-wrap: pretty;
                }
            }
        }
    }

    @include max(500px) {
        width: 50%;
    }

    &.open {
        transform: translate(0, -50%);
    }

    .bar {
        position: absolute;
        top: 50%;
        transform: translate(100%, -50%);
        right: 0;
        width: 3.5rem;
        height: 3.2rem;
        background-color: $color-white;
        box-shadow: 1rem 0rem 3rem 0rem rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;

        @include min(992px) {
            display: none;
        }

        .line {
            display: block;
            width: 100%;
            height: 0.16rem;
            background-color: $color-pri;
        }
    }

    .inner {
        padding: 2.4rem;
        // min-height: 80vh;
        @include scroll-bar(0.5rem, 0.5rem, transparent, $color-pri);

        @include max(1200px) {
            height: 100%;
            overflow: scroll;
            padding: 1.4rem;
        }
    }

    .aside-item {
        border-bottom: 0.1rem solid $color-border-10;
    }

    .aside-item.active {
        .aside-item-hd {
            .tt {
                color: $color-pri;
            }

            .ic {
                transform: rotate(180deg);

                i {
                    color: $color-pri;
                }
            }
        }
    }

    .tt-aside {
        color: $color-second;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1.6rem;
    }

    .aside-item-hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem 0;
        cursor: pointer;

        .tt {
            color: $color-second;
            font-weight: 600;
        }

        .ic {
            transition: .4s;

            i {
                color: #1E1E1E;
            }
        }
    }

    .aside-item-bd {
        padding: 0 1.6rem 1.6rem 1.6rem;

        // .content-list{
        //     padding-bottom: 1.6rem;
        // }
        .content-item {
            margin-bottom: 0.8rem;
            transition: .4s;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                color: $color-pri;
            }
        }
    }
}

.content-wr {
    @include max(991px) {
        width: calc(((8/12)*100%));
    }

    @include max(768px) {
        width: 100%;
    }

    .inner {
        border: 0.1rem solid $color-border-10;
        padding: 2rem;
        border-radius: 0.4rem;

        @include max(1200px) {
            padding: 1.4rem;
        }
    }

    .info-content {
        display: flex;
        align-items: center;
        // gap: 3rem;
        gap: 1.6rem;

        margin-bottom: 2.4rem;

        @include max(1200px) {
            gap: 1.5rem;
        }

        @include max(500px) {
            flex-wrap: wrap;
            row-gap: 1rem;
        }

        .info {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            // padding-right: 0.75rem;
            padding-right: 1.6rem;
            border-right: 0.1rem solid $color-border-10;

            &:last-child {
                border-right: unset;
            }

            @include max(330px) {
                border-right: unset;
            }

            .ic {
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .txt {
                font-weight: 500;

                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .tt-sec {
        color: $color-black-four;
        font-weight: 600;
    }

    .main-content.wp-content {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;

        h2 {
            color: $color-black-five ;
            font-size: 2rem;
            font-weight: 600;
            line-height: 150%;
        }

        p {
            font-size: 1.6rem;
            line-height: 150%;
        }

        .caption {
            font-size: 1.4rem;
            padding-left: 1.6rem;
            border-left: 0.2rem solid $color-pri;
        }

        .img {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .content-act {
        margin-top: 3.2rem;
        padding-top: 3.2rem;
        border-top: 0.1rem solid #E0E0E0;

        .content-act-wr {
            .txt {
                color: $color-text-five;
                margin-bottom: 1.6rem;
            }
        }
    }

    .share {
        margin-bottom: 1.6rem;

        .share-act {
            display: flex;
            align-items: center;
            gap: 1rem;

            .link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 50%;
                background-color: #666666;
                transition: .4s;

                i {
                    color: $color-white;
                    font-size: 1.6rem;
                }

                &:hover {
                    background-color: $color-pri;
                }
            }
        }
    }

    .tag-blog {
        .tag-act {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .link {
                display: flex;
                padding: 0.4rem 0.8rem;
                color: $color-text-five;
                background-color: #F0F0F0;
                transition: .4s;

                &:hover {
                    color: $color-pri;
                    background-color: $color-red-opa-3;
                }
            }
        }
    }
}

.side-blog {
    position: sticky;
    top: 8rem;
    height: 100%;

    @include max(991px) {
        width: calc(((4/12)*100%));
    }

    @include max(768px) {
        width: 100%;
    }

    >.inner {
        padding: 2rem;
        border: 0.1rem solid $color-border-10;
        border-radius: 0.4rem;

        @include max(1200px) {
            padding: 1.4rem;
        }
    }

    .tt-sec {
        margin-bottom: 2.4rem;
        text-wrap: balance;
    }
}

.info-blog {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.4rem;

    .info-blog-item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            padding-right: 1.6rem;
            margin-right: 1.6rem;
            border-right: 0.1rem solid #E1E8E8;
        }

        &:first-child {
            .txt {
                font-size: 1.6rem;
                color: #101212;
                font-weight: 500;
            }
        }
    }

    .ic {
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        margin-right: 0.8rem;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .txt {
        font-size: 1.4rem;
        color: #606969;
    }
}

.blog-dt-content {
    @include max(800px) {
        width: 100%;
    }

    .mona-content {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #101212;
            font-weight: 500;
        }

        h1 {
            @include fz-32;
        }

        h2 {
            @include fz-18;
        }

        p {
            color: $color-text-second;
            @include fz-16;
        }

        .img-full {}

        img {
            border-radius: 3.2rem;
            // @include imgPd(402, 696);
            // @include imgCover;
        }

        .wp-caption {
            border: unset;
        }

        iframe {
            aspect-ratio: 16/9;
            width: 100%;
        }
    }
}

.blog-share {
    margin-top: 4.8rem;

    @include max(1200px) {
        margin-top: 2.8rem;
    }

    .txt {
        color: #3A4040;
        margin-bottom: 1.6rem;
    }

    .social-list {
        display: flex;
        gap: 0.8rem;

        .social-link {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.page-blog-dt {
    padding-top: calc(var(--size-hd) + 2.4rem);
    overflow: unset !important;

    // @include max(1200px) {
    //     padding-top: calc(var(--size-hd) / 2);
    // }

    .blog-dt-wr {
        @include max(800px) {
            flex-direction: column;
        }
    }

    // .blog-aside {
    //     @include max(1200px) {
    //         position: unset;
    //         height: 100%;
    //     }
    // }

    .benefit-item-wr {
        @include max(575px) {
            justify-content: start !important;
        }
    }

    .blog-dt-sect {
        @include min-max(500px, 800px) {
            margin-bottom: -6rem;
            padding-bottom: unset;
        }
    }
}