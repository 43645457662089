.banner {
    position: relative;

    .wave-bg {
        position: absolute;
        // height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: -.1rem;
        z-index: 1;

        &-inner {
            @include imgPd(233, 1728);

            img {
                @include imgCover;
                object-position: bottom;
            }
        }

    }

    

    .bg {
        position: absolute;
        height: 100%;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after {
            @include pseudo;
            height: 100%;
            width: 100%;

            // @include max(500px) {
            //     width: 150%;
            // }

            top: 0;
            left: 0;
            background: linear-gradient(90deg, #FFF -5.76%, #FFF 27.83%, rgba(255, 255, 255, 0.74) 41.27%, rgba(255, 255, 255, 0.00) 54.71%);
        }
    }

    &-wr {}

    &-ctn {
        min-height: calc(100vw*(628/1728));
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 2;
        padding: 4rem 0;
        padding-top: calc(var(--size-hd) + 2.4rem);
    }

    .title {
        margin: unset;
        width: 100%;

        .title-pri {
            color: $color-text;
            @include fz-38;
            // line-height: 120%;
        }
    }

    .breadcrumb {
        .container {
            padding: 0;
        }

        &-list {}

        &-item {
            color: $color-text;

            &:last-child {
                color: $color-text;
                font-weight: 600;
            }
        }
    }
}

.bn {


    .bn-item {
        width: 100%;
        height: fit-content;

        @include min(1201px) {
            height: 100vh;
        }

        .image {

            &-inner {

                @include min(1201px) {
                    height: 100vh;
                }

                width: 100%;

                @include max(1200px) {
                    @include imgPd(800, 1728);
                }

                img {
                    @include max(1200px) {
                        @include imgCover;
                    }

                    @include min(1201px) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .bnSlider {
        position: relative;

        .swiper {
            @include min(1201px) {
                height: 100vh;
            }
        }
    }



    .bn-control {
        @include max(1200px) {
            // display: none;
        }

        @include min(1201px) {

            @include pseudo;
            right: 3.6rem;
            top: 50%;
            z-index: 4;
            transform: translateY(-50%);

            .swiper-control {
                display: block;
                @include flex-col;
                justify-content: space-between;
                height: 20.3rem;
            }

            .swiper-control-btn {
                background: transparent;
                border: unset;

                &::after {
                    display: none;
                }

                @include hover {
                    scale: 1.1;
                }
            }

            .swiper-prev {
                // padding-bottom: 5rem;
            }

            .swiper-next {
                margin-top: unset;
                height: fit-content;
            }

            .swiper-button-disabled {
                // display: block;
                opacity: unset;
                width: 100%;
                height: fit-content;
                @include mid-flex;
            }
        }

        @include max(1200px) {
            .swiper-control {
                .swiper-control-btn {
                    @include posi-vertical;
                    background: transparent;
                    border: unset;

                    img {
                        rotate: -90deg;
                    }
                }

                .swiper-prev {

                    left: 1rem;
                }

                .swiper-next {

                    right: 1rem;
                }
            }
        }
    }

    .bn-pagi {
        @include max(1200px) {
            display: none;
        }

        margin: 0 auto;
        @include pseudo;
        right: 4.6rem;

        @include max(1280px) {
            right: 4.2rem;
        }

        z-index: 5;
        width: 1.9rem !important;

        .swiper-pagination-bullet {
            text-align: center;
            width: 1.5rem !important;
            // padding: 0 1rem;
            height: 1rem;

            // css num
            width: fit-content;
            // color: rgba(255, 255, 255, 0.50);
            color: $color-white;
            background-color: transparent;
            text-align: center;
            font-family: "Roboto Slab";
            @include fz-16;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .swiper-pagination-bullet-active {
            width: 1.5rem;
            /* Larger width for active bullet */
            background-color: transparent;
            /* Different background color for active bullet */
        }

        .swiper-pagination-bullet-active-main {
            position: relative;

            // &::before {
            //     @include pseudo;
            //     top: 50%;
            //     right: 0;
            //     transform: translateX(100%)translateY(-50%);
            //     background: url("../assets/images/ic-circle.svg");
            //     width: .9rem;
            //     height: .9rem;
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     z-index: 6;
            // }

        }
    }


}