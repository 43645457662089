// --------------------------------------
.mobile {
    position: fixed;
    z-index: -1 !important;
    top: $h-top;
    left: 0;
    bottom: 0;
    min-width: 50rem;
    width: 50rem;
    background-color: white;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
    @include transition(0.4s, linear);
    transform: translateX(-110%);
    border-top: 1px solid $color-border;

    @include max($mobile) {
        width: 100%;
        min-width: initial;
    }

    &.sticky {
        top: $h-top !important;
    }

    &.open {
        transform: translateX(0);
    }

    .search-mona {
        padding: 0 1.5rem;
        margin: 1.6rem 0;
    }

    @include min($res-mobile-min) {
        display: none;
    }

    &-con {
        width: 100%;
        height: 100%;
        @include flex;
        flex-direction: column;
        overflow-y: auto;
    }

    &-heading {
        font-size: 1.8rem;
        line-height: 1.5;
        color: $color-pri;
        font-weight: 700;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    &-overlay {
        position: fixed;
        z-index: -1 !important;
        top: $h-top;
        left: 0;
        bottom: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        @include transition(0.6s, ease);
        opacity: 0;
        visibility: hidden;
        transform: translateX(-110%);
        border-top: 1px solid $color-border;

        &.sticky {
            top: $h-top !important;
        }

        &.open {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }

        @include min($res-mobile-min) {
            display: none;
        }
    }

    &-wr {
        padding-bottom: 6rem;
        position: relative;
    }

    &-list {
        margin-bottom: 2rem;
    }

    &-content {
        padding: 2.4rem 1.6rem;

        .social {
            &-list {
                margin-bottom: 2.4rem;
                gap: 1.6rem;
            }

            &-link {
                img {
                    filter: var(--filter);
                }
            }
        }
    }

    &-title {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1.6rem;
    }

    &-mxh {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4rem;
        @include flex;
        align-items: center;
        border-top: 1px solid $color-border;

        &-link {
            @include mid-flex;
            flex: 1;
            height: 100%;
            background-color: white;
            cursor: pointer;

            &:not(:first-child) {
                border-left: 1px solid $color-border;
            }

            .icon {
                color: $color-white;
            }
        }
    }

    &-text {
        @include fz-14;
        display: block;
        color: $color-pri;
        word-break: break-word;
        font-weight: 500;

        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }
    }

    &-account {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }

    &-re {
        @include flex;
        align-items: center;

        &-link {
            @include fz-16;
            color: $color-pri;
            text-decoration: underline;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }

    &-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
        border: 1px solid $color-pri;
        @include mid-flex;
        cursor: pointer;
        background-color: white;

        &:hover .icon {
            transform: rotate(360deg);
        }

        .icon {
            transition: 0.6s ease-in-out;
            font-weight: 500;
            font-size: 1.8rem;
            color: $color-pri;
        }
    }

    &-fl {
        .fw-6 {
            margin-bottom: 0.8rem;
            margin-bottom: 0.8rem;
            display: inline;
            width: fit-content;
            font-size: 1.4rem;
        }

        .mobile-text {
            display: inline;
            font-size: 1.4rem;
        }

        .dot {
            display: none;
        }
    }
}