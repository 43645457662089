.breadcrumb {
    padding: 1.2rem 0;

    &-wrapper {
        @include min($res-mobile) {
            width: fit-content;
        }
    }

    &-list {
        @include flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        gap: .8rem;
    }

    &-item {
        @include fz-16;
        display: flex;
        align-items: center;
        gap: .8rem;
        font-weight: 400;
        color: var(--color-text);

        &:not(:first-child) {
            &::before {
                display: inline-block;
                content: '/';
                border-radius: 100rem;
                vertical-align: middle;
            }
        }

        &:last-child {
            color: var(--color-text);
            pointer-events: none;
            font-weight: 600;
        }

        @include hover {
            text-decoration: underline;
        }
    }

    &-link {
        color: inherit;
        transition: .2s ease-in-out;
    }
}