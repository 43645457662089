.header-nav {
    .menu {
        &-nav {
            height: 100%;

            >.menu-list {
                height: 100%;
                justify-content: center;

                >.menu-item {
                    height: 100%;
                    @include flex;
                    align-items: center;

                    &:not(:first-child) {
                        margin-left: 3.2rem;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 3.2rem;
                        @include posi-horizontal;
                        height: 0.2rem;
                        // background-color: $color-pri;
                        // background: radial-gradient(circle, hsla(151, 100%, 24%, 1) 0%, hsla(101, 62%, 48%, 1) 100%);
                        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 120, 62, 1) 50%, rgba(255, 255, 255, 1) 100%);
                        width: 0;
                        // border-radius: 1rem;
                        @include transitionRe;
                    }

                    @include min($res-mobile-min) {
                        &:hover {
                            &::before {
                                width: 100%;
                            }

                            i {
                                rotate: 180deg;
                            }

                            .mega-sidebar-item,
                            .menu-list {
                                i {
                                    rotate: 0deg;
                                }
                            }
                        }
                    }

                    &:hover {
                        >.menu-link {
                            color: $color-pri
                        }

                        .mega {
                            transform: translate(-50%, 0);
                            visibility: visible;
                            opacity: 1;
                        }

                        .mega-overlay {
                            @include show;
                        }
                    }

                    >.menu-link {
                        height: 100%;
                        @include flex;
                        align-items: center;
                        @include fz-16;
                        color: $color-text;
                        padding: 2rem 0;
                    }

                    &.current_page_item {
                        >.menu-link {
                            color: $color-pri;
                        }

                        &::before {
                            width: 100%;
                        }
                    }

                    >.menu-list {
                        top: calc(100% + 0rem);

                        // &::after {
                        //     content: '';
                        //     width: 0;
                        //     height: 0;
                        //     left: 1rem;
                        //     border-left: 0.5rem solid transparent;
                        //     border-right: 0.5rem solid transparent;
                        //     border-bottom: 0.8rem solid $color-white;
                        //     position: absolute;
                        //     bottom: 100%;
                        // }
                    }
                }
            }
        }

        &-list {
            @include flex;
            align-items: center;
        }

        &-item {
            position: relative;
            @include transition(0.2s, linear);

            &:hover {
                >.menu-list {
                    @include min($res-mobile-min) {
                        @include show;
                    }

                    >.menu-item {
                        &.current_page_item {
                            >a {
                                color: $color-pri;

                                >i {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }

            .menu-link {
                @include transition(0.2s, linear);

                i {
                    @include transition(0.2s, linear);
                }
            }

            >.menu-link {
                color: $color-text;
                font-weight: 400;
                display: block;
                @include fz-16;

                >i {
                    display: none;
                    font-weight: 600;
                    margin-left: 0.4rem;
                    @include fz-10;
                    margin-top: 0rem;
                }
            }

            &.dropdown {
                >a {
                    >i {
                        display: inline-block;
                    }
                }
            }

            >.menu-list {
                padding: 1.4rem;
                position: absolute;
                top: 0;
                left: 0;
                min-width: 20rem;
                @include flex;
                flex-direction: column;
                align-items: flex-start;
                background-color: $color-white;
                box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
                border-radius: 1.6rem;
                @include transitionRe;

                // &:after {
                //     content: '';
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     width: 100%;
                //     height: 2px;
                //     background-color: $color-four
                // }
                @include min($res-mobile-min) {
                    @include hidden(0, 5px);
                }

                >.menu-item {
                    margin-left: 0;
                    width: 100%;

                    // &::after {
                    //     content: "";
                    //     position: absolute;
                    //     right: 0;
                    //     top: 0;
                    //     height: 100%;
                    //     width: 0.2rem;
                    //     background-color: $color-second;
                    //     @include transition(0.2s, ease-in-out);
                    //     opacity: 0;
                    // }
                    @include min($res-mobile-min) {
                        &:hover {
                            // &::after {
                            //     opacity: 1;
                            // }

                            >.menu-link {
                                color: $color-pri;
                                // background-color: $color-pri-ln;

                                >i {
                                    // color: white;
                                }
                            }
                        }
                    }

                    >.menu-link {
                        &:not(:last-child) {
                            @include mb(1.6rem);
                        }

                        padding: 1rem;
                        @include flex;
                        align-items: center;
                        justify-content: space-between;

                        >i {
                            transform: rotate(-90deg) translate(5px, 0);
                            margin-top: 0.8rem;
                        }
                    }

                    >.menu-list {
                        left: 100%;
                        top: -2.4rem;
                    }
                }
            }
        }
    }
}

.mobile-nav {
    .menu {
        &-nav {
            >.menu-list {
                >.menu-item {
                    &.current_page_item {
                        >a {
                            color: $color-pri;
                        }
                    }

                    >a {
                        padding: 1.2rem;
                        @include flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #303030;
                        font-size: 1.4rem;
                        font-weight: 600;
                        // text-transform: uppercase;
                        border-bottom: 1px solid $color-border;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &-item {
            &.dropdown {
                >a {
                    >i {
                        display: flex;
                    }
                }
            }

            &.active {
                >a {
                    color: $color-pri;

                    >i {
                        transform: rotate(180deg);
                    }
                }
            }

            a {
                position: relative;

                i {
                    width: 2rem;
                    height: 2rem;
                    @include mid-flex;
                    display: none;
                    @include transitionRe;
                }
            }

            >a {
                @include flex;
                align-items: center;
                justify-content: space-between;
                // padding: 1.2rem 1.2rem 1.2rem 2.4rem;
                padding: 1.2rem;
                font-size: 1.4rem;
                color: #303030;
                font-weight: 500;
                // &::before {
                //     content: "";
                //     position: absolute;
                //     @include posi-vertical;
                //     left: 1.4rem;
                //     width: 4px;
                //     height: 4px;
                //     border-radius: 100rem;
                //     border: 1px solid #303030;
                // }
            }

            >.menu-list {
                display: none;
                margin-left: 1.6rem;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 2rem;
                    bottom: 2rem;
                    left: 0;
                    width: 1px;
                    background-color: $color-border;
                }

                >.menu-item {
                    &.current_page_item {
                        >a {
                            color: $color-pri;
                        }
                    }

                    // >.menu-list {
                    //     >.menu-item {
                    //         >a {
                    //             padding: 1.2rem 1.2rem 1.2rem 3.2rem;
                    //             &::before {
                    //                 left: 2rem;
                    //             }
                    //         }
                    //         >.menu-list {
                    //             >.menu-item {
                    //                 >a {
                    //                     padding: 1.2rem 1.2rem 1.2rem 4rem;
                    //                     &::before {
                    //                         left: 3rem;
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
}

.mega {
    position: fixed;
    left: 50%;
    right: 0;
    width: 100%;
    min-height: 70vh;
    height: 70rem;
    max-width: 124.8rem;
    // padding-top: 2rem;
    padding-top: 4.5rem;
    z-index: 0;
    top: 7.5rem;
    transform-origin: top;
    @include hidden(-50%, -100%);
    @include transition(0.4s, ease);

    &.close {
        @include hidden(-50%, -100%);
    }

    &-overlay {
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        height: 100%;
        top: calc($h-top + $h-bot);
        // top: var(--size-hd);
        @include hidden(0, -100%);
        @include transition(0.6s, ease);
        z-index: -2;
        display: none;
    }

    &-side {
        min-height: inherit;
        background-color: $color-white;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
        overflow: hidden;
        border-radius: 3.2rem;

        // .dsmall-item {
        //     margin-bottom: 0;
        // }

        &-left {
            width: calc(100%*(2.5/12));
            min-height: inherit;
            background-color: $color-white;
            // box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.05);
            padding: 0;
        }

        &-right {
            width: calc(100%*(9.5/12));
            max-height: 70vh;
            padding-left: 4.8rem;
            padding-right: 2.4rem;

            @include max(1440px) {
                padding-left: 2.4rem;
                padding-right: 1.2rem;
            }

            overflow: auto;
            overflow-x: hidden;
        }
    }

    // .container {
    //     min-height: inherit;
    // }

    &-sidebar {
        height: 100%;
        padding: 4rem 2.4rem 2.4rem 4.8rem;

        @include max(1440px) {
            padding: 2rem 1.2rem 1.2rem 2.4rem;
        }

        min-height: inherit;

        &-list {}

        &-item {
            &:not(:last-child) {
                @include mb(1.6rem);
            }

            border-bottom: 1px solid $color-white;
            // padding: 0 2rem;
            border-radius: 4.6rem;
            padding: 1rem;
            position: relative;
            z-index: 2;
            background-color: $color-white;
            transition: 0.4s;

            // &::after {
            //     content: '';
            //     height: 100%;
            //     width: calc(100% + 3rem);
            //     background-color: $color-pri-ln;
            //     clip-path: polygon(25rem 0, 99% 50%, 25rem 100%, 0 100%, 0 0);
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     transition: 0.4s;
            //     z-index: -1;
            //     transform: translateX(-100%);
            // }

            // &:first-child {
            //     a {}
            // }

            &.active {
                // &::after {
                //     transform: translateX(0);
                //     opacity: 1;
                // }
                border-bottom: 1px solid var(--m002, #00783E);
                background: rgba(0, 120, 62, 0.10);

                a,
                i {
                    color: $color-pri;
                    font-weight: 400;
                    transition: .1s;
                }
            }

            &.current_page_item {
                &::after {
                    transform: translateX(0);
                    opacity: 1;

                }

                a {
                    color: $color-pri;
                    font-weight: 500;
                }
            }

            &:hover {
                // &::after {
                //     transform: translateX(0);
                //     opacity: 1;

                // }

                a,
                i {
                    color: $color-pri;
                    font-weight: 400;
                }
            }

            a {
                // padding: 1.6rem 0;
                // padding-right: 1.6rem;
                @include flex;
                align-items: center;
                justify-content: space-between;
                gap: .8rem;

                color: $color-text;
                // text-wrap: nowrap;
                transition: 0.4s;
                // @include lc(1);

                i {
                    font-weight: 400;
                    // display: none;
                }
            }
        }
    }

    &-menu {
        padding: 4rem 0;

        @include max(1440px) {
            padding: 2rem 0;
        }

        // overflow-y: auto;
        // overflow-x: hidden;
        display: none;
        animation: megaAni 0.7s ease-in-out;

        &.open {
            display: block;
        }

        &-row {
            @include flex;
        }

        &-col {
            width: calc(100% / 4);

            &.current_page_parent {
                .mega-menu-title {
                    color: $color-pri;
                }
            }
        }

        &-title {
            margin-bottom: 1.6rem;
            // padding-bottom: 1.2rem;

            width: calc(100% + 2.4rem);
            color: $color-text;

            @include fz-16;
            font-weight: 600;
            position: relative;

            // &::after {
            //     content: '';
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     height: 0.1rem;
            //     width: 50%;
            //     background-color: $color-pri;
            // }

            // &::before {
            //     content: '';
            //     position: absolute;
            //     bottom: 0.5rem;
            //     left: 0;
            //     height: 0.1rem;
            //     width: 25%;
            //     background-color: $color-pri2;
            // }
        }

        &-list {}

        &-item {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            @include fz-16;

            &:hover {
                a {
                    color: $color-pri;
                    // background-color: $color-pri-ln;
                }
            }

            &.current_page_item {
                pointer-events: none;

                a {
                    // color: $color-white;
                    color: $color-pri;
                    // background-color: $color-pri-ln;
                }
            }

            a {
                color: $color-text;
                @include transitionRe;
                text-wrap: pretty;
                // padding: 0.5rem 1rem;
                // border-radius: 0.5rem;
            }
        }
    }
}

@keyframes megaAni {
    0% {
        transform: translateX(20rem);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


.mega {
    /* pointer-events: none; */
    transform: translateX(-50%) translateY(0);
    opacity: 0;
}

.header-nav .menu-nav>.menu-list>.menu-item:hover .mega {
    pointer-events: visible;
    transform: translate(-50%, 0);
    transition: 0.4s;
}

// .mega-side-right {
//     padding-left: 1.2rem;
// }

// .mega-side {
//     position: relative;
//     margin: 0;
// }

// .header .banner-img-menu {
//     margin-bottom: 0;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
//     bottom: 0;

//     &::before {
//         position: absolute;
//         content: "";
//         z-index: 1;
//         width: 100%;
//         height: 100%;
//         top: 0;
//         left: 0;
//         background: linear-gradient(90deg, #2D3923 6.67%, transparent 68.67%);
//     }
// }

// .mega-menu-row {
//     position: relative;
//     z-index: 10;
//     flex-direction: column;
//     margin-bottom: unset;
// }

// .mega {
//     min-height: 40vh;
//     max-width: 130rem;
//     height: auto;

//     .container {
//         max-width: 130rem;
//         padding: 0;
//     }
// }

// .mega-align-left {}

// .mega-menu {
//     span.icon {
//         display: flex;
//         align-items: center;
//         height: auto;
//         position: relative;

//         img {
//             margin-right: 1rem;
//         }
//     }
// }

// .mega-menu-title {
//     margin-bottom: 0;
//     padding-bottom: 0;
//     color: white;

//     &::before,
//     &::after {
//         display: none;
//     }
// }

// .mega-menu-col {
//     width: 50%;
//     position: relative;

//     &::before {
//         position: absolute;
//         content: "";
//         width: 100%;
//         height: 100%;
//         left: 0;
//         top: 0;
//         background: linear-gradient(90deg, #FE1B24 .67%, transparent 68.67%);
//         transition: all 0.3s ease-in-out;
//         opacity: 0;
//     }

//     a {
//         display: block;
//         padding-left: 3.2rem;
//     }

//     @include hover {
//         &::before {
//             opacity: 1;
//         }
//     }
// }

// .mega-side-right {
//     padding: 0;
// }