.social {
    &-list {
        @include flex;
        align-items: center;
        gap: 2.4rem;
    }

    &-link {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: translateY(-5px);
        }

        img {
            width: 100%;
            display: block;
        }

    }
}