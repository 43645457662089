.header {
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 50 !important;
    padding-top: 2.4rem;
    transition: 0.3s;

    @include max($res-mobile) {
        height: auto;
        padding: 0;
    }

    &.sticky {
        padding-top: 0;

        .header-wrapper {
            max-width: 100%;
            border-radius: 0;
        }

        .header-wr {
            @include min(1201px) {
                height: calc(var(--size-hd) / 1.5);
            }
        }

        .header-logo {
            .custom-logo-link {
                height: 4.2rem;

                @include max(320px) {
                    height: 3.2rem;
                }
            }
        }

        .header-nav .menu-nav>.menu-list>.menu-item::before {
            bottom: 1.6rem;
        }

        .mega {
            top: 1.8rem;
        }

        .header-srch-ip {
            top: 5.4rem;

            @include max(1200px) {
                top: 4.4rem;

            }
        }
    }

    &.customize {
        position: fixed;
    }

    &-wrapper {
        max-width: calc(124.8rem + 31.2rem);



        box-shadow: var(--sdow);
        transition: 0.3s;
        border-radius: 7.3rem;
        margin: auto;
        background-color: $color-white;

        @include max($res-mobile) {
            width: 100%;
            border-radius: 0;
        }
    }

    &-wr {
        height: calc(var(--size-hd) - 2.4rem);
        max-width: 124.8rem;

        // @include min(1201px) {
        //     max-width: 110.8rem;
        // }

        padding: 0 1.6rem;
        margin: auto;
        @include flex;
        align-items: center;
        justify-content: space-between;

        @include max($res-mobile) {
            width: 100%;
            border-radius: 0;
            padding: 1.5rem;
            height: 6rem;
        }
    }

    &-right {
        @include flex;
        align-items: center;
    }

    &-logo {
        .custom-logo-link {
            display: block;
            transition: 0.4s ease-in-out;
            height: 6.4rem;
            width: auto;

            @include max($res-mobile) {
                height: 4.2rem;

                @include max(320px) {
                    height: 3.2rem;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }
        }
    }

    &-nav {
        position: relative;
        height: 100%;

        @include max($res-mobile) {
            display: none;
        }
    }

    &-action {
        @include flex;
        align-items: center;
        gap: 3.2rem;

        @include max(1200px) {
            gap: .8rem;
        }

        // margin-right: 0.5rem;
    }

    // &-language {
    //     position: relative;

    //     &:hover {
    //         >.header-language-list {
    //             transform: translateY(0);
    //             opacity: 1;
    //             visibility: visible;
    //         }
    //     }

    //     &-main {
    //         @include flex;
    //         align-items: center;

    //         img {
    //             margin-right: 0.4rem;
    //         }

    //         .arrow {
    //             width: 1.6rem;
    //             height: 1.6rem;
    //             @include mid-flex;
    //             margin-left: 0.4rem;

    //             i {
    //                 font-size: 1rem;
    //             }
    //         }
    //     }

    //     &-txt {
    //         transition: 0.4s;
    //     }

    //     &-list {
    //         position: absolute;
    //         @include flex;
    //         flex-direction: column;
    //         width: 100%;
    //         top: calc(100% + 0.5rem);
    //         transition: 0.4s;
    //         transform: translateY(1rem);
    //         opacity: 0;
    //         visibility: hidden;
    //     }

    //     &-item {
    //         @include flex;
    //         align-items: center;
    //         background-color: $color-white;
    //         padding: 0.5rem;
    //         border-radius: 0.5rem;
    //         box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    //         transition: 0.4s;

    //         @include hover {
    //             background-color: #ff656a;

    //             .header-language-txt {
    //                 color: $color-white;
    //             }
    //         }

    //         img {
    //             margin-right: 0.4rem;
    //         }
    //     }
    // }
    &-lg {
        display: flex;

        @include max(1200px) {
            gap: 0.8rem;
            margin-left: auto;
            // border: 0.1rem solid var(--color-pri);
            padding: 0.6rem;
            padding-right: unset;
            border-radius: 0.4rem;
            // background: var(--color-pri);
        }

        &-it {
            color: #D0D9D9;
            transition: 0.4s;
            position: relative;
            font-weight: 700;
            padding: .2rem 0;
            gap: .8rem;

            @include min(1201px) {
                &:first-child {
                    padding-right: 2.35rem;
                    border-right: 1px solid #E1E8E8;
                }

                &:last-child {
                    padding-left: 2.35rem;
                }
            }

            @include max(1200px) {
                // color: rgba(255, 255, 255, 0.6);
                color: #D0D9D9;
            }

            // &::after {
            //     @include pseudo;
            //     width: 0%;
            //     height: 0.1rem;
            //     bottom: .4rem;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     background: transparent;
            //     transition: 0.4s;

            //     @include max(1200px) {
            //         background: white;
            //     }
            // }

            &.active {
                color: $color-pri;

                @include max(1200px) {
                    color: $color-pri;
                    // color: white;
                }

                // &::after {
                //     width: 100%;
                // }
            }

            @include hover {
                // &::after {
                //     width: 100%;
                // }
                color: $color-pri;
            }
        }
    }

    &-search {
        &-button {
            // margin: 0 2.4rem;
            cursor: pointer;
            display: flex;
            flex-shrink: 0;
            width: 3.2rem;
            height: 3.2rem;

            @include max(1200px) {
                width: 2.2rem;
                height: 2.2rem;
            }

            img {
                flex-shrink: 0;
                width: 3.2rem;
                height: 3.2rem;

                @include max(1200px) {
                    width: 2.2rem;
                    height: 2.2rem;
                }
            }
        }
    }

    &-srch {
        display: block;
        position: relative;
        height: 100%;

        @include max(1200px) {
            margin-left: auto;
            // display: none;
        }

        &-it {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 0.8rem;
            cursor: pointer;

            img {
                width: 3.2rem;
                height: 2.8rem;

                @include max(800px) {
                    width: 2.2rem;
                    height: 2.2rem;
                }

            }
        }

        &-ip {
            position: absolute;
            top: 0;
            right: 0;
            transition: 0.4s;
            top: 6.2rem;

            @include max(1200px) {
                top: 4.4rem;
            }

            // transform: translateY(-50%);
            // transform: translateY(110%);
            clip-path: inset(0 0 0 100%);

            &.open {
                clip-path: inset(0 0 0 0);
            }
        }

        &-flex {
            display: flex;
            align-items: center;
            height: 4rem;
            min-width: 20rem;
            border: 0.1rem solid $color-border-01;
            padding: 0.5rem;
            padding-left: 1rem;
            border-radius: 3.2rem;
            background: white;

            input {
                width: 100%;
                border: none;
                outline: none;
                @include fz-14;
                @include font-pri;
            }

            button {
                width: 3rem;
                height: 3rem;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--color-pri);
                border-radius: inherit;

                img {
                    width: 1.5rem;
                    height: 1.6rem;
                    filter: brightness(0) invert(1);
                    flex-shrink: 0;
                    object-fit: contain;
                }
            }

        }
    }

    &-searchbar {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(0.7rem);
        @include full-fixed;
        transform: translateX(-100%);
        transition: 0.5s;

        &.open {
            transform: translateX(0);

            .header-searchbar-wr {
                &::after {
                    width: 100%;
                    transition-delay: 0.5s;
                }

                input {
                    @include input-placeholder {
                        transform: translateY(0);
                        opacity: 1;
                        transition-delay: 1.25s;
                    }
                }
            }

            button {
                transform: translateX(0);
                transition-delay: 1.5s;
                opacity: 1;
            }
        }

        &-wrapper {
            position: relative;
            height: 100%;
        }

        &-close {
            position: absolute;
            top: 2.4rem;
            left: 0;
            width: 35px;
            height: 35px;
            @include mid-flex;
            cursor: pointer;
            z-index: 110;
            display: none;

            @include max(900px) {
                top: 1rem;
                left: 0;
            }

            &:hover .icon {
                transform: rotate(360deg);
            }

            display: flex;

            .icon {
                transition: 0.6s ease-in-out;
                font-weight: 500;
                font-size: 2.4rem;
                color: $color-white;
                width: 2.4rem;
                height: 2.4rem;
                @include mid-flex;
            }
        }

        &-form {
            @include mid;
            height: 50px;
            width: 100%;
        }

        &-wr {
            position: relative;

            &::after {
                content: '';
                width: 0%;
                height: 0.1rem;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $color-white;
                transition: 1s;
            }
        }

        input {
            width: 100%;
            @include fz-30;
            color: $color-white;
            background-color: transparent;
            border: none;
            padding: 1rem 0;
            padding-left: 1rem;
            padding-right: 7rem;

            @include input-placeholder {
                color: rgba(255, 255, 255, 0.5);
                transition: 0.4s;
                transform: translateY(100%);
                opacity: 0;
            }
        }

        button {
            right: 0;
            bottom: 0;
            height: 100%;
            position: absolute;
            transition: 0.4s;
            transform: translateX(50%);
            opacity: 0;
            background-color: transparent;
            padding-right: 1rem;
            cursor: pointer;

            img {
                filter: brightness(0) saturate(100%) invert(95%) sepia(1%) saturate(72%) hue-rotate(232deg) brightness(121%) contrast(100%);
                transition: 0.4s;
                width: 2rem;
                height: 2rem;
            }

            &::after {
                background-color: transparent;
            }

            @include hover {
                >img {
                    transform: scale(1.2);
                }
            }
        }
    }

    &-phone {
        @include max(1199px) {
            display: none;
        }

        &-gr {
            .tlink {
                .txt {
                    display: block;
                    color: #3A4040;
                    @include fz-14;
                    font-weight: 400;
                    line-height: normal;
                }

                .tphone {
                    display: block;
                    color: $color-pri;
                    font-weight: 700;
                    line-height: normal;
                    transition: .3s;

                    @include hover {
                        color: var(--color-second);
                    }
                }
            }
        }
    }

    .banner-img-menu {
        position: relative;
        @include imgPd(1, 4);
        overflow: hidden;
        margin-bottom: 2rem;

        .banner-content-menu-mega {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: 0.4s;

            &:nth-child(1) {
                opacity: 1;
            }

            &.open {
                opacity: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

// mạke opacity 0 to show face 
@include min(1201px) {
    .header-wrapper {
        background-color: transparent;
        box-shadow: unset;
    }

    .header-nav,
    .header-right {
        opacity: 0;
    }

    &.sticky {
        .header-wrapper {
            background-color: $color-white;
            box-shadow: initial;
        }

        .header-nav,
        .header-right {
            opacity: 1;
        }
    }
}