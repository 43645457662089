.burger {
    cursor: pointer;
    // padding-left: 8px;
    position: relative;
    @include min($res-mobile-min) {
        display: none;
    }
}

.hamburger .line {
    width: 22px;
    height: 2px;
    border-radius: 4px;
    display: block;
    margin: 5px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    &:nth-child(1) {
        background-color: $color-pri;
    }
    &:nth-child(2) {
        background-color: $color-pri;
    }
    &:nth-child(3) {
        background-color: $color-pri;
    }
}

#hamburger.active .line:nth-child(1) {
    width: 18px;
    margin-left: auto;
}

#hamburger.active .line:nth-child(2) {
    margin-left: auto;
}

#hamburger.active .line:nth-child(3) {
    width: 14px;
    margin-left: auto;
}

// #hamburger.active .line{
//   -webkit-transform: rotate(30deg);
//   -ms-transform: rotate(30deg);
//   -o-transform: rotate(30deg);
//   transform: rotate(30deg);
// }

.menu-button {
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include max($res-mobile) {
        display: none;
    }
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: $color-second;
        border-radius: 4px 0px 2px 4px;
        position: absolute;
        z-index: -1;
    }

    .head {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 40px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            width: 200%;
            background: $color-second;
            z-index: 10;
            border-radius: 0 5px 2px 0;
            transform: skewX(-40deg);
        }
    }

    .tail {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 40px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 18px;
            bottom: 0;
            width: 200%;
            background: blue;
            z-index: 10;
            border-radius: 2px 0px 0px 5px;
            transform: skewX(-22deg);
        }
    }

    a {
        color: white;
        text-decoration: none;
    }

    p {
        color: $color-white;
        margin-left: 1.2rem;
    }
}

.menu-type {
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 5rem;
    cursor: pointer;
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: $color-pri;
        position: absolute;
        z-index: -1;
        border-radius: 1px 0 1px 0;
        @include max($res-mobile) {
            display: none;
        }
    }

    .head {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 40px;
        overflow: hidden;
        @include max($res-mobile) {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 19px;
            bottom: 0;
            width: 200%;
            background: $color-pri;
            z-index: 10;
            border-radius: 0 5px 2px 0;
            transform: skewX(-40deg);
        }
    }

    .tail {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 40px;
        overflow: hidden;
        @include max($res-mobile) {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 19px;
            bottom: 0;
            width: 200%;
            background: $color-pri;
            z-index: 10;
            border-radius: 2px 0px 0px 5px;
            transform: skewX(-40deg);
        }
    }

    a {
        color: white;
        text-decoration: none;
    }

    img {
        width: 2.4rem;
        height: auto;
        max-width: none !important;
    }

    p {
        color: $color-white;
        margin-left: 1.2rem;
        white-space: nowrap;
    }
    @include max($res-mobile) {
        padding: 1.1rem 1.6rem;
        border-radius: 0.4rem;
        background-color: $color-pri;
        margin-left: 0;
        height: 4rem;
    }
    @include max($mobile) {
        width: 100%;
        img {
            display: none;
        }
        p {
            margin-left: 0;
        }
    }
}

