.star {
    @include flex;
    align-content: start;

    &-list {
        position: relative;
        height: 2rem;
    }

    &-flex {
        @include flex;

        .icon {
            width: 1.8rem;
            height: 1.8rem;

            &:not(:first-child) {
                margin-left: 0.4rem;
            }
        }
    }

    &-filter {
        overflow: hidden;
        position: absolute;
        @include posi-vertical;
        left: 0;
        height: 100%;
        width: 0;
    }
}