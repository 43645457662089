.form-ct-sect {
    position: relative;
    z-index: 2;
    margin-top: -20%;

    @include max(800px) {
        margin-top: 0;
    }
}

.form-ct-row {
    align-items: flex-end;

    .info-ct-item {
        border-bottom: 1px solid #E1E8E8;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        .inner {
            padding: 4rem;

            @include max(1200px) {
                padding: 2rem;
            }
        }

        .info-ct-item-wr {
            display: flex;
            align-items: center;


        }

        .ic {
            display: flex;
            width: 8.3rem;
            height: 8.3rem;
            flex-shrink: 0;
            border-radius: 1.6rem;

            @include max(1200px) {
                width: 6.3rem;
                height: 6.3rem;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .info-dt {
            display: flex;
            flex-direction: column;
            margin-left: 1.4rem;

            @include max(1200px) {
                margin-left: 0.8rem;
            }
        }

        .info-label {
            font-size: 2.4rem;
            font-weight: 500;
            margin-bottom: 0.4rem;

            @include max(1200px) {
                font-size: 2rem;
            }
        }

        .txt {
            color: #606969;
            font-size: 2rem;
            transition: .3s;

            @include hover {
                color: $color-pri;
            }

            @include max(1200px) {
                font-size: 1.6rem;
            }
        }
    }

    .info-ct-wr,
    .form-ct-wr {
        box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
        background-color: #fff;
        border-radius: 3.2rem;
    }

    .info-ct,
    .form-ct {
        @include max(800px) {
            width: 100%;
        }
    }

    // .fomr-ct,
    // .form-ct {
    //     @include max(800px) {
    //         order: -1;
    //     }
    // }
}

.form-ct-wr {
    border-radius: 3.2rem;

    .inner {
        padding: 4rem;

        @include max(1200px) {
            padding: 2rem;
        }
    }

    .tt-main {
        padding-bottom: 3.2rem;

        @include max(1200px) {
            padding-bottom: 2rem;
        }
    }
}

.form-group {
    .group {
        @include mb(2.2rem);

        @include min(2000px) {
            margin-bottom: 1.6rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .btn {
            width: 100%;
        }
    }

    .label-group {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.4rem;
    }

    .input,
    .textarea {
        border-radius: 0.8rem;
        background-color: #F5F7F7;

        input,
        textarea {
            font-size: 1.6rem;
            padding: 1.2rem 1.6rem;
            color: #121212;
            font-weight: 600;
        }
    }

    .input {
        width: 100%;
        height: 4.8rem;

        input {
            width: 100%;
            height: 100%;
        }
    }

    .textarea {
        width: 100%;
        height: 15.2rem;

        textarea {
            resize: none;
            width: 100% !important;
            height: 100%;
        }
    }
}

.benefit {
    background-color: #F5F7F7;
    margin-top: 5rem;
    border-radius: 1.5rem;

    @include max(1200px) {
        margin-top: 3rem;
    }

    .inner {
        padding: 2rem;
    }

    .benefit-item {
        @include max(700px) {
            width: 50%;
        }

        @include max(575px) {
            width: 100%;
        }
    }

    .benefit-item-wr {
        display: flex;

        @include max(575px) {
            justify-content: center;
        }

        .ic {
            display: flex;
            width: 4rem;
            height: 4rem;
            flex-shrink: 0;
            margin-right: 1.6rem;

            @include max(1200px) {
                width: 3.2rem;
                height: 3.2rem;
                margin-right: 0.8rem;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .benefit-content {
        display: flex;
        flex-direction: column;

        .label {
            font-weight: 600;
        }

        .desc {
            font-size: 1.2rem;
            color: #606969;
        }
    }
}

.map-wr {
    @include imgPd(608, 1216);
    border-radius: 3.2rem;

    iframe {
        @include imgCover;
    }
}

.faq-wr {
    .tt-main {
        margin-bottom: 4.8rem;

        @include max(1200px) {
            margin-bottom: 2.8rem;
        }
    }
}

.faq-block {
    .faq-item {
        margin-bottom: 1.6rem;
        padding: 2.4rem 1.6rem;
        background-color: #F5F7F7;
        border-radius: 1.6rem;

        i {
            transition: .3s;
        }

        @include max(1200px) {
            padding: 1.6rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            i {
                transform: rotate(180deg);
            }
        }
    }

    .faq-head {
        font-size: 2.4rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include max(1200px) {
            font-size: 2rem;
        }
    }

    .faq-content {
        .desc {
            padding-top: 1.6rem;
            color: #3A4040;
        }
    }
}

.page-contact {
    .form-ct-sect {
        @include max(425px) {
            padding-top: 2rem;
        }
    }

    .faq-sect {
        padding-bottom: 4rem;
    }
}